import { WEBSITE_URL } from '../../../config';
import { TranslatorKeys } from '../../../config/Translator';

export type SVVariants =
  | 'primary'
  | 'secondary'
  | 'muted'
  | 'success'
  | 'info'
  | 'error'
  | 'danger';

export type SVBadgeElem = {
  variant: SVVariants;
  text: TranslatorKeys;
  class?: string;
};

export type SVRestriction = ('bakery' | 'host' | 'guest' | 'private')[];

export type SVNavWrapper = {
  element: string;
  attributes: { [U in string]: unknown };
};

export type SVDividerItem = {
  divider: true;
  title?: false;
  children?: undefined;
  class?: string;
};

export type SVTitleItem = {
  divider?: false;
  title: true;
  name: TranslatorKeys;
  wrapper?: SVNavWrapper;
  class?: string;
  restricted?: SVRestriction;
};

export type SVDropdownItem = {
  divider?: false;
  title?: false;
  children: SVNavItem[];
  name: TranslatorKeys;
  icon: string;
  url: string;
};

export type SVLinkItem = {
  divider?: false;
  title?: false;
  children?: undefined;
  name: TranslatorKeys;
  class?: string;
  badge?: SVBadgeElem;
  restricted?: SVRestriction;
  icon?: string;
  url: string;
  variant?: SVVariants;
};

export type SVNavItem =
  | SVDividerItem
  | SVTitleItem
  | SVLinkItem
  | SVDropdownItem;

export type SVNavigation = {
  items: SVNavItem[];
};

export const nav: SVNavigation = {
  items: [
    {
      name: 'dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'primary',
        text: 'new',
      },
    },
    {
      title: true,
      name: 'pages',
      wrapper: {
        // optional wrapper object
        element: '', // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: 'd-lg-none', // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'clients',
      url: '/clients',
      icon: 'icon-user',
      class: 'd-lg-none',
      restricted: ['host', 'bakery'],
    },
    {
      name: 'products',
      url: '/products',
      icon: 'icon-puzzle',
      class: 'd-lg-none',
    },
    {
      name: 'orders',
      url: '/orders',
      icon: 'icon-basket-loaded',
      class: 'd-lg-none',
    },
    {
      name: 'bills',
      url: '/bills',
      icon: 'icon-docs',
      class: 'd-lg-none',
    },
    {
      title: true,
      name: 'actions',
      wrapper: {
        // optional wrapper object
        element: '', // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: '', // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'createUser',
      url: '/client/create',
      icon: 'icon-user',
      restricted: ['host', 'bakery'],
    },
    {
      name: 'createProduct',
      url: '/product/create',
      icon: 'icon-puzzle',
      restricted: ['host', 'bakery'],
    },
    {
      name: 'importProducts',
      url: '/product/import',
      icon: 'icon-docs',
      restricted: ['bakery'],
    },
    {
      name: 'createBill',
      url: '/bill/create',
      icon: 'icon-calculator',
      restricted: ['bakery'],
    },
    {
      name: 'compileOrders',
      url: '/orders/compile',
      icon: 'fa fa-balance-scale',
      restricted: ['bakery'],
    },
    {
      name: 'bakelist',
      url: '/bakery',
      icon: 'fa fa-file-text',
      restricted: ['bakery'],
    },
    {
      name: 'billGuest',
      url: '/client/billing',
      icon: 'fa fa-file-text-o',
      restricted: ['host'],
    },
    {
      name: 'editProfile',
      url: '/profile',
      icon: 'fa fa-user',
      restricted: ['private'],
    },
    {
      name: 'settings',
      url: '/settings',
      icon: 'fa fa-gear',
      restricted: ['private'],
    },
    {
      divider: true,
    },
    {
      title: true,
      name: 'extra',
    },
    {
      name: 'impressum',
      url: '/impressum',
      icon: 'icon-info',
    },
    {
      name: 'agb',
      url: '/tos',
      icon: 'icon-doc',
    },
    {
      name: 'privacyPolicy',
      url: '/privacy',
      icon: 'icon-people',
    },
    {
      name: 'contactSupport',
      url: '/chat/create',
      icon: 'icon-support',
    },
    {
      name: 'appDownload',
      url: `${WEBSITE_URL}/app/get`,
      icon: 'icon-cloud-download',
      class: 'mt-auto',
      variant: 'success',
    },
  ],
};

export const navLogout: SVNavigation = {
  items: [
    {
      name: 'login',
      url: '/login',
      icon: 'icon-login',
    },
    /*
    {
      name: 'register',
      url: '/register',
      icon: 'icon-user-follow',
    },
    */
    {
      name: 'about',
      url: '/about',
      icon: 'icon-question',
    },
    {
      title: true,
      name: 'extra',
    },
    {
      name: 'impressum',
      url: '/impressum',
      icon: 'icon-info',
    },
    {
      name: 'agb',
      url: '/tos',
      icon: 'icon-doc',
    },
    {
      name: 'privacyPolicy',
      url: '/privacy',
      icon: 'icon-people',
    },
    {
      name: 'contactSupport',
      url: '/chat/create',
      icon: 'icon-support',
    },
    {
      name: 'appDownload',
      url: 'https://play.google.com/store/apps/details?id=com.waldboth.slowvita',
      icon: 'fa fa-android',
      class: 'mt-auto',
      variant: 'success',
    },
  ],
};

import React from 'react';
import { getApolloContext } from '@apollo/client';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Cookie from 'js-cookie';
import {
  MESSAGE_TYPE,
  THEME_MAIN_COLOR,
  THEME_SECONDARY_COLOR,
  UserContext,
} from '../../config';
import { getLoggedInUser, isBakery } from '../../config/UserManagement';
import {
  AllowedUser,
  AuthResponse,
  Credentials,
  LanguageISO,
  PriceList,
  Vat,
} from '../../config/types';
import { translate } from '../../config/Translator';
import Loading from '../Components/Loading';

import Footer from '../Components/Footer/Footer';
import HeaderLoggedOut from '../Components/Header/HeaderLoggedOut';
import {
  autobind,
  errorLogging,
  getHeaderInfos,
  lazyWithRetry,
} from '../../config/utils';
// import SlowVitaChatbot from '../Components/Chatbot/Chatbot';
import SidebarLoggedOut from '../Components/Sidebar/SidebarLoggedOut';
import 'react-interactive-tutorials-cont/dist/react-interactive-tutorials.css';
import { mutate, query } from '../../graphql/networking';
import 'react-dates/lib/css/_datepicker.css';
import { getPriceListsOfUser } from '../../config/ProductManagement';

const Full = lazyWithRetry(() => import('./Full'));

// Pages
const Login = lazyWithRetry(() => import('../Pages/Authentication/Login'));
const Register = lazyWithRetry(
  () => import('../Pages/Authentication/Register'),
);
const Page404 = lazyWithRetry(() => import('../Icons/FontAwesome/FontAwesome'));
const Page500 = lazyWithRetry(
  () => import('../Icons/SimpleLineIcons/SimpleLineIcons'),
);
const Impressum = lazyWithRetry(() => import('../Pages/Misc/Impressum'));
const Sitemap = lazyWithRetry(() => import('../Pages/Misc/Sitemap'));
const Privacy = lazyWithRetry(() => import('../Pages/Misc/Privacy'));
const Tos = lazyWithRetry(() => import('../Pages/Misc/ToS'));
const Plans = lazyWithRetry(() => import('../Pages/Misc/Plans'));
const PrintInstructions = lazyWithRetry(
  () => import('../Pages/Client/PrintInstructions'),
);
const AppGet = lazyWithRetry(() => import('../Pages/App/AppGet'));

type MainProps = {
  language: LanguageISO;
};
type MainState = {
  user: null | AllowedUser;
  credentials: null | Credentials;
  authenticating: boolean;
  authMessage: string;
  checkout: boolean;
  language: LanguageISO;
  vats: Vat[];
  numNewPrivates: number;
  authMessageType: MESSAGE_TYPE;
  userPriceLists: PriceList[];
};
export default class Main extends React.Component<MainProps, MainState> {
  constructor(props: MainProps) {
    super(props);
    this.state = {
      user: null,
      credentials: null,
      authenticating: true,
      authMessage: translate('welcome', props.language),
      checkout: false,
      language: props.language,
      vats: [],
      authMessageType: MESSAGE_TYPE.MUTED,
      numNewPrivates: 0,
      userPriceLists: [],
    };
    autobind(this, ['getUserResponse', 'authenticate', 'changePageLanguage']);
  }

  componentDidMount(): void {
    const { client } = this.context;
    const { language } = this.props;
    const header = getHeaderInfos('Home', language);
    document.title = header.title;
    const token = localStorage.getItem('token');
    const userID = localStorage.getItem('userID');

    if (
      typeof token !== 'undefined' &&
      typeof userID !== 'undefined' &&
      userID !== null &&
      token !== null
    ) {
      this.setState({ credentials: { identifier: '', password: '' } });
      getLoggedInUser(client, userID, language).then(
        this.getUserResponse.bind(this),
        this.getUserFailed.bind(this),
      );
    } else {
      this.setState({
        credentials: { identifier: '', password: '' },
        authenticating: false,
      });
    }
    query(client, 'vats', {}, true).then((vats) => {
      this.setState({ vats: vats || [] });
    }, errorLogging);
  }

  getUserFailed(error: string | Error): void {
    localStorage.removeItem('token');
    localStorage.removeItem('userID');
    this.setState({
      authMessage: typeof error === 'string' ? error : error.message,
    });
  }

  getUserResponse(user: AllowedUser): void {
    const { language } = this.state;
    const { client } = this.context;
    this.setState({
      user,
      language: !user.language ? language : user.language.short,
      authenticating: false,
    });
    if (isBakery(user)) {
      query(client, 'countNewPrivates', { bakeryID: user.id }).then(
        (numNewPrivates) => {
          if (numNewPrivates) this.setState({ numNewPrivates });
        },
      );
      query(client, 'getPriceLists', { bakery: user.id }).then((priceLists) => {
        if (priceLists) this.setState({ userPriceLists: priceLists });
      });
    } else {
      query(client, 'getPriceLists', { bakery: user.bakery.id }).then(
        (priceLists) => {
          if (priceLists) {
            const ups = getPriceListsOfUser(priceLists, user.id);
            this.setState({
              userPriceLists: priceLists.filter((p) => ups.includes(p.id)),
            });
          }
        },
      );
    }
  }

  async authSuccess(response: AuthResponse | undefined): Promise<void> {
    const { client } = this.context;
    const { language } = this.props;
    const { credentials } = this.state;
    if (response && response.jwt) {
      this.setState({ authenticating: false });
      localStorage.setItem('token', response.jwt);
      localStorage.setItem('userID', response.user.id);
      getLoggedInUser(client, response.user.id, language).then(
        this.getUserResponse.bind(this),
        this.getUserFailed.bind(this),
      );
      this.setState({
        authMessage: translate('loginSuccess', language),
        authMessageType: MESSAGE_TYPE.SUCCESS,
      });
    } else if (credentials) {
      const { password, identifier } = credentials;
      if (identifier.length < 3) {
        this.authFailed();
        return;
      }
      query(client, 'findGuest', {
        username: credentials.identifier,
      }).then(async (users) => {
        if (users && users.length) {
          if (users.length > 10) {
            this.authFailed();
            return;
          }
          for (const user of users) {
            // eslint-disable-next-line no-await-in-loop
            const subResponse = await mutate(client, 'login', {
              identifier: user.username,
              password,
            });
            if (subResponse) {
              // eslint-disable-next-line no-await-in-loop
              await this.authSuccess(subResponse);
              return;
            }
          }
        }
        this.authFailed();
      });
    } else {
      this.authFailed();
    }
  }

  authFailed(): void {
    const { language } = this.state;
    this.setState({ authenticating: false });
    this.setState({
      authMessage: translate('credentialsWrong', language),
      authMessageType: MESSAGE_TYPE.DANGER,
    });
  }

  authenticate(creds: Credentials | null): void {
    const { client } = this.context;
    if (creds == null) {
      return;
    }
    localStorage.removeItem('token');
    this.setState({ authenticating: true, credentials: creds });
    mutate(client, 'login', creds).then(this.authSuccess.bind(this));
  }

  changePageLanguage(lang: LanguageISO): void {
    Cookie.set('sw_lang', lang, { path: '/' });
    this.setState({ language: lang });
  }

  render(): JSX.Element {
    const { client } = this.context;
    const {
      credentials,
      authenticating,
      checkout,
      user,
      language,
      authMessage,
      vats,
      numNewPrivates,
      authMessageType,
      userPriceLists,
    } = this.state;
    if (
      credentials === null ||
      typeof credentials === 'undefined' ||
      authenticating ||
      checkout
    ) {
      return <Loading />;
    }

    if (user === null) {
      return (
        <UserContext.Provider
          value={{
            user: null,
            client,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            logout: () => {},
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            updateUserResponse: () => {},
            language,
            loginFunc: this.authenticate.bind(this),
            authMessage,
            authMessageType,
            identifier: credentials.identifier,
            password: credentials.password,
            vats: [],
            changePageLanguage: this.changePageLanguage.bind(this),
            numNewPrivates: 0,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            decrNumNewPrivates: () => {},
            userPriceLists,
          }}>
          <HeaderLoggedOut />
          <div className="app-body logged-out">
            <BrowserRouter>
              <SidebarLoggedOut {...this.props} />
              <main className="main">
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route
                    exact
                    path="/password/restore/:code"
                    component={Login}
                  />
                  <Route exact path="/login/:verify" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/404" component={Page404} />
                  <Route exact path="/500" component={Page500} />
                  <Route exact path="/tos" component={Tos} />
                  <Route exact path="/sitemap" component={Sitemap} />
                  <Route exact path="/impressum" component={Impressum} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/plans" component={Plans} />
                  <Route exact path="/app/get" component={AppGet} />
                  <Redirect from="/" to="/login" />
                </Switch>
              </main>
              <Footer />
            </BrowserRouter>
          </div>
          {(authenticating || checkout) && <Loading />}
          {/*       <SlowVitaChatbot language={language} user={null} /> */}
          <CookieConsent
            containerClasses="cookie-consent"
            overlayClasses="cookie-consent-overlay"
            location="top"
            overlay
            buttonText={translate('cookieConsentBtn', language)}
            cookieName="cookieconsentslowvita"
            style={{ background: THEME_SECONDARY_COLOR, fontSize: '14px' }}
            buttonStyle={{ color: THEME_MAIN_COLOR, fontSize: '16px' }}
            expires={150}>
            {translate('cookieConsent', language)}
            <a href="/privacy">{translate('privacyPolicy', language)}</a>
          </CookieConsent>
        </UserContext.Provider>
      );
    }

    return (
      <UserContext.Provider
        value={{
          user,
          client,
          logout: () => {
            this.setState({
              user: null,
              authMessage: translate('loggedOut', language),
              credentials: { identifier: '', password: '' },
            });
            localStorage.removeItem('token');
            localStorage.removeItem('userID');
          },
          language,
          updateUserResponse: this.getUserResponse,
          vats,
          changePageLanguage: this.changePageLanguage,
          loginFunc: this.authenticate,
          authMessage: '',
          authMessageType: MESSAGE_TYPE.MUTED,
          identifier: '',
          password: '',
          numNewPrivates,
          decrNumNewPrivates: () => {
            this.setState({ numNewPrivates: numNewPrivates - 1 });
          },
          userPriceLists,
        }}>
        <BrowserRouter>
          <Switch>
            <Route
              path="/printInstruction/:language/:username/:password/:host/:bakery/:deadline/:bakeryID/:from/:to"
              component={PrintInstructions}
            />
            <Route path="/" component={Full} />
          </Switch>
        </BrowserRouter>
        <CookieConsent
          containerClasses="cookie-consent"
          overlayClasses="cookie-consent-overlay"
          location="top"
          overlay
          buttonText={translate('cookieConsentBtn', language)}
          cookieName="cookieconsentslowvita"
          style={{ background: THEME_SECONDARY_COLOR, fontSize: '14px' }}
          buttonStyle={{ color: THEME_MAIN_COLOR, fontSize: '16px' }}
          expires={150}>
          {translate('cookieConsent', language)}
          <a href="/privacy">{translate('privacyPolicy', language)}</a>
        </CookieConsent>
      </UserContext.Provider>
    );
  }
}

Main.contextType = getApolloContext();

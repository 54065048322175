import React from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  AllowedUser,
  Credentials,
  LanguageISO,
  PriceList,
  User,
  Vat,
} from './types';

export const BASE_URL = 'https://api.slowvita.it';
export const GRAPHQL_URL = `${BASE_URL}/graphql`;

export const THEME_MAIN_COLOR = '#382F2D';
export const THEME_MAIN_WHITE = '#ffffff';
export const THEME_MAIN_COLOR_DARK = 'rgba(56,47,45,.8)';
export const THEME_SECONDARY_COLOR = '#9c9796';
export const THEME_SECONDARY_COLOR_DARK = 'rgba(156,151,150,.8)';
export const THEME_BG_COLOR = '#ffffff';
export const WEBSITE_URL = 'https://naturbaeckerei-messner.slowvita.it';
export const APP_GOOGLE_URL =
  'https://play.google.com/store/apps/details?id=com.waldboth.messner';
export const APP_IOS_URL =
  'https://apps.apple.com/us/app/bäckerei-frisch/id1620208704';
export const APP_NAME = 'Naturbäckerei Messner';
export const BAKERY:
  | 'Sellemond'
  | 'SlowVita'
  | 'Bäckerei Frisch'
  | 'Naturbäckerei Messner' = 'Naturbäckerei Messner';
export const URL = 'naturbaeckerei-messner.slowvita.it';
export const SLOGAN = {
  de: 'Naturbäckerei Messner',
  it: 'Panificio Artigianale Messner',
  en: 'Messner natural bakery',
};
export const LOGIN_IMAGE_DE = '/whatsMessner1_de.png';
export const LOGIN_IMAGE_IT = '/whatsMessner2_it.png';
export const LOGIN_IMAGE_EN = '/whatsMessner3_en.png';
export const DASHBOARD_IMAGE_DE = '/useMessner1_de.png';
export const DASHBOARD_IMAGE_IT = '/useMessner2_it.png';
export const DASHBOARD_IMAGE_EN = '/useMessner3_en.png';

export enum MESSAGE_TYPE {
  INFO,
  DANGER,
  MUTED,
  SUCCESS,
}

const userContext: {
  user: AllowedUser | null;
  client: null | ApolloClient<NormalizedCacheObject>;
  logout: () => void;
  updateUserResponse: (user: AllowedUser) => void;
  language: LanguageISO;
  vats: Vat[];
  changePageLanguage: (lang: LanguageISO) => void;
  loginFunc: (creds: Credentials | null) => void;
  authMessage: string;
  authMessageType: MESSAGE_TYPE;
  identifier: string;
  password: string;
  numNewPrivates: number;
  decrNumNewPrivates: () => void;
  userPriceLists: PriceList[];
} = {
  user: null,
  client: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateUserResponse: () => {},
  language: 'de',
  vats: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changePageLanguage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loginFunc: () => {},
  authMessage: '',
  authMessageType: MESSAGE_TYPE.MUTED,
  identifier: '',
  password: '',
  numNewPrivates: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  decrNumNewPrivates: () => {},
  userPriceLists: [],
};

export const UserContext = React.createContext(userContext);

export declare type UserContextTypeLoggedIn = {
  user: AllowedUser;
  client: ApolloClient<NormalizedCacheObject>;
  logout: () => void;
  updateUserResponse: (user: AllowedUser) => void;
  language: LanguageISO;
  vats: Vat[];
  changePageLanguage: (lang: LanguageISO) => void;
  loginFunc: (creds: Credentials | null) => void;
  authMessage: string;
  authMessageType: MESSAGE_TYPE;
  identifier: string;
  password: string;
  numNewPrivates: number;
  decrNumNewPrivates: () => void;
  userPriceLists: PriceList[];
};
export declare type UserContextTypeLoggedOut = {
  user: User | null;
  client: ApolloClient<NormalizedCacheObject>;
  logout: () => void;
  updateUserResponse: (user: User) => void;
  language: LanguageISO;
  vats: Vat[];
  changePageLanguage: (lang: LanguageISO) => void;
  loginFunc: (creds: Credentials | null) => void;
  authMessage: string;
  authMessageType: MESSAGE_TYPE;
  identifier: string;
  password: string;
  numNewPrivates: 0;
};

export const DAY_IN_MS = 864e5;
export const CENTURY_IN_MS = 3187296000000;

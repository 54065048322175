import {
  AuthResponse,
  BakeryListItem,
  BakeryUser,
  Bill,
  BillableUser,
  CollectionType,
  DashboardDataItem,
  DashboardDataItemLarge,
  DashboardResponseDataItem,
  DeliveryZone,
  Entities,
  FeedItem,
  GuestUser,
  Holiday,
  HostProduct,
  HostUser,
  IDType,
  IntType,
  Language,
  LanguageISO,
  Message,
  Order,
  OrderedProduct,
  Plan,
  PrivateUser,
  Product,
  User,
  Vat,
} from '../config/types';
import { showTranslated, showUsername } from '../config/utils';
import {
  BAKERIES,
  CHECK_EMAIL,
  CHECK_USERNAME,
  COUNT_NEW_PRIVATES,
  DASHBOARD_BAKERY,
  DASHBOARD_BAKERY_CONT,
  FIND_GUEST,
  FIND_ORDERS_OF_BAKERY,
  GET_ABOS_BAKERY,
  GET_ABOS_BAKERY_GUESTS,
  GET_ABOS_HOST,
  GET_ABOS_HOST_GUESTS,
  GET_ACTIVE_GUESTS_OF_HOST,
  GET_ACTIVE_HOLIDAY_OF_BAKERY,
  GET_BAKELIST,
  GET_BAKERY_PRODUCT,
  GET_BAKERY_PRODUCT_BY_PRICELIST,
  GET_BAKERY_PRODUCTS,
  GET_BILL,
  GET_BILLABLE_GUESTS,
  GET_BILLABLE_HOSTS_PRIVATES,
  GET_BILLABLE_ORDERS,
  GET_BILLS_OF_BAKERY,
  GET_BILLS_OF_HOST,
  GET_CLIENTS_OF_HOST,
  GET_CONVERSATIONS,
  GET_DELIVERY_ZONE,
  GET_DELIVERY_ZONES_BY_USER,
  GET_HOLIDAY,
  GET_HOLIDAYS_OF_BAKERY,
  GET_HOST_PRODUCT,
  GET_HOST_PRODUCT_BY_BASE,
  GET_HOST_PRODUCTS,
  GET_HOSTS_OF_BAKERY,
  GET_LANGUAGES,
  GET_MESSAGE,
  GET_MESSAGES,
  GET_NEW_PRIVATES,
  GET_NEWSFEED_BAKERY,
  GET_NEWSFEED_HOST,
  GET_ORDER,
  GET_ORDERED_PRODUCT_GUEST,
  GET_ORDERS_OF_BAKERY,
  GET_ORDERS_OF_BAKERY_FOR_CALCULATION,
  GET_ORDERS_OF_BAKERY_TO_COMPILE,
  GET_ORDERS_OF_HOST,
  GET_PLANS,
  GET_PRICELISTS,
  GET_SUPPORT,
  GET_USER,
  GET_VATS,
  HOST_PRODUCTS_COUNT,
  ORDERS_COUNT,
  ORDERS_COUNT_HOST,
  ORDERS_COUNT_USER,
  ORDERS_OF_BILL,
  SEARCH_EASYBACK_PRODUCT,
  USERS,
} from './queries';
import {
  ACCEPT_ALL_PRODUCTS,
  ACCEPT_ORDER,
  ACCEPT_PRIVATE,
  ADD_PRODUCT_PRICELIST,
  ADD_USER_INFO,
  CHANGE_DEADLINE,
  CHANGE_LANG,
  CHANGE_PRODUCT_ORDER,
  CHANGE_PW,
  CONFIRM_MAIL,
  CREATE_BILL,
  CREATE_BILL_SETTINGS,
  CREATE_DELIVERY_ZONE,
  CREATE_GUEST,
  CREATE_HOLIDAY,
  CREATE_HOST,
  CREATE_HOST_PRODUCTS,
  CREATE_MESSAGE,
  CREATE_ORDERED_PRODUCT,
  CREATE_PRICE_LIST,
  CREATE_PRODUCT,
  CREATE_TRANSLATION,
  DELETE_BILL,
  DELETE_DELIVERY_ZONE,
  DELETE_HOLIDAY,
  DELETE_HOST_PRODUCT,
  DELETE_MEDIA,
  DELETE_MESSAGE,
  DELETE_ORDER,
  DELETE_PRICE_LIST,
  DELETE_PRODUCT,
  DELETE_USER,
  EDIT_BILL,
  EDIT_BILL_SETTINGS,
  EDIT_HOST_PRODUCT,
  EDIT_MESSAGE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_PRICELIST,
  EDIT_TRANSLATION,
  EDIT_USER,
  EDIT_USER_INFO,
  INCREASE_BILL_NUMBER,
  INCREASE_DD_NUMBER,
  INITIAL_USER_UPDATE,
  LOGIN,
  PASSWORD_FORGET,
  PASSWORD_RESET,
  PAY_BILL,
  PLACE_ORDER,
  REGISTER,
  REMOVE_ORDERED_PRODUCT,
  REMOVE_PRODUCT_PRICELIST,
  RENAME_PRICE_LIST,
  REPLY_MESSAGE,
  RESTORE_USER,
  SEND_BILL,
  SET_DEFAULT_PRICE_LIST,
  TOGGLE_PRODUCT_ACTIVE,
  UPDATE_USER_PRICE_LIST,
  UPLOAD_IMAGE,
  UPLOAD_MEASURED_WEIGHT,
} from './mutations';
import { translate, TranslatorKeys } from '../config/Translator';
import {
  GQLStrapiData,
  GQLStrapiResponse,
  MutationType,
  QueryType,
} from './types';
import {
  GQLBill,
  GQLDashboardItemLarge,
  GQLDeliveryZone,
  GQLLanguage,
  GQLMessage,
  GQLMutation,
  GQLOrder,
  GQLOrderedProduct,
  GQLPlan,
  GQLProduct,
  GQLQuery,
  GQLUsersPermissionsUser,
  GQLVat,
} from './graphqlTypes';

function sortNewsfeed(left: CollectionType, right: CollectionType): number {
  return (
    new Date(right.createdAt).getTime() - new Date(left.createdAt).getTime()
  );
}

function prepareNewsfeedItem(
  item: User | Bill | HostProduct | Product | Order,
  date: Date,
  language: LanguageISO,
): FeedItem {
  switch (item.__typename) {
    case 'Product':
      return {
        type: 'success',
        name: `${translate('newProduct', language)}: ${showTranslated(
          item.name,
          language,
        )}`,
        thumb: item.thumb,
        time: `${date.getHours()}:${date.getMinutes()}`,
        link: `/product/edit/${item.id}/${showTranslated(item.name, language)}`,
      };
    case 'HostProduct':
      return {
        type: 'success',
        name: `${translate('newProduct', language)}: ${showTranslated(
          item.base_product.name,
          language,
        )}`,
        thumb: item.base_product.thumb,
        time: `${date.getHours()}:${date.getMinutes()}`,
        link: `/product/edit/${item.id}/${showTranslated(
          item.base_product.name,
          language,
        )}`,
      };
    case 'Bill':
      return {
        type: 'danger',
        name: `${translate('newBill', language)}: ${item.bill_number}`,
        time: `${date.getHours()}:${date.getMinutes()}`,
        link: `/bill/${item.id}`,
      };
    case 'Order':
      return {
        type: 'warning',
        name: `${translate('newOrder', language)}: ${item.public_id}`,
        time: `${date.getHours()}:${date.getMinutes()}`,
        link: `/order/${item.id}`,
      };
    case 'UsersPermissionsUser':
      return {
        type: 'info',
        name: `${translate('newClient', language)}: ${showUsername(item)}`,
        time: `${date.getHours()}:${date.getMinutes()}`,
        link: `/client/edit/${item.id}/${showUsername(item)}`,
      };
    default:
      return {
        type: 'title',
        name: translate('nothingHappenedRecently', language),
      };
  }
}

function createFeed(
  all: (HostUser | PrivateUser)[] | Order[] | Product[] | HostProduct[],
): FeedItem[] {
  if (all.length === 0) {
    return [];
  }
  all = all.sort(sortNewsfeed);
  const feed: FeedItem[] = [];
  let date = new Date();
  let offset = 0;
  if (getDayAbsolute(all[0].createdAt) === getDayAbsolute(date)) {
    feed.push({
      type: 'title',
      name: getOffsetDayString(date, offset, 'de'),
    });
  }
  for (const item of all) {
    const itemDate = new Date(item.createdAt);
    if (
      getDayAbsolute(itemDate) !== getDayAbsolute(date) &&
      Math.abs(offset) < 7
    ) {
      offset += getDayAbsolute(itemDate) - getDayAbsolute(date);
      date = itemDate;
      feed.push({
        type: 'title',
        name: getOffsetDayString(date, offset, 'de'),
      });
    }
    feed.push(prepareNewsfeedItem(item, itemDate, 'de'));
  }
  return feed;
}

function getDayAbsolute(time: string | Date) {
  if (typeof time === 'string') {
    time = new Date(time);
  }
  return Math.floor(time.getTime() / 86400000);
}

function getOffsetDayString(
  date: Date,
  offset: number,
  language: LanguageISO,
): string {
  if (Math.abs(offset) > 6) {
    return translate('lastWeek', language);
  }
  return translate(`Day${Math.abs(offset)}` as TranslatorKeys, language);
}

function parseDashboardItem(item: DashboardDataItem | null): DashboardDataItem {
  if (item) return { date: new Date(item.date), amount: item.amount };
  return { date: new Date(), amount: 0 };
}

function parseDashboardItemLarge(
  item: GQLDashboardItemLarge | null,
): DashboardDataItemLarge {
  if (item) {
    return {
      date: new Date(item.date),
      from: new Date(item.from),
      sales: {
        sales: item.sales.sales,
        paid: item.sales.paid,
        delivered: item.sales.delivered,
      },
    };
  }
  return {
    date: new Date(),
    from: new Date(),
    sales: {
      sales: 0,
      paid: 0,
      delivered: 0,
    },
  };
}

function isDate(dateStr: string): boolean {
  try {
    return (
      dateStr.match(
        /[0-9]{4}-[0-9]{2}-[0-9]{2}(T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z)/g,
      ) !== null
    );
  } catch (e) {
    return false;
  }
}

function isFragment<U>(
  val: GQLStrapiResponse<U> | string | boolean | number,
): val is GQLStrapiResponse<U> {
  return typeof val === 'object' && 'data' in val;
}
// reload
function fragmentToObject<T extends Entities, U>(data: GQLStrapiData<U>): T {
  const o = { id: data.id };
  if (data.attributes) {
    const keys = Object.keys(data.attributes);
    for (let i = 0; i < keys.length; i++) {
      const val: GQLStrapiResponse<U> | string | boolean | number | null =
        data.attributes[keys[i]];
      if (typeof val !== 'undefined' && val !== null) {
        if (isFragment(val)) {
          o[keys[i]] = fragmentParse(val);
        } else if (typeof val === 'string' && isDate(val)) {
          o[keys[i]] = new Date(val);
        } else {
          o[keys[i]] = val;
        }
      } else {
        o[keys[i]] = undefined;
      }
    }
  }
  return o as T;
}

function fragmentParse<T extends Entities | Entities[], U>(
  res: GQLStrapiResponse<U>,
): T | undefined {
  if (res && res.data) {
    if (Array.isArray(res.data)) {
      return res.data
        .map((e) => {
          if (e.id) return fragmentToObject(e);
          return undefined;
        })
        .filter((e) => !!e) as T;
    }
    if (res.data.id) return fragmentToObject(res.data) as T;
    return undefined;
  }
  return undefined;
}

function getId<T>(res: GQLStrapiResponse<T>): IDType | undefined {
  if (res && 'data' in res && res.data) {
    if (Array.isArray(res.data)) {
      return undefined;
    }
    return res.data.id ?? undefined;
  }
  return undefined;
}

function getIds<T>(res: GQLStrapiResponse<T>): IDType[] {
  if (res && 'data' in res && res.data) {
    if (Array.isArray(res.data)) {
      return res.data.map((d) => d.id) as IDType[];
    }
    return [];
  }
  return [];
}

function getCount<T extends keyof GQLQuery | keyof GQLMutation>(
  res: T extends keyof GQLQuery
    ? GQLQuery[T]
    : T extends keyof GQLMutation
    ? GQLMutation[T]
    : never,
): number {
  if (res && 'meta' in res) {
    return res.meta.pagination.total;
  }
  return -1;
}

export const queries: QueryType = {
  users: {
    url: 'usersPermissionsUsers',
    query: USERS,
    resolver: (r) =>
      fragmentParse<User[], GQLUsersPermissionsUser>(
        r.data.usersPermissionsUsers,
      ),
  },
  bakeries: {
    url: 'usersPermissionsUsers',
    query: BAKERIES,
    resolver: (r) =>
      fragmentParse<BakeryUser[], GQLUsersPermissionsUser>(
        r.data.usersPermissionsUsers,
      ),
  },
  deliveryZone: {
    url: 'deliveryZone',
    query: GET_DELIVERY_ZONE,
    resolver: (r) =>
      fragmentParse<DeliveryZone, GQLDeliveryZone>(r.data.deliveryZone),
  },
  deliveryZoneByUser: {
    url: 'deliveryZones',
    query: GET_DELIVERY_ZONES_BY_USER,
    resolver: (r) => {
      const zones =
        fragmentParse<DeliveryZone[], GQLDeliveryZone>(r.data.deliveryZones) ??
        [];
      const users =
        fragmentParse<(HostUser | PrivateUser)[], GQLUsersPermissionsUser>(
          r.data.usersPermissionsUsers,
        ) ?? [];
      const orders = fragmentParse<Order[], GQLOrder>(r.data.orders) ?? [];
      let noZoneOrders = 0;
      let noZoneUsers = 0;
      for (let j = 0; j < zones.length; j++) {
        let numOfOrders = 0;
        let numOfUsers = 0;
        let numOfNoZoneUsers = 0;
        let numOfNoZoneOrders = 0;
        if (typeof users !== 'undefined') {
          users.forEach((user) => {
            if (user.delivery_zone) {
              if (user.delivery_zone.id === zones[j].id) {
                numOfUsers++;
              }
            } else {
              numOfNoZoneUsers++;
            }
          });
        }
        zones[j].numOfUsers = numOfUsers;
        if (typeof orders !== 'undefined') {
          orders.forEach((order) => {
            if (order.host.delivery_zone) {
              if (order.host.delivery_zone.id === zones[j].id) {
                numOfOrders++;
              }
            } else {
              numOfNoZoneOrders++;
            }
          });

          zones[j].numOfOrders = numOfOrders;
          noZoneUsers += numOfNoZoneUsers;
          noZoneOrders += numOfNoZoneOrders;
        }
      }
      if (noZoneOrders || noZoneUsers) {
        zones.push({
          id: '-2',
          updatedAt: '',
          createdAt: '',
          name: 'noZone',
          bakery: {} as User,
          numOfOrders: noZoneOrders,
          numOfUsers: noZoneUsers,
          __typename: 'DeliveryZone',
        });
      }
      return zones;
    },
  },
  bakelist: {
    url: 'orderedProducts',
    query: GET_BAKELIST,
    resolver: (r) => {
      const items =
        fragmentParse<OrderedProduct[], GQLOrderedProduct>(
          r.data.orderedProducts,
        ) ?? [];
      const products: BakeryListItem[] = [];
      for (const item of items) {
        let found = false;
        const { name } = item.base_product;
        for (let i = 0; i < products.length; i++) {
          if (products[i].name[0].id === name[0].id) {
            found = true;
            products[i].amount += item.amount;
            break;
          }
        }
        if (!found) {
          products.push({ name, amount: item.amount });
        }
      }
      return products;
    },
  },
  dashboardBakeryCont: {
    query: DASHBOARD_BAKERY_CONT,
    url: 'orders',
    resolver: (r): { users: User[]; orders: Order[] } => ({
      users:
        fragmentParse<User[], GQLUsersPermissionsUser>(
          r.data.usersPermissionsUsers,
        ) ?? [],
      orders: fragmentParse<Order[], GQLOrder>(r.data.orders) ?? [],
    }),
  },
  dashboardBakery: {
    url: 'dashboardData',
    query: DASHBOARD_BAKERY,
    resolver: (r): DashboardResponseDataItem => ({
      sales: r.data.dashboardData.sales.map(parseDashboardItemLarge),
      orders: r.data.dashboardData.orders.map(parseDashboardItem),
      products: r.data.dashboardData.products.map(parseDashboardItem),
      clients: r.data.dashboardData.clients.map(parseDashboardItem),
    }),
  },
  message: {
    url: 'message',
    query: GET_MESSAGE,
    resolver: (r) => fragmentParse<Message, GQLMessage>(r.data.message),
  },
  messages: {
    url: 'messages',
    query: GET_MESSAGES,
    resolver: (r): Message[] =>
      fragmentParse<Message[], GQLMessage>(r.data.messages) ?? [],
  },
  conversations: {
    url: 'messages',
    query: GET_CONVERSATIONS,
    resolver: (r): Message[] =>
      fragmentParse<Message[], GQLMessage>(r.data.messages) ?? [],
  },
  newsfeedBakery: {
    url: 'usersPermissionsUsers',
    query: GET_NEWSFEED_BAKERY,
    resolver: (r): FeedItem[] => {
      const users =
        fragmentParse<User[], GQLUsersPermissionsUser>(
          r.data.usersPermissionsUsers,
        ) ?? [];
      const bills = fragmentParse<Bill[], GQLBill>(r.data.bills) ?? [];
      const products =
        fragmentParse<Product[], GQLProduct>(r.data.products) ?? [];
      const orders = fragmentParse<Order[], GQLOrder>(r.data.orders) ?? [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const all = users.concat(bills).concat(products).concat(orders);
      return createFeed(
        all as (HostUser | PrivateUser)[] | Order[] | Product[] | HostProduct[],
      );
    },
  },
  newsfeedHost: {
    url: 'usersPermissionsUsers',
    query: GET_NEWSFEED_HOST,
    resolver: (r): FeedItem[] => {
      const users =
        fragmentParse<User[], GQLUsersPermissionsUser>(
          r.data.usersPermissionsUsers,
        ) ?? [];
      const bills = fragmentParse<Bill[], GQLBill>(r.data.bills) ?? [];
      const products =
        fragmentParse<Product[], GQLProduct>(r.data.hostProducts) ?? [];
      const orders = fragmentParse<Order[], GQLOrder>(r.data.orders) ?? [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const all = users.concat(bills).concat(products).concat(orders);
      return createFeed(
        all as (HostUser | PrivateUser)[] | Order[] | Product[] | HostProduct[],
      );
    },
  },
  bill: {
    url: 'bill',
    query: GET_BILL,
    resolver: (r) => fragmentParse<Bill, GQLBill>(r.data.bill),
  },
  billsBakery: {
    url: 'bills',
    query: GET_BILLS_OF_BAKERY,
    resolver: (r): Bill[] => fragmentParse<Bill[], GQLBill>(r.data.bills) ?? [],
  },
  billsHost: {
    url: 'bills',
    query: GET_BILLS_OF_HOST,
    resolver: (r): Bill[] => fragmentParse<Bill[], GQLBill>(r.data.bills) ?? [],
  },
  vats: {
    url: 'vats',
    query: GET_VATS,
    resolver: (r): Vat[] => fragmentParse<Vat[], GQLVat>(r.data.vats) ?? [],
  },
  languages: {
    url: 'languages',
    query: GET_LANGUAGES,
    resolver: (r): Language[] =>
      fragmentParse<Language[], GQLLanguage>(r.data.languages) ?? [],
  },
  countOrdersUser: {
    url: 'orders',
    query: ORDERS_COUNT_USER,
    resolver: (r): number => getCount(r.data.orders),
  },
  countOrdersHost: {
    url: 'orders',
    query: ORDERS_COUNT_HOST,
    resolver: (r): number => getCount(r.data.orders),
  },
  countOrders: {
    url: 'orders',
    query: ORDERS_COUNT,
    resolver: (r): number => getCount(r.data.orders),
  },
  order: {
    url: 'order',
    query: GET_ORDER,
    resolver: (r) => fragmentParse<Order, GQLOrder>(r.data.order),
  },
  ordersBakeryComplete: {
    url: 'orders',
    query: GET_ORDERS_OF_BAKERY_TO_COMPILE,
    resolver: (r): Order[] =>
      fragmentParse<Order[], GQLOrder>(r.data.orders) ?? [],
  },
  ordersBakery: {
    url: 'orders',
    query: GET_ORDERS_OF_BAKERY,
    resolver: (r): Order[] =>
      fragmentParse<Order[], GQLOrder>(r.data.orders) ?? [],
  },
  ordersBakeryCalculation: {
    url: 'orders',
    query: GET_ORDERS_OF_BAKERY_FOR_CALCULATION,
    resolver: (r): Order[] =>
      fragmentParse<Order[], GQLOrder>(r.data.orders) ?? [],
  },
  ordersHost: {
    url: 'orders',
    query: GET_ORDERS_OF_HOST,
    resolver: (r): Order[] =>
      fragmentParse<Order[], GQLOrder>(r.data.orders) ?? [],
  },
  plans: {
    url: 'plans',
    query: GET_PLANS,
    resolver: (r): Plan[] => fragmentParse<Plan[], GQLPlan>(r.data.plans) ?? [],
  },
  checkUsername: {
    url: 'usersPermissionsUsers',
    query: CHECK_USERNAME,
    resolver: (r): boolean => getCount(r.data.usersPermissionsUsers) === 0,
  },
  checkEmail: {
    url: 'usersPermissionsUsers',
    query: CHECK_EMAIL,
    resolver: (r): boolean => getCount(r.data.usersPermissionsUsers) === 0,
  },
  support: {
    url: 'usersPermissionsUser',
    query: GET_SUPPORT,
    resolver: (r) =>
      fragmentParse<User, GQLUsersPermissionsUser>(r.data.usersPermissionsUser),
  },
  activeGuests: {
    url: 'usersPermissionsUsers',
    query: GET_ACTIVE_GUESTS_OF_HOST,
    resolver: (r): User[] =>
      fragmentParse<User[], GQLUsersPermissionsUser>(
        r.data.usersPermissionsUsers,
      ) ?? [],
  },
  clientsHost: {
    url: 'usersPermissionsUsers',
    query: GET_CLIENTS_OF_HOST,
    resolver: (r): GuestUser[] =>
      fragmentParse<GuestUser[], GQLUsersPermissionsUser>(
        r.data.usersPermissionsUsers,
      ) ?? [],
  },
  clientsBakery: {
    url: 'usersPermissionsUsers',
    query: GET_HOSTS_OF_BAKERY,
    resolver: (r): (HostUser | PrivateUser)[] =>
      fragmentParse<(HostUser | PrivateUser)[], GQLUsersPermissionsUser>(
        r.data.usersPermissionsUsers,
      ) ?? [],
  },
  newPrivates: {
    url: 'usersPermissionsUsers',
    query: GET_NEW_PRIVATES,
    resolver: (r): PrivateUser[] =>
      fragmentParse<PrivateUser[], GQLUsersPermissionsUser>(
        r.data.usersPermissionsUsers,
      ) ?? [],
  },
  countNewPrivates: {
    url: 'usersPermissionsUsers',
    query: COUNT_NEW_PRIVATES,
    resolver: (r): IntType => getCount(r.data.usersPermissionsUsers),
  },
  user: {
    url: 'usersPermissionsUser',
    query: GET_USER,
    resolver: (r) =>
      fragmentParse<User, GQLUsersPermissionsUser>(r.data.usersPermissionsUser),
  },
  searchEasyback: {
    url: 'products',
    query: SEARCH_EASYBACK_PRODUCT,
    resolver: (r): Product[] => fragmentParse(r.data.products) ?? [],
  },
  countHostProducts: {
    url: 'hostProducts',
    query: HOST_PRODUCTS_COUNT,
    resolver: (r): number => getCount(r.data.hostProducts),
  },
  hostProducts: {
    url: 'hostProducts',
    query: GET_HOST_PRODUCTS,
    resolver: (r): HostProduct[] => fragmentParse(r.data.hostProducts) ?? [],
  },
  hostProduct: {
    url: 'hostProduct',
    query: GET_HOST_PRODUCT,
    resolver: (r) => fragmentParse(r.data.hostProduct),
  },
  hostProductsByBase: {
    url: 'hostProducts',
    query: GET_HOST_PRODUCT_BY_BASE,
    resolver: (r): HostProduct[] => fragmentParse(r.data.hostProducts) ?? [],
  },
  bakeryProducts: {
    url: 'products',
    query: GET_BAKERY_PRODUCTS,
    resolver: (r): Product[] => fragmentParse(r.data.products) ?? [],
  },
  bakeryProductsByPriceList: {
    url: 'products',
    query: GET_BAKERY_PRODUCT_BY_PRICELIST,
    resolver: (r): Product[] => fragmentParse(r.data.products) ?? [],
  },
  bakeryProduct: {
    url: 'product',
    query: GET_BAKERY_PRODUCT,
    resolver: (r) => fragmentParse(r.data.product),
  },
  activeHoliday: {
    url: 'holidays',
    query: GET_ACTIVE_HOLIDAY_OF_BAKERY,
    resolver: (r): Holiday[] => fragmentParse(r.data.holidays) ?? [],
  },
  holidayBakery: {
    url: 'holidays',
    query: GET_HOLIDAYS_OF_BAKERY,
    resolver: (r): Holiday[] => fragmentParse(r.data.holidays) ?? [],
  },
  holiday: {
    url: 'holiday',
    query: GET_HOLIDAY,
    resolver: (r) => fragmentParse(r.data.holiday),
  },
  findGuest: {
    url: 'usersPermissionsUsers',
    query: FIND_GUEST,
    resolver: (r): User[] => fragmentParse(r.data.usersPermissionsUsers) ?? [],
  },
  billableGuests: {
    url: 'usersPermissionsUsers',
    query: GET_BILLABLE_GUESTS,
    resolver: (r) => fragmentParse(r.data.usersPermissionsUsers) ?? [],
  },
  orderedProductsGuestTime: {
    url: 'orderedProducts',
    query: GET_ORDERED_PRODUCT_GUEST,
    resolver: (r) => fragmentParse(r.data.orderedProducts) ?? [],
  },
  billableHostsPrivates: {
    url: 'orders',
    query: GET_BILLABLE_HOSTS_PRIVATES,
    resolver: (r) => {
      const orders = fragmentParse<Order[], GQLOrder>(r.data.orders) ?? [];
      const users = orders
        .map((order) => (order.bill ? null : order.host))
        .filter((host) => host !== null);
      const uniques: BillableUser[] = [];
      users.forEach((user) => {
        if (user) {
          let isIn = false;
          uniques.forEach((u, i) => {
            if (u.id === user.id) {
              isIn = true;
              uniques[i].orders++;
            }
          });
          if (!isIn) {
            uniques.push({
              id: user.id,
              orders: 1,
              userdetail: user.userdetail,
              username: user.username,
            });
          }
        }
      });
      return uniques;
    },
  },
  billableOrders: {
    url: 'orders',
    query: GET_BILLABLE_ORDERS,
    resolver: (r) => fragmentParse(r.data.orders) ?? [],
  },
  ordersOfBill: {
    url: 'orders',
    query: ORDERS_OF_BILL,
    resolver: (r) => fragmentParse(r.data.orders) ?? [],
  },
  getPriceLists: {
    url: 'priceLists',
    query: GET_PRICELISTS,
    resolver: (r) => fragmentParse(r.data.priceLists) ?? [],
  },
  abosHost: {
    query: GET_ABOS_HOST,
    url: 'abos',
    resolver: (r) => fragmentParse(r.data.abos) ?? [],
  },
  abosBakery: {
    query: GET_ABOS_BAKERY,
    url: 'abos',
    resolver: (r) => fragmentParse(r.data.abos) ?? [],
  },
  abosHostGuest: {
    query: GET_ABOS_HOST_GUESTS,
    url: 'abos',
    resolver: (r) => fragmentParse(r.data.abos) ?? [],
  },
  abosBakeryGuests: {
    query: GET_ABOS_BAKERY_GUESTS,
    url: 'abos',
    resolver: (r) => fragmentParse(r.data.abos) ?? [],
  },
  ordersBakeryFind: {
    query: FIND_ORDERS_OF_BAKERY,
    url: 'orders',
    resolver: (r) => fragmentParse(r.data.orders) ?? [],
  },
};

// TODO billableHosts remove null if and filter
export const mutations: MutationType = {
  upload: {
    mutation: UPLOAD_IMAGE,
    resolver: (r) => fragmentParse(r.data.upload),
  },
  deleteImage: {
    mutation: DELETE_MEDIA,
    resolver: (r) => getId(r.data.deleteUploadFile),
  },
  createTranslation: {
    mutation: CREATE_TRANSLATION,
    resolver: (r) => fragmentParse(r.data.createTranslation),
  },
  editTranslation: {
    mutation: EDIT_TRANSLATION,
    resolver: (r) => fragmentParse(r.data.updateTranslation),
  },
  createProduct: {
    mutation: CREATE_PRODUCT,
    resolver: (r) => fragmentParse(r.data.createProduct),
  },
  editProduct: {
    mutation: EDIT_PRODUCT,
    resolver: (r) => fragmentParse(r.data.updateProduct),
  },
  productActive: {
    mutation: TOGGLE_PRODUCT_ACTIVE,
    resolver: (r) => fragmentParse(r.data.updateProduct),
  },
  productDisplayOrder: {
    mutation: CHANGE_PRODUCT_ORDER,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: (r): IDType[] => getIds(r.data.changeProductDisplayOrder),
  },
  deleteProduct: {
    mutation: DELETE_PRODUCT,
    resolver: (r) => getId(r.data.updateProduct),
  },
  deleteHostProduct: {
    mutation: DELETE_HOST_PRODUCT,
    resolver: (r) => getId(r.data.deleteHostProduct),
  },
  createHostProduct: {
    mutation: CREATE_HOST_PRODUCTS,
    resolver: (r) => fragmentParse(r.data.createHostProduct),
  },
  editHostProduct: {
    mutation: EDIT_HOST_PRODUCT,
    resolver: (r) => fragmentParse(r.data.updateHostProduct),
  },
  placeOrder: {
    mutation: PLACE_ORDER,
    resolver: (r) => fragmentParse(r.data.createOrder),
  },
  orderedProduct: {
    mutation: CREATE_ORDERED_PRODUCT,
    resolver: (r) => fragmentParse(r.data.createOrderedProduct),
  },
  removeOrderedProduct: {
    mutation: REMOVE_ORDERED_PRODUCT,
    resolver: (r) => getId(r.data.deleteOrderedProduct),
  },
  deleteOrder: {
    mutation: DELETE_ORDER,
    resolver: (r) => getId(r.data.deleteOrder),
  },
  acceptOrder: {
    mutation: ACCEPT_ORDER,
    resolver: (r) => fragmentParse(r.data.updateOrder),
  },
  changeLanguage: {
    mutation: CHANGE_LANG,
    resolver: (r) => fragmentParse(r.data.updateUsersPermissionsUser),
  },
  login: {
    mutation: LOGIN,
    resolver: (r): AuthResponse => r.data.login,
  },
  acceptPrivate: {
    mutation: ACCEPT_PRIVATE,
    resolver: (r) => fragmentParse(r.data.updateUsersPermissionsUser),
  },
  deleteUser: {
    mutation: DELETE_USER,
    resolver: (r) => fragmentParse(r.data.updateUsersPermissionsUser),
  },
  restoreUser: {
    mutation: RESTORE_USER,
    resolver: (r) => fragmentParse(r.data.updateUsersPermissionsUser),
  },
  editUser: {
    mutation: EDIT_USER,
    resolver: (r) => fragmentParse(r.data.updateUsersPermissionsUser),
  },
  initialUser: {
    mutation: INITIAL_USER_UPDATE,
    resolver: (r) => fragmentParse(r.data.updateUsersPermissionsUser),
  },
  confirmMail: {
    mutation: CONFIRM_MAIL,
    resolver: (r) => r.data.emailConfirmation,
  },
  changePw: {
    mutation: CHANGE_PW,
    resolver: (r) => fragmentParse(r.data.updateUsersPermissionsUser),
  },
  createHost: {
    mutation: CREATE_HOST,
    resolver: (r) => fragmentParse(r.data.createUsersPermissionsUser),
  },
  createGuest: {
    mutation: CREATE_GUEST,
    resolver: (r) => fragmentParse(r.data.createUsersPermissionsUser),
  },
  register: {
    mutation: REGISTER,
    resolver: (r) => r.data.register,
  },
  addUserInfo: {
    mutation: ADD_USER_INFO,
    resolver: (r) => fragmentParse(r.data.createUserdetail),
  },
  editUserInfo: {
    mutation: EDIT_USER_INFO,
    resolver: (r) => fragmentParse(r.data.updateUserdetail),
  },
  passwordForget: {
    mutation: PASSWORD_FORGET,
    resolver: (r) => r.data.forgotPassword?.ok ?? false,
  },
  passwordReset: {
    mutation: PASSWORD_RESET,
    resolver: (r) => r.data.resetPassword,
  },
  deleteMessage: {
    mutation: DELETE_MESSAGE,
    resolver: (r) => getId(r.data.deleteMessage),
  },
  createMessage: {
    mutation: CREATE_MESSAGE,
    resolver: (r) => fragmentParse(r.data.createMessage),
  },
  replyMessage: {
    mutation: REPLY_MESSAGE,
    resolver: (r) => fragmentParse(r.data.createMessage),
  },
  editMessage: {
    mutation: EDIT_MESSAGE,
    resolver: (r) => fragmentParse(r.data.updateMessage),
  },
  createDeliveryZone: {
    mutation: CREATE_DELIVERY_ZONE,
    resolver: (r) => fragmentParse(r.data.createDeliveryZone),
  },
  deleteDeliveryZone: {
    mutation: DELETE_DELIVERY_ZONE,
    resolver: (r) => getId(r.data.deleteDeliveryZone),
  },
  createHoliday: {
    mutation: CREATE_HOLIDAY,
    resolver: (r) => fragmentParse(r.data.createHoliday),
  },
  deleteHoliday: {
    mutation: DELETE_HOLIDAY,
    resolver: (r) => getId(r.data.deleteHoliday),
  },
  acceptAll: {
    mutation: ACCEPT_ALL_PRODUCTS,
    resolver: (r) => fragmentParse(r.data.acceptAll) ?? [],
  },
  uploadMeasuredWeight: {
    mutation: UPLOAD_MEASURED_WEIGHT,
    resolver: (r) => fragmentParse(r.data.updateOrderedProduct),
  },
  changeDeadline: {
    mutation: CHANGE_DEADLINE,
    resolver: (r) => fragmentParse(r.data.updateOrder),
  },
  createBill: {
    mutation: CREATE_BILL,
    resolver: (r) => fragmentParse(r.data.createBill),
  },
  editBill: {
    mutation: EDIT_BILL,
    resolver: (r) => fragmentParse(r.data.updateBill),
  },
  sendBill: {
    mutation: SEND_BILL,
    resolver: (r) => fragmentParse(r.data.updateBill),
  },
  payBill: {
    mutation: PAY_BILL,
    resolver: (r) => fragmentParse(r.data.updateBill),
  },
  createBillSettings: {
    mutation: CREATE_BILL_SETTINGS,
    resolver: (r) => fragmentParse(r.data.createBillSetting),
  },
  editBillSettings: {
    mutation: EDIT_BILL_SETTINGS,
    resolver: (r) => fragmentParse(r.data.updateBillSetting),
  },
  deleteBill: {
    mutation: DELETE_BILL,
    resolver: (r) => getId(r.data.deleteBill),
  },
  createPriceList: {
    mutation: CREATE_PRICE_LIST,
    resolver: (r) => fragmentParse(r.data.createPriceList),
  },
  deletePriceList: {
    mutation: DELETE_PRICE_LIST,
    resolver: (r) => getId(r.data.deletePriceList),
  },
  renamePriceList: {
    mutation: RENAME_PRICE_LIST,
    resolver: (r) => fragmentParse(r.data.updatePriceList),
  },
  addProductPriceList: {
    mutation: ADD_PRODUCT_PRICELIST,
    resolver: (r) => fragmentParse(r.data.createProductPriceList),
  },
  updateUserPriceList: {
    mutation: UPDATE_USER_PRICE_LIST,
    resolver: (r) => fragmentParse(r.data.updateUserPriceList),
  },
  removeProductPriceList: {
    mutation: REMOVE_PRODUCT_PRICELIST,
    resolver: (r) => getId(r.data.deleteProductPriceList),
  },
  editProductPriceList: {
    mutation: EDIT_PRODUCT_PRICELIST,
    resolver: (r) => fragmentParse(r.data.updateProductPriceList),
  },
  setDefaultPriceList: {
    mutation: SET_DEFAULT_PRICE_LIST,
    resolver: (r) => fragmentParse(r.data.updatePriceList),
  },
  incrDDNumber: {
    mutation: INCREASE_DD_NUMBER,
    resolver: (r) => fragmentParse(r.data.updateBillSetting),
  },
  incrBillNumber: {
    mutation: INCREASE_BILL_NUMBER,
    resolver: (r) => fragmentParse(r.data.updateBillSetting),
  },
};

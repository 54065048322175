import { Keyword, LanguageISO } from './types';
import { BAKERY, SLOGAN, URL } from './index';
// reload
const generalKeywords = {
  template: {
    de: '',
    it: '',
    en: '',
  },
  all: {
    de: 'Alle',
    it: 'Tutti',
    en: 'All',
  },
  home: {
    de: 'Home',
    it: 'Home',
    en: 'Home',
  },
  translations: {
    de: 'Übersetzungen',
    it: 'Traduzioni',
    en: 'Translations',
  },
  accept: {
    de: 'Akzeptieren',
    it: 'Accettare',
    en: 'Accept',
  },
  new: {
    de: 'NEU',
    it: 'NUOVO',
    en: 'NEW',
  },
  default: {
    de: 'Standard',
    it: 'Standard',
    en: 'Default',
  },
  forMe: {
    de: '-- Für mich --',
    it: '-- Per me --',
    en: '-- For me --',
  },
  you: {
    de: 'Sie',
    it: 'Tu',
    en: 'You',
  },
  notConfirmed: {
    de: 'E-Mail noch nicht bestätigt!',
    it: "Non ancora confermato l' indirizzo e-mail!",
    en: 'E-Mail not yet confirmed!',
  },
  notAccepted: {
    de: 'Noch nicht akzeptiert!',
    it: 'Non ancora accettato!',
    en: 'Not yet accepted!',
  },
  blocked: {
    de: 'Blockiert!',
    it: 'Bloccato!',
    en: 'Blocked!',
  },
  dashboard: {
    de: 'Dashboard',
    it: 'Dashboard',
    en: 'Dashboard',
  },
  clients: {
    de: 'Kunden',
    it: 'Clienti',
    en: 'Clients',
  },
  settings: {
    de: 'Einstellungen',
    it: 'Impostazioni',
    en: 'Settings',
  },
  products: {
    de: 'Produkte',
    it: 'Prodotti',
    en: 'Products',
  },
  product: {
    de: 'Produkt',
    it: 'Prodotto',
    en: 'Product',
  },
  thumb: {
    de: 'Bild',
    it: 'Immagine',
    en: 'Thumbnail',
  },
  myProducts: {
    de: 'Meine Produkte',
    it: 'Miei prodotti',
    en: 'My products',
  },
  of: {
    de: 'Vom',
    it: 'Del',
    en: 'Of',
  },
  ofSmall: {
    de: 'vom',
    it: 'del',
    en: 'of',
  },
  view: {
    de: 'Ansehen',
    it: 'Mostra',
    en: 'Show',
  },
  create: {
    de: 'Jetzt Erstellen',
    it: 'Crea adesso',
    en: 'Create now',
  },
  edit: {
    de: 'Bearbeiten',
    it: 'Modifica',
    en: 'Edit',
  },
  save: {
    de: 'Speichern',
    it: 'Salva',
    en: 'Save',
  },
  back: {
    de: 'Zurück',
    it: 'Indietro',
    en: 'Back',
  },
  savedSuccessfully: {
    de: 'Erfolgreich gespeichert',
    it: 'Salvato con successo',
    en: 'Saved Successfully',
  },
  saveFailed: {
    de: 'Konnte nicht gespeichert werden',
    it: 'Impossibile salvare',
    en: 'Could not be saved',
  },
  importedSuccessfully: {
    de: 'Erfolgreich importiert',
    it: 'Importo con successo',
    en: 'Imported Successfully',
  },
  delete: {
    de: 'Löschen',
    it: 'Elimina',
    en: 'Delete',
  },
  sureDelete: {
    de: 'Sind sie sicher dass Sie [name] löschen möchten?',
    it: 'Sei sicuro di voler eliminare [name]?',
    en: 'Are you sure you want to delete [name]?',
  },
  onPlayStore: {
    de: 'Jetzt bei Google Play',
    it: 'Disponibile su Google Play',
    en: 'Get it on Google Play',
  },
  onIOSStore: {
    de: 'Laden im App Store',
    it: 'Download sul App Store',
    en: 'Download on the App Store',
  },
  page: { de: 'Seite', it: 'Pagina', en: 'Page' },
  pages: { de: 'Seiten', it: 'Pagine', en: 'Pages' },
  language: { de: 'Sprache', it: 'Lingua', en: 'Language' },
  accountType: {
    de: 'Account Typ',
    it: 'Tipo di account',
    en: 'Account type',
  },
  host: { de: 'Gastgeber', it: 'Ospitante', en: 'Host' },
  guest: { de: 'Gast', it: 'Ospite', en: 'Guest' },
  bakery: { de: 'Bäckerei', it: 'Panetteria', en: 'Bakery' },
  mail: { de: 'E-Mail Adresse', it: 'Indirizzo e-mail', en: 'E-Mail address' },
  name: { de: 'Name', it: 'Nome', en: 'Name' },
  appDownload: {
    de: 'Lade die App',
    it: "Scarica l'App",
    en: 'Download the App',
  },
  appAvailable: {
    de: 'Die Bestell App ist verfügbar für IOS und Android.',
    it: "Scarica l'App",
    en: 'Download the App',
  },
  loadMore: {
    de: 'Lade Mehr',
    it: 'Carica piú',
    en: 'Load more',
  },
  allReady: {
    de: 'Alles Bereit',
    it: 'Tutto pronto',
    en: 'Everything ready',
  },
  yes: {
    de: 'Ja',
    it: 'Si',
    en: 'Yes',
  },
  no: {
    de: 'Nein',
    it: 'No',
    en: 'No',
  },
  cancel: {
    de: 'Abbrechen',
    it: 'Annulla',
    en: 'Cancel',
  },
  show: {
    de: 'Anzeigen',
    it: 'Mostra',
    en: 'Show',
  },
  none: {
    de: '-- Keine --',
    it: '-- Nessun --',
    en: '-- None --',
  },
  choose: {
    de: '-- Auswählen --',
    it: '-- Seleziona --',
    en: '-- Choose --',
  },
  change: {
    de: 'Ändern',
    it: 'Cambia',
    en: 'Change',
  },
  send: {
    de: 'Senden',
    it: 'Invia',
    en: 'Send',
  },
  day: {
    de: 'Tag',
    it: 'Giorno',
    en: 'Day',
  },
  week: {
    de: 'Woche',
    it: 'Settimana',
    en: 'Week',
  },
  month: {
    de: 'Monat',
    it: 'Mese',
    en: 'Month',
  },
  year: {
    de: 'Jahr',
    it: 'Anno',
    en: 'Year',
  },
  clientCompanyName: {
    de: 'Firmenname',
    it: 'Nome della ditta',
    en: 'Company name',
  },
  fullName: {
    de: 'Vollständiger Name',
    it: 'Nome e cognome',
    en: 'Full Name',
  },
  country: {
    de: 'Land',
    it: 'Stato',
    en: 'Country',
  },
  street: {
    de: 'Straße',
    it: 'Via',
    en: 'Street',
  },
  houseNumber: {
    de: 'Hausnummer',
    it: 'Numero',
    en: 'Number',
  },
  cityPostalCode: {
    de: 'PLZ und Stadt',
    it: 'CAP e città',
    en: 'CAP and city',
  },
  partitaIva: {
    de: 'MwStr. Nr',
    it: 'Partita IVA',
    en: 'VAT number',
  },
  pec: {
    de: 'PEC-Adresse',
    it: 'Indirizzo PEC',
    en: 'PEC-Address',
  },
  recipientCode: {
    de: 'Empfängercodex',
    it: 'Codice destinatario',
    en: 'Recipient Code',
  },
  taxNumber: {
    de: 'Steuernummer',
    it: 'Codice fiscale',
    en: 'Tax number',
  },
  nothingChanged: {
    de: 'Nichts verändert',
    it: 'Niente è cambiato',
    en: 'Nothing changed',
  },
  fillInUserdetail: {
    de: 'Sie müssen zuerst Ihre Daten vervollständigen sodass die Bäckerei Ihre Adresse und Kontaktdaten sieht. Gehen Sie dazu aufs Dashboard oder Ihr Profil.',
    it: 'Devi prima completare i tuoi dati in modo che the Bakery possa vedere il tuo indirizzo e i dettagli di contatto. Per fare ciò, vai alla dashboard o al tuo profilo.',
    en: 'You must first complete your details so that the Bakery can see your address and contact details. To do this, go to the dashboard or your profile.',
  },
};
const orderKeywords = {
  bill: {
    de: 'Rechnung',
    it: 'Fattura',
    en: 'Invoice',
  },
  createFirstOrder: {
    de: 'Erstelle deine erste Bestellung',
    it: 'Crea il tuo primo ordine',
    en: 'Create your first order',
  },
  today: {
    de: 'Heute',
    it: 'Oggi',
    en: 'Today',
  },
  tomorrow: {
    de: 'Morgen',
    it: 'Domani',
    en: 'Tomorrow',
  },
  dayAfterTomorrow: {
    de: 'Übermorgen',
    it: 'Dopodomani',
    en: 'The day after tomorrow',
  },
  noOrdersToday: {
    de: 'Heute haben Sie keine offenen Bestellungen für die Zone "[zone]".',
    it: 'Non hai ordini aperti oggi per la zona di consegna "[zone]".',
    en: 'You have no open orders today for the delivery zone "[zone]".',
  },
  waitForOrders: {
    de: 'Sobald ein Kunde eine Bestellung aufgibt sehen Sie diese Hier.',
    it: 'Non appena un cliente effettua un ordine, lo vedrai qui.',
    en: 'As soon as a customer places an order you will see it here.',
  },
  newOrder: {
    de: 'Neue Bestellung',
    it: 'Nuovo ordine',
    en: 'New order',
  },
  customMessage: {
    de: 'Zusätzliche Nachricht',
    it: 'Messaggio aggiuntivo',
    en: 'Additional message',
  },
  createOrder: {
    de: 'Bestellung erstellen',
    it: 'Creare ordine',
    en: 'Create Order',
  },
  order: {
    de: 'Bestellung',
    it: 'Ordine',
    en: 'Order',
  },
  forGuest: {
    de: 'Für wen?',
    it: 'Per chi?',
    en: 'For whom?',
  },
  orderedBy: {
    de: 'Bestellt von',
    it: 'Ordinato da',
    en: 'Ordered by',
  },
  productBy: {
    de: 'Hersteller',
    it: 'Produttore',
    en: 'Manufacturer',
  },
  addProducts: {
    de: 'Produkte hinzufügen',
    it: 'Aggiungi prodotti',
    en: 'Add products',
  },
  aboOrder: {
    de: 'Abo-Bestellung',
    it: 'Ordine di abbonamento',
    en: 'Subscription order',
  },
  deleteOrder: {
    de: 'Bestellung löschen',
    it: 'Cancella ordine',
    en: 'Delete order',
  },
  viewOrder: {
    de: 'Bestellung ansehen',
    it: 'Visualizza ordine',
    en: 'View order',
  },
  editOrder: {
    de: 'Bestellung bearbeiten',
    it: 'Modifica ordine',
    en: 'Edit order',
  },
  activeOrder: {
    de: 'Aktive Bestellung',
    it: 'Ordine attivo',
    en: 'Active order',
  },
  notAccepted: {
    de: 'Nicht akzeptiert',
    it: 'Non accettato',
    en: 'Not accepted',
  },
  accepted: {
    de: 'Akzeptiert',
    it: 'Accettato',
    en: 'Accepted',
  },
  payment: {
    de: 'Zahlung',
    it: 'Pagamento',
    en: 'Payment',
  },
  orderDate: {
    de: 'Bestelldatum',
    it: "Data dell' ordine",
    en: 'Order date',
  },
  orderNumber: {
    de: 'Bestellnummer',
    it: "Numero dell' ordine",
    en: 'Order number',
  },
  info: {
    de: 'Informationen',
    it: 'Informazioni',
    en: 'Information',
  },
  price: {
    de: 'Preis',
    it: 'Prezzo',
    en: 'Price',
  },
  noOrdersYet: {
    de: 'Noch keine Bestellungen',
    it: 'Nessun ordine ancora',
    en: 'No orders yet',
  },
  makeOrderWithApp: {
    de: 'Sie können mit der App Bestellungen erstellen.',
    it: "Puoi creare ordini con l' app.",
    en: 'You can create orders with the app.',
  },
  orderNr: {
    de: 'Bestellnummer',
    it: "Numero d'ordine",
    en: 'Order number',
  },
  paid: {
    de: 'Bezahlt',
    it: 'Pagato',
    en: 'Paid',
  },
  notPaid: {
    de: 'Nicht bezahlt',
    it: 'Non pagato',
    en: 'Not paid',
  },
  orders: { de: 'Bestellungen', it: 'Ordini', en: 'Orders' },
  orderedArticles: {
    de: 'Bestellte Artikel',
    it: 'Articoli ordinati',
    en: 'Ordered articles',
  },
  hostProducts: {
    de: 'Meine Produkte',
    it: 'Miei Prodotti',
    en: 'My products',
  },
  pieces: { de: 'Stück', it: 'Pezzi', en: 'Pieces' },
  piecesShort: { de: 'St', it: 'Pz', en: 'Pcs' },
  summary: { de: 'Zusammenfassung', it: 'Sommario', en: 'Summary' },
  productSum: {
    de: 'Anzahl Produkte',
    it: 'Numero di prodotti',
    en: 'Number of products',
  },
  priceSum: { de: 'Summe', it: 'Totale', en: 'Total' },
  totalPrice: { de: 'Gesamtpreis', it: 'Totale', en: 'Total' },
  createdAt: { de: 'Erstellt am', it: 'Creato il', en: 'Created at' },
  orderId: { de: 'Bestellnummer', it: "Numero d'ordine", en: 'Order number' },
  deadline: {
    de: 'Deadline',
    it: 'Scadenza',
    en: 'Deadline',
  },
  deadlineHelp: {
    de: 'Bis welcher Uhrzeit kann bestellt werden, um die Lieferung am nächsten Tag zu bekommen.',
    it: 'Fino a che ora è possibile ordinare per ricevere la consegna il giorno successivo.',
    en: 'Until what time can be ordered in order to get the delivery the next day.',
  },
  hour: { de: 'Stunde', it: 'ora', en: 'hour' },
  hours: { de: 'Stunden', it: 'ore', en: 'hours' },
  minute: { de: 'Minute', it: 'minuto', en: 'minute' },
  minutes: { de: 'Minuten', it: 'minuti', en: 'minutes' },

  interval: {
    de: 'Interval',
    it: 'Intervallo',
    en: 'Interval',
  },
  everyDay: {
    de: 'Jeden Tag',
    it: 'Ogni giorno',
    en: 'Every day',
  },

  deliveryEvery: {
    de: 'Lieferung jeden',
    it: 'Consegna ogni',
    en: 'Delivery every',
  },
  ofMonth: {
    de: 'des Monats',
    it: 'del mese',
    en: 'of the month',
  },
  compileOrders: {
    de: 'Bestellungen wiegen',
    it: 'Metti insieme gli ordini',
    en: 'Compile orders',
  },
  tabletMode: {
    de: 'Tablet Ansicht',
    it: 'Vista tablet',
    en: 'Tablet view',
  },
  deliveryDocket: {
    de: 'Lieferschein',
    it: 'Documento di trasporto',
    en: 'Delivery Docket',
  },
  deliveryDocketNumber: {
    de: 'Lieferschein-Nummer',
    it: 'Numero del documento di trasporto',
    en: 'Number of the delivery Docket',
  },
  printDD: {
    de: 'Lieferscheine generieren',
    it: 'Genera bolle',
    en: 'Generate delivery docket',
  },
  startCompile: {
    de: 'Wiegen starten',
    it: 'Comincia a compilare',
    en: 'Start compiling',
  },
  selectDeliveryZone: {
    de: 'Bitte Lieferzone auswählen',
    it: 'Seleziona una zona di consegna',
    en: 'Select a delivery zone',
  },
  printLabels: {
    de: 'Etikette Drucken',
    it: 'Stampa etichetta',
    en: 'Print label',
  },
  configurePrinter: {
    de: 'Drucker konfigurieren',
    it: 'Configurare stampante',
    en: 'Configure printer',
  },
  selectPrinterPredefined: {
    de: 'Vordefinierten Drucker auswählen',
    it: 'Scegli stampante preconfigurato',
    en: 'Select a predefined printer',
  },
  createPrinter: {
    de: 'Druckerkonfiguration erstellen',
    it: 'Crea configurazione stampante',
    en: 'Create printer configuration',
  },
  changePrinter: {
    de: 'Drucker ändern!',
    it: 'Cambia stampante!',
    en: 'Change printer!',
  },
  printerWidth: {
    de: 'Etikettenbreite',
    it: "Larghezza dell'etichetta",
    en: 'Label width',
  },
  withPrice: {
    de: 'Preis auf Etiketten',
    it: 'Prezzo sulle etichette',
    en: 'Price on labels',
  },
  seperatedGuests: {
    de: 'Nach Gäste-Accounts aufteilen',
    it: 'Diviso per account ospiti',
    en: 'Split by guest accounts ',
  },
  finished: { de: 'Fertig', it: 'Finito', en: 'Finished' },
  productCouldNotRemoved: {
    de: 'Produkt kann nicht entfernt werden.',
    it: 'Il prodotto non può essere rimosso.',
    en: 'Product cannot be removed.',
  },
  productRemoved: {
    de: 'Produkt entfernt',
    it: 'Prodotto rimosso',
    en: 'Product removed',
  },
  noHostProductDefined: {
    de: 'Kein Produkt für Ihre Gäste definiert.',
    it: 'Nessun prodotto definito per i tuoi ospiti.',
    en: 'No product defined for your guests.',
  },
  couldNotOrder: {
    de: 'Produkt konnte nicht hinzugefügt werden.',
    it: 'Impossibile aggiungere il prodotto.',
    en: 'Product could not be added.',
  },
  productAdded: {
    de: 'Produkt hinzugefügt',
    it: 'prodotto aggiunto',
    en: 'Product added',
  },
  noGuestFound: {
    de: 'Gast konnte nicht gefunden werden.',
    it: "Impossibile trovare l'ospite.",
    en: 'Guest could not be found.',
  },
  productNotFound: {
    de: 'Produkt konnte nicht gefunden werden.',
    it: 'Impossibile trovare il prodotto.',
    en: 'Product could not be found.',
  },
  orderCouldNotRemoved: {
    de: 'Bestellung konnte nicht gelöscht werden.',
    it: "Impossibile eliminare l'ordine.",
    en: 'Order could not be deleted.',
  },
  orderRemoved: {
    de: 'Bestellung gelöscht',
    it: 'Ordine eliminato',
    en: 'Order deleted',
  },
  orderCouldNotCreate: {
    de: 'Bestellung konnte nicht erstellt werden.',
    it: "Impossibile creare l'ordine.",
    en: 'Order could not be created.',
  },
  orderCreated: {
    de: 'Bestellung erfolgreich erstellt',
    it: 'Ordine creato con successo',
    en: 'Order created successfully',
  },
  orderNotFound: {
    de: 'Bestellung konnte nicht gefunden werden.',
    it: "Impossibile trovare l'ordine.",
    en: 'Order could not be found.',
  },
  orderNotAllowed: {
    de: 'Sie sind nicht erlaubt diese Bestellung zu bearbeiten. ',
    it: 'Non sei autorizzato a modificare questo ordine.',
    en: 'You are not allowed to edit this order.',
  },
  selectProducts: {
    de: 'Wähle deine Lieblings-Produkte aus',
    it: 'Scegli i tuoi prodotti preferiti',
    en: 'Choose your favorite products',
  },
  selectProductTelOrder: {
    de: 'Wähle die Produkte aus',
    it: 'Scegli i prodotti',
    en: 'Choose the products',
  },
  legend: {
    de: 'Legende',
    it: 'Leggenda',
    en: 'Legend',
  },
  filter: {
    de: 'Filter',
    it: 'Filtra',
    en: 'Filter',
  },
  filterHost: {
    de: 'Nach Kunde filtern',
    it: 'Filtra per cliente',
    en: 'Filter by customer',
  },
  filterPrice: {
    de: 'Nach Preis filtern',
    it: 'Filtro per prezzo',
    en: 'Filter by price',
  },
  filterTime: {
    de: 'Nach Bestelldatum filtern',
    it: "Filtro per data d'ordine",
    en: 'Filter by date',
  },
  daySubstract: {
    de: 'Ein Tag früher liefern',
    it: 'Consegna un giorno prima',
    en: 'Deliver one day earlier',
  },
  dayAdd: {
    de: 'Ein Tag später liefern',
    it: 'Consegna un giorno dopo',
    en: 'Deliver one day later',
  },
  activeOrders: {
    de: 'Bestellungen für den nächsten Liefertag',
    it: 'Ordini per il giorno di consegna successivo',
    en: 'Orders for the next delivery day',
  },
  pastOrders: {
    de: 'Vergangene Bestellungen',
    it: 'Ordini passati',
    en: 'Past orders',
  },
  otherOrders: {
    de: 'Alle Bestellungen',
    it: 'Tutti gli ordini',
    en: 'All orders',
  },
  futureOrders: {
    de: 'Zukünftigen Bestellungen',
    it: 'Ordini futuri',
    en: 'Future orders',
  },
  deliveryDate: {
    de: 'Lieferdatum',
    it: 'Data consegna',
    en: 'Delivery Date',
  },
  takeawayDate: {
    de: 'Abholdatum',
    it: 'Data di ritiro',
    en: 'Date of pick up',
  },
  selectClient: {
    de: 'Kunde auswählen',
    it: 'Scegli un cliente',
    en: 'Choose a client',
  },
  telOrderInfo: {
    de: 'Hier können Sie eine Bestellung für Ihre Kunden anlegen, bzw. wenn der Kunde bereits eine Bestellung für das ausgewählte Lieferdatum erstellt hat, werden die Produkte zur Bestellung hinzufügen.',
    en: 'Here you can create an order for your customers, or if the customer has already created an order for the selected delivery date, the products will be added to the order.',
    it: "Qui puoi creare un ordine per i tuoi clienti, oppure se il cliente ha già creato un ordine per la data di consegna selezionata, i prodotti verranno aggiunti all'ordine.",
  },
  selectDeliveryDate: {
    de: 'Lieferdatum wählen',
    it: 'Scegli la data di consegna',
    en: 'Choose delivery date',
  },
  dayAfterTomorrowCapital: {
    de: 'Übermorgen',
    it: 'Dopo domani',
    en: 'The day after tomorrow',
  },
  tomorrowCapital: {
    de: 'Morgen',
    it: 'Domain',
    en: 'Tomorrow',
  },
};
const billKeywords = {
  bills: {
    de: 'Rechnungen',
    it: 'Fatture',
    en: 'Bills',
  },
  billNr: {
    de: 'Rechnungsnummer',
    it: 'Numero fattura',
    en: 'Bill number',
  },
  noBillsYet: {
    de: 'Noch keine Rechnungen.',
    it: 'Nessuna fattura trovato.',
    en: 'No bills yet',
  },
  billsAppearWhenMade: {
    de: 'Hier sehen Sie Ihre Rechnungen sobald die Bäckerei Sie erstellt.',
    it: 'Qui vedrai le tue fatture non appena il panificio le crea.',
    en: 'Here you will see your invoices as soon as the bakery creates them.',
  },
  createBill: {
    de: 'Rechnung erstellen',
    it: 'Crea fattura',
    en: 'Create bill',
  },
  editBill: {
    de: 'Rechnung bearbeiten',
    it: 'Modifica fattura',
    en: 'Edit bill',
  },
  deleteBill: {
    de: 'Rechnung löschen',
    it: 'Elimina fattura',
    en: 'Delete bill',
  },
  viewBill: {
    de: 'Rechnung',
    it: 'Fattura',
    en: 'Bill',
  },
  redirectBill: {
    de: 'Weiterleitung',
    it: 'Inoltro',
    en: 'Redirect',
  },
  actions: {
    de: 'Aktionen',
    it: 'Azioni',
    en: 'Actions',
  },
  newBill: {
    de: 'Neue Rechnung',
    it: 'Nuova fattura',
    en: 'New bill',
  },
  billingDisabled: {
    de: 'Rechnungserstellung deaktiviert',
    it: 'Fatturazione disattivato',
    en: 'Invoicing disabled',
  },
  billingDisabledCont: {
    de: 'Die Rechnungserstellung haben Sie deaktiviert, wollen Sie dennoch Rechnungen über SlowVita erstellen bitte gehen Sie zu den Einstellungen (Profil-Icon rechts oben), aktivieren sie die Rechnungsstellung und kontaktieren Sie den Support sollten dabei Fragen aufkommen.',
    it: "Hai disattivato la fatturazione, ma se desideri comunque creare fatture tramite SlowVita, vai nelle impostazioni (icona del profilo in alto a destra), attiva la fatturazione e contatta l'assistenza in caso di domande.",
    en: 'You have deactivated invoicing, but if you still want to create invoices via SlowVita, please go to the settings (profile icon at the top right), activate invoicing and, if you have any questions, contact support.',
  },
  noBillableOrders: {
    de: 'Keine Bestellung für [client] in diesen Zeitraum zur fakturierung gefunden.',
    it: 'Non è stato trovato alcun ordine per la fatturazione per [client] in questo periodo.',
    en: 'No order was found for billing for [client] in this period.',
  },
  changePeriodOrClient: {
    de: 'Kunde oder Zeitraum ändern.',
    it: 'Cambia cliente o periodo.',
    en: 'Change client or period.',
  },
  billNumber: {
    de: 'Rechnungsnummer:',
    it: 'Numero fattura:',
    en: 'Invoice number:',
  },
  billDate: {
    de: 'Rechnungsdatum:',
    it: 'Data fattura:',
    en: 'Invoice date:',
  },
  billAmount: {
    de: 'Rechnungsbetrag:',
    it: 'Importo fattura:',
    en: 'Invoice amount:',
  },
  includeInBill: {
    de: 'In Rechnung aufnehmen',
    it: 'Includi in fattura',
    en: 'Include in invoice',
  },
  pdfPreview: {
    de: 'PDF Vorschau',
    it: 'PDF anteprima',
    en: 'PDF preview',
  },
  downloadPDF: {
    de: 'PDF Download',
    it: 'Scarica PDF',
    en: 'Download PDF',
  },
  downloadingPDF: {
    de: 'Ladet...',
    it: 'Scaricando...',
    en: 'Downloading...',
  },
  markPayed: {
    de: 'Als bezahlt markieren',
    it: 'Segna come pagato',
    en: 'Mark as paid',
  },
  sendXML: {
    de: 'An Portal senden',
    it: 'Consegna SDI',
    en: 'Send to SDI',
  },
  sendPdf: {
    de: 'An Kunde senden',
    it: 'Consegna a cliente',
    en: 'Send to client',
  },
  toLateToDelete: {
    de: 'Diese Rechnung kann nicht mehr gelöscht werden.',
    it: 'Questa fattura non può più essere eliminata.',
    en: 'This invoice can no longer be deleted.',
  },
};
const errorKeywords = {
  whatAreULooking: {
    de: 'Was suchen Sie?',
    it: 'Che cosa sta cercando?',
    en: 'What are you looking for?',
  },
  search: {
    de: 'Suchen',
    it: 'Ricerca',
    en: 'Search',
  },
  pageNotFound: {
    de: 'Die gesuchte Seite wurde nicht gefunden.',
    it: 'La pagina che stai cercando non è stata trovata.',
    en: 'The page you are looking for was not found.',
  },
  oops: {
    de: 'Hoppla! Du bist verloren.',
    it: 'Ops! Ti sei perso.',
    en: "Oops! You're lost.",
  },
  houston: {
    de: 'Houston, wir haben ein Problem!',
    it: 'Houston abbiamo un problema!',
    en: 'Houston, we have a problem!',
  },
  pageUnavailable: {
    de: 'Die gesuchte Seite ist vorübergehend nicht verfügbar.',
    it: 'La pagina che stai cercando è temporaneamente non disponibile.',
    en: 'The page you are looking for is temporarily unavailable.',
  },
  unknownError: {
    de: 'Ein unerwarteter Fehler ist aufgetreten',
    it: 'Si è verificato un errore imprevisto',
    en: 'An unexpected error occurred',
  },
  clientUserdetail: {
    de: 'Der Kunde muss noch die Rechnungsdaten eintragen!',
    it: 'Il cliente deve ancora inserire i dati di fatturazione!',
    en: 'The customer still has to enter the billing data!',
  },
};
const clientKeywords = {
  clientNotFound: {
    de: 'Kunde konnte nicht gefunden werden',
    it: 'Impossibile trovare il cliente',
    en: 'Client could not be found',
  },
  createUser: {
    de: 'Kunde erstellen',
    it: 'Crea un cliente',
    en: 'Create client',
  },
  editUser: {
    de: 'Kunde bearbeiten',
    it: 'Modifica cliente',
    en: 'Edit client',
  },
  deleteUser: {
    de: 'Kunde löschen',
    it: 'Elimina cliente',
    en: 'Delete client',
  },
  usernameHelp: {
    de: 'Erstellen Sie Hier einen Benutzername mit welchen der Benutzer sich anmelden kann.',
    it: "Crea qui un nome utente con il quale l'utente può accedere.",
    en: 'Create a username here with which the user can log in.',
  },
  email: {
    de: 'E-Mail Adresse',
    it: 'Indirizzo mail',
    en: 'E-Mail address',
  },
  languageHelp: {
    de: 'Wählen Sie die Anzeigesprache für den Kunden aus.',
    it: 'Seleziona la lingua di visualizzazione per il cliente.',
    en: 'Select the display language for the customer.',
  },
  noClientsYet: {
    de: 'Noch keine Kunden vorhanden.',
    it: 'Nessun cliente ancora.',
    en: 'No clients yet.',
  },
  createFirstClient: {
    de: 'Fügen Sie Ihren ersten Kunden hinzu.',
    it: 'Aggiungi il tuo primo cliente',
    en: 'Add your first client.',
  },
  newClient: {
    de: 'Neuer Kunde',
    it: 'Nuovo cliente',
    en: 'New client',
  },
  newClients: {
    de: 'Neue Kunden',
    it: 'Nuovi clienti',
    en: 'New clients',
  },
  createNewClient: {
    de: 'Füge ein neuen Kunden hinzu.',
    it: 'Aggiungi un nuovo cliente.',
    en: 'Add a new client.',
  },
  createClientSuccess: {
    de: 'Kunde erfolgreich erstellt.',
    it: 'Cliente creato con successo.',
    en: 'Client created successfully.',
  },
  createClientFailed: {
    de: 'Kunde konnte nicht erstellt werden.',
    it: 'Impossibile creare il cliente.',
    en: 'Customer could not be created.',
  },
  editClientSuccess: {
    de: 'Kunde erfolgreich erstellt.',
    it: 'Cliente creato con successo.',
    en: 'Client created successfully.',
  },
  activeFrom: { de: 'Aktiv von', it: 'Attivo da', en: 'Active from' },
  activeTo: { de: 'Aktiv bis', it: 'Attivo fino a', en: 'Active until' },
  activeFromHelp: {
    de: 'Geben Sie hier das Anreisedatum des Gastes an.',
    it: "Inserisci qui la data di arrivo dell'ospite.",
    en: "Enter the guest's arrival date here.",
  },
  activeToHelp: {
    de: 'Geben Sie hier das Abreisedatum des Gastes an.',
    it: "Inserisci qui la data di partenza dell'ospite.",
    en: "Enter the guest's departure date here.",
  },
  takeawayObligate: {
    de: 'Muss selbst abholen',
    it: 'Raccolta obbliga',
    en: 'Takeaway obligation',
  },
  takeawayObligateHelp: {
    de: 'Dem Kunde wird nicht ausgeliefert, d.h. er kann in der app nicht auswählen ob die Ware geliefert wird sondern hat nur die Option "Selbst abholen"',
    it: 'Per il cliente i pani non viene consegnato, cioè non può scegliere nell\'app se la merce viene consegnata ma ha solo l\'opzione "Ritirala"',
    en: 'No delivery to this customer, this means he cannot choose in the app whether the goods are be delivered but only has the option "Pick it up"',
  },
  qrCodes: { de: 'QR-Codes', it: 'Codici QR', en: 'QR-Codes' },
  qrcodeApp: {
    de: 'APP-Download',
    it: "Download dell'APP",
    en: 'APP download',
  },
  address: {
    de: 'Adresse',
    it: 'Indirizzo',
    en: 'Address',
  },
  qrcodeLogin: {
    de: 'Anmeldung Kunde',
    it: 'Registrazione per cliente',
    en: 'Login for client',
  },
  printQrcode: {
    de: 'QR-Codes Drucken',
    it: 'Stampa QR-Code',
    en: 'Print QR-Code',
  },
  print: {
    de: 'Ausdrucken',
    it: 'Stampa',
    en: 'Print',
  },
  printBakelist: {
    de: 'Backliste Drucken',
    it: 'Stampa lista',
    en: 'Print List',
  },
  printFull: {
    de: 'Anleitung Drucken',
    it: 'Stampa Istruzioni',
    en: 'Print Instructions',
  },
  noAddressYet: {
    de: 'Noch nicht eingetragen.',
    it: 'Non ancora specificato.',
    en: 'Not yet specified.',
  },
  informationNotAvailable: {
    de: 'Es liegen nicht alle Informationen vor um eine Anleitung zu generieren, bitte alle daten ausfüllen.',
    it: 'Non tutte le informazioni sono disponibili per generare istruzioni, si prega di compilare tutti i dati.',
    en: 'Not all information is available to generate instructions, please fill in all the data.',
  },
  private: {
    de: 'Privat',
    it: 'Privato',
    en: 'Private',
  },
  instWelcome: {
    de: 'Lust auf frische und lokale Produkte?',
    it: 'Voglia di prodotti freschi e locali?',
    en: 'Do you want fresh and local products?',
  },
  instDesc: {
    de:
      `Mit der ${BAKERY} App finden Sie diese täglich vor Ihrer Haustür. Dazu arbeiten wir mit der lokalen Bäckerei "[BAKERYNAME]" zusammen. Diese backt täglich* frisches Brot und liefert dieses, zusammen mit anderen tollen Produkten, an uns. \n` +
      `Auch Produkte aus unserer eigenen Produktion können Sie hier finden und bequem mit den Brötchen mitbestellen. \n`,
    it:
      `Con l'app di ${BAKERY} puoi trovarli a portata di mano ogni giorno. Per questo collaboriamo con il panificio locale "[BAKERYNAME]". Sfornano il pane fresco ogni giorno* e ce lo consegnano insieme ad altri ottimi prodotti.\n` +
      `Qui puoi anche trovare prodotti di nostra produzione e ordinarli comodamente con i rotoli.\n`,
    en:
      `With the ${BAKERY} app you can find them on your doorstep every day. For this we work together with the local bakery "[BAKERYNAME]". They bake fresh bread every day * and deliver it to us along with other great products.\n` +
      `You can also find products from our own production here and conveniently order them with the rolls.\n`,
  },
  instSubtitle: {
    de: 'So funktioniert’s:',
    it: 'Ecco come funziona:',
    en: 'This is how it works:',
  },
  instStep1: {
    de: 'Scannen Sie folgenden QR-Code um die App aus dem iOS / Android Store herunterzuladen',
    it: "Scansiona il seguente codice-QR per scaricare l'app dallo store iOS/Android",
    en: 'Scan the following QR-code to download the app from the iOS / Android store',
  },
  instStep2: {
    de:
      'Melden Sie sich mit folgenden Benutzerdaten an:\n' +
      'Benutzername: [USERNAME]\n' +
      'Password: [PASSWORD]\n' +
      'oder scannen Sie in der App folgenden QR-Code',
    it:
      'Accedi con i seguenti dati utente:\n' +
      'Utente: [USERNAME]\n' +
      'Password: [PASSWORD]\n' +
      "o scansiona il seguente codice QR nell'app",
    en:
      'Log in with the following user data:\n' +
      'Username: [USERNAME]\n' +
      'Password: [PASSWORD]\n' +
      'or scan the following QR code with the app',
  },
  instStep3: {
    de: 'Bestellen Sie auf was immer Sie Lust haben. Die Produkte warten am nächsten Tag vor der Tür.',
    it: 'Ordina quello che vuoi. I prodotti sono in attesa davanti alla porta il giorno successivo.',
    en: 'Order whatever you want. The products are waiting in front of the door the next day.',
  },
  instStep4: {
    de: 'WICHTIG: Die Bestellung muss bis spätestens [DEADLINE] des Vortages aufgegeben werden. ',
    it: "IMPORTANTE: l'ordine deve essere effettuato entro [DEADLINE] al massimo il giorno prima.",
    en: 'IMPORTANT: The order must be placed before [DEADLINE] the day before at the latest.',
  },
  instDesc2: {
    de:
      'Wir wünschen Ihnen einen schönen Aufenthalt. Bei Fragen oder Rückmeldungen, können Sie sich gerne an die Rezeption wenden.\n' +
      '\n' +
      'Die Abrechnung erfolgt am Tag der Abreise, zu der in der App angegebenen Preisen. Liefergebühren oder versteckte Kosten gibt es bei uns nicht!\n',
    it:
      'Vi auguriamo un piacevole soggiorno. In caso di domande o commenti, non esitate a contattare la reception.\n' +
      '\n' +
      "La fatturazione avviene il giorno della partenza, ai prezzi indicati nell'app. Non ci sono spese di spedizione o costi nascosti!\n",
    en:
      'We wish you a pleasant stay. If you have any questions or feedback, please feel free to contact reception.\n' +
      '\n' +
      'Billing takes place on the day of departure, at the prices specified in the app. There are no delivery charges or hidden costs!\n',
  },
  instStar: {
    de: '* außer Sonntage und Feiertage',
    it: '* Tranne le domeniche e i giorni di festa',
    en: '* Except Sundays & Holidays',
  },
  deliveryZone: {
    de: 'Lieferzone',
    it: 'Zona di consegna',
    en: 'Delivery Zone',
  },
  noZone: {
    de: 'Keine Zone',
    it: 'Nessuna zona',
    en: 'No zone',
  },
  createDeliveryZone: {
    de: 'Lieferzone erstellen',
    it: 'Crea zona di consegna',
    en: 'Create delivery zone',
  },
  deleteDeliveryZone: {
    de: 'Lieferzone löschen',
    it: 'Elimina zona di consegna',
    en: 'Delete delivery zone',
  },
  zoneName: {
    de: 'Name der Lieferzonen',
    it: 'Nome delle aree di consegna',
    en: 'Name of the delivery areas',
  },
  zoneNameHelp: {
    de: 'Wenn Sie mehrere verschiedene Lieferanten/Standorte haben können Sie Ihre Kunden in sogenannten Lieferzonen unterteilen. Sie können dann beim Zusammenstellen der Bestellungen eine Lieferzone auswählen. Die Lieferzone eines Kunden kann jederzeit geändert werden.',
    it: 'Se hai diversi fornitori/località, puoi dividere i tuoi clienti nelle cosiddette zone di consegna. È quindi possibile selezionare una zona di consegna durante la composizione degli ordini. La zona di consegna per un cliente può essere modificata in qualsiasi momento.',
    en: 'If you have several different suppliers / locations, you can divide your customers into so-called delivery zones. You can then select a delivery zone when compiling the orders. The delivery zone of a client can be changed at any time.',
  },
  deliveryZones: {
    de: 'Lieferzone',
    it: 'Zona di consegna',
    en: 'Delivery zone',
  },
  deliveryZoneHelp: {
    de: 'Mit dem Plus kannst du neue Lieferzonen anlegen, die Kunden können nachher nach Lieferzone gruppiert werden.',
    it: 'Con il plus puoi creare nuove zone di consegna, i clienti possono poi essere raggruppati per zona di consegna.',
    en: 'With the plus you can create new delivery zones, clients can then be grouped by delivery zone.',
  },
  zoneCreated: {
    de: 'Lieferzone erfolgreich erstellt',
    it: 'Zona di consegna creata con successo',
    en: 'Delivery zone created successfully',
  },
  zoneCreateFailed: {
    de: 'Lieferzone konnte nicht angelegt werden',
    it: 'Impossibile creare la zona di consegna',
    en: 'Delivery zone could not be created',
  },
  zoneDeleted: {
    de: 'Lieferzone erfolgreich gelöscht',
    it: 'Zona di consegna eliminato con successo',
    en: 'Delivery zone deleted successfully',
  },
  zoneDeleteFailed: {
    de: 'Lieferzone konnte nicht gelöscht werden',
    it: 'Impossibile eliminare la zona di consegna',
    en: 'Delivery zone could not be deleted',
  },
  zoneContainsUsers: {
    de: 'In der ausgewählten Lieferzone befinden sich Kunden, bitte weisen Sie zuerst den Kunden eine andere Lieferzone zu.',
    it: "Ci sono clienti nella zona di consegna selezionata, assegna prima i clienti a un'altra zona di consegna.",
    en: 'There are clients in the selected delivery zone, please assign the clients to another delivery zone first.',
  },
  sendMail: {
    de: 'E-Mail versenden',
    it: 'Invia E-Mail',
    en: 'Send E-Mail',
  },
  hostMailSubject: {
    de: `Wir sind nun bei ${BAKERY}`,
    it: `Ora siamo a ${BAKERY}`,
    en: `We are now at ${BAKERY}`,
  },
  hostMailText: {
    de:
      `Sehr geehrten Damen und Herren,\nWir sind nun Mitglied bei ${BAKERY}, und auch Sie profitieren davon.\n` +
      `\n` +
      `${BAKERY} ist eine Platform mit der Sie ganz einfach Brote und andere tolle Produkte bestellen können. Auch Ihre Gäste können direkt über die App bei uns bestellen. Die Produkte werden immer noch von uns an Sie verkauft. Sie haben mit ${BAKERY} eine übersichtliche Platform die Ihnen anzeigt, was Ihre Gäste bestellen, und müssen sich nicht mehr darum kümmern.\n` +
      `\n` +
      `Für weitere Details besuchen Sie einfach die Webseite ${URL}. \n` +
      `Sie können sich mit folgenden Benutzerdaten anmelden:\n` +
      `Benutzername: [username]\n` +
      `Passwort: [password]\n` +
      `\n` +
      `Wir empfehlen Ihnen zuerst die Tutorials anzuschauen, klicken Sie dazu einfach auf das eingekreiste Fragezeichen in der Header-Leiste. Diese helfen Ihnen sich in der Seite zurecht zu finden.\n` +
      `\n` +
      `Danke und freundliche Grüße\n` +
      `[bakery]`,
    it:
      `Gentile signore o signora,\nOra siamo membri di ${BAKERY} e anche tu ne trarrai beneficio.\n` +
      `\n` +
      `${BAKERY} è una piattaforma con cui puoi facilmente ordinare pane e altri ottimi prodotti. I tuoi ospiti possono anche ordinare da noi direttamente tramite l'app. I prodotti TI vengono ancora venduti da noi, con ${BAKERY} hai una piattaforma chiara per ciò che ordinano i tuoi ospiti e non devi più preoccupartene.\n` +
      `\n` +
      `Per maggiori dettagli basta visitare il sito ${URL}.\n` +
      `Puoi accedere con i seguenti dati utente:\n` +
      `Nome utente: [username]\n` +
      `Password: [password]\n` +
      `\n` +
      `Ti consigliamo di guardare prima i tutorial, basta fare clic sul punto interrogativo cerchiato nella barra dell'intestazione. Questi ti aiuteranno a orientarti nel sito.\n` +
      `\n` +
      `Grazie e distinti saluti\n` +
      `[bakery]`,
    en:
      `Dear Sir or Madam,\nWe are now a member of ${BAKERY}, and you too will benefit from it.\n` +
      `\n` +
      `${BAKERY} is a platform where you can easily order bread and other great products. Your guests can also order from us directly via the app. The products are still being sold to you by us, with ${BAKERY} you have a clear platform for what your guests order and you no longer have to worry about it.\n` +
      `\n` +
      `For more details just visit the website ${URL}.\n` +
      `You can log in with the following user data:\n` +
      `Username: [username]\n` +
      `Password: [password]\n` +
      `\n` +
      `We recommend that you watch the tutorials first, just click on the circled question mark in the header bar. These will help you to find your way around the site.\n` +
      `\n` +
      `Thanks and best regards\n` +
      `[bakery]`,
  },
  billGuest: {
    de: 'Gäste Abrechnen',
    it: 'Fatturazione ospiti',
    en: 'Billing guests',
  },
  noGuestsForBilling: {
    de: 'Keine Gäste zum Abrechnen gefunden.',
    it: 'nessun ospite trovato per la fatturazione.',
    en: 'No guest found for billing.',
  },
  timePeriod: {
    de: 'Zeitraum',
    it: 'Periodo',
    en: 'Time Period',
  },
  generateBill: {
    de: 'Abrechnung generieren',
    it: 'Crea fattura',
    en: 'Generate Bill',
  },
  generatedSuccessfully: {
    de: 'Erfolgreich generiert',
    it: 'Generato con successo',
    en: 'Successfully generated',
  },
  nothingOrderedInTimeGuest: {
    de: '[guest] hat in diesem Zeitraum nichts bestellt.',
    it: '[guest] non ha ordinato nulla durante questo periodo.',
    en: '[guest] did not order anything during this period.',
  },
  splitDate: {
    de: 'Nach Datum sortieren',
    it: 'Ordinare per data',
    en: 'Split by date',
  },
  sumUp: {
    de: 'Zusammenfassen',
    it: 'Tutto sommato',
    en: 'Sum up',
  },
  orderedAt: {
    de: 'Am [date] bestellt',
    it: 'Ordinato il [date]',
    en: 'Ordered at [date]',
  },
};
const profileKeywords = {
  profile: { de: 'Mein Profil', it: 'Mio profilo', en: 'My Profile' },
  profileEdit: {
    de: 'Sie können hier Ihre Daten jederzeit bearbeiten.',
    it: 'Puoi modificare i tuoi dati qui in qualsiasi momento.',
    en: 'You can edit your data here at any time.',
  },
  editProfile: {
    de: 'Profil bearbeiten',
    it: 'Modifica profilo',
    en: 'Edit my profile',
  },
  hasToCheckOrders: {
    de: 'Bestellungen akzeptieren',
    it: 'Accettare ordini',
    en: 'Accept orders',
  },
  hasToCheckOrdersHelp: {
    de: 'Bestellungen von Ihren Gästen müssen von Ihnen akzeptiert werden bevor sie an die Bäckerei weiter geleitet werden',
    it: 'Gli ordini dei tuoi ospiti devono essere accettati da te prima di essere inoltrati al panificio',
    en: 'Orders from your guests have to be accepted by you before they are forwarded to the bakery',
  },
  couldNotLoadData: {
    de: 'Einige wichtige Daten sind nicht vorhanden, probieren Sie es erneut oder kontaktieren Sie Ihre Bäckerei.',
    it: 'Mancano alcuni dati importanti, riprova o contatta il tuo panificio',
    en: 'Some important data are missing, please try again or contact your bakery',
  },
  tel: {
    de: 'Telefonnummer',
    it: 'Numero di telefono',
    en: 'Phone number',
  },
};
const settingsKeywords = {
  settingsEdit: {
    de: 'Grunddaten bearbeiten und Seiteneinstellungen.',
    it: 'Modifica i dati di base e le impostazioni della pagina.',
    en: 'Edit basic data and page settings.',
  },
  currentPassword: {
    de: 'Aktuelles Passwort',
    it: 'Password attuale',
    en: 'Current password',
  },
  changePassword: {
    de: 'Passwort ändern',
    it: 'Cambia la password',
    en: 'Change password',
  },
  newPassword: {
    de: 'Neues Passwort',
    it: 'Nuova password',
    en: 'New password',
  },
  holidays: {
    de: 'Feiertage',
    it: 'Giorni Festivi',
    en: 'Holidays',
  },
  newHoliday: {
    de: 'Neuer Feiertag',
    it: 'Nuovo giorno Festivo',
    en: 'New Holiday',
  },
  from: {
    de: 'Von',
    it: 'Da',
    en: 'From',
  },
  to: {
    de: 'Bis',
    it: 'Fino a',
    en: 'To',
  },
  holidaysDesc: {
    de: 'Tragen Sie hier die Feiertage ein an denen Sie nicht liefern.',
    it: 'Inserisci i giorni festivi in cui non effettui consegne.',
    en: 'Enter the holidays on which you do not deliver.',
  },
  savePassword: {
    de: 'Neues Passwort speichern',
    it: 'Salva nuova password',
    en: 'Save new password',
  },
  baseData: {
    de: 'Grunddaten',
    it: 'Dati di base',
    en: 'Base settings',
  },
  extraSettings: {
    de: 'Sonstige Einstellungen',
    it: 'Altre impostazioni',
    en: 'Other settings',
  },
  documents: {
    de: 'Dokumente',
    it: 'Documenti',
    en: 'Documents',
  },
  searchOrder: {
    de: 'Nach Bestellung suchen.',
    it: 'Cerca ordine',
    en: 'Search for order',
  },
  ordersFound: {
    de: '[numOrders] Bestellung/en [found]',
    it: '[numOrders] ordini [found]',
    en: '[numOrders] order/s [found]',
  },
  last10: {
    de: 'Zeige letzten 10',
    it: 'Mostra gli ultimi 10',
    en: 'Show Last 10',
  },
  found: {
    de: 'gefunden',
    it: 'trovato',
    en: 'found',
  },
  billSettings: {
    de: 'Rechnungs-Einstellungen',
    it: 'Impostazioni fatturazione',
    en: 'Bill settings',
  }, // (\%d\%m\%y\%Y)*(\w)*(\%d\%m\%y\%Y)*(\%n)(\%d\%m\%y\%Y)*(\w)*(\%d\%m\%y\%Y)*
  billNumberFormat: {
    de: 'Format der Rechnungsnummer',
    it: 'Formato del numero per la fattura',
    en: 'Bill number Format',
  },
  billNumberFormatHelp: {
    de: 'Hier bestimmen Sie das Format der Rechnungsnummer, verwenden Sie %n für die fortlaufende Nummer. Wenn Sie das aktuelle Datum in Ihrer Rechnungsnummer haben möchten können Sie %d für den Tag des Monats, %m für das Monat und %y bzw. %Y für das Jahr verwenden. Bsp: %YR%n = 2022R42 (42 ist die fortlaufende Nummer), %y%m%d_%n = 220101_42 (1.Jan.2022)',
    it: "Qui determini il formato del numero di fattura, usa %n per il numero sequenziale. Se desideri la data corrente nel numero della fattura, puoi utilizzare %d per il giorno del mese, %m per il mese e %y o %Y per l'anno. Esempio: %YR%n = 2022R42 (42 è il numero consecutivo), %y%m%d_%n = 220101_42 (1.gen.2022)",
    en: 'Here you determine the format of the invoice number, use %n for the sequential number. If you want the current date in your invoice number, you can use %d for the day of the month, %m for the month and %y or %Y for the year. Example: %YR%n = 2022R42 (42 is the consecutive number), %y%m%d_%n = 220101_42 (1.Jan.2022)',
  },
  currentBillNumber: {
    de: 'Fortlaufende Nummer Rechnung',
    it: 'Numero consecutivo fattura',
    en: 'Consecutive number invoice',
  },
  currentBillNumberHelp: {
    de: 'Tragen Sie hier die fortlaufende Nummer ein, diese zählt dann bei jeder Rechnung automatisch hoch. Sie können diese auch jederzeit bei der Rechnungserstellung austauschen, sollten Sie Rechnungen außerhalb von Slowvita erstellen.',
    it: 'Inserisci qui il numero progressivo, che aumenterà automaticamente ad ogni fattura. Puoi anche scambiarli in qualsiasi momento durante la creazione di fatture, se crei fatture al di fuori di Slowvita.',
    en: 'Enter the sequential number here, which will then automatically increase with each invoice. You can also change the number at any time when creating invoices, in case you create invoices outside of Slowvita.',
  },
  sendDDMails: {
    de: 'Senden und speichern',
    it: 'Invia e salva',
    en: 'Send and save',
  },
  increaseDDNUmber: {
    de: 'Speichern',
    it: 'Salva',
    en: 'Save',
  },
  deliveryDocketNumberFormat: {
    de: 'Format der Lieferschein-nummer',
    it: 'Formato del numero per il DDT',
    en: 'Delivery docket number Format',
  },
  deliveryDocketNumberFormatHelp: {
    de: 'Das Format ist das selber wie bei der Rechnungsnummer. Bsp: %Y_%m_%d_D_%n = 2022_01_01_D_42 (1. Jan. 2022, Lieferscheinnummer 42)',
    it: 'Il formato è lo stesso del numero di fattura. Esempio: %Y_%m_%d_D_%n = 2022_01_01_D_42 (1 gennaio 2022, documento di trasporto numero 42)',
    en: 'The format is the same as the invoice number. Example: %Y_%m_%d_D_%n = 2022_01_01_D_42 (1 Jan. 2022, delivery docket number 42)',
  },
  currentDeliveryDocketNumber: {
    de: 'Fortlaufende Nummer Lieferschein',
    it: 'Numero consecutivo DDT',
    en: 'Consecutive number delivery docket',
  },
  billNumberPrefix: {
    de: 'Rechnungsnummer Prefix',
    it: 'Numero fattura prefix',
    en: 'Invoice number prefix',
  },
  billNumberPrefixHelp: {
    de: 'Der hier eingegebene text wird automatisch bei Ihrer Rechnungsnummer vorangestellt.',
    it: 'Il testo inserito qui viene automaticamente posizionato davanti al numero della fattura.',
    en: 'The text entered here is automatically placed in front of your invoice number.',
  },
  billNumberSuffix: {
    de: 'Rechnungsnummer Suffix',
    it: 'Numero fattura suffisso',
    en: 'Invoice number suffix',
  },
  billNumberSuffixHelp: {
    de: 'Das Suffix wird automatisch hinter die Rechnungsnummer geschrieben. Z.B.: Prefix: "SV/" Suffix: "A" ergibt die Rechnungsnummer: SV/420A',
    it: 'Il suffisso viene scritto automaticamente dopo il numero di fattura. Es.: Prefisso: "SV/" Suffisso: "A" risulta nel numero della fattura: SV/420A',
    en: 'The suffix is automatically written after the invoice number. E.g.: Prefix: "SV/" Suffix: "A" results in the invoice number: SV/420A',
  },
  pdfLogo: {
    de: 'Logo',
    it: 'Logo',
    en: 'Logo',
  },
  pdfLogoHelp: {
    de: 'Ihr Logo welches aufs PDF Dokument der Rechnung platziert wird.',
    it: 'Il tuo logo che verrà inserito nel documento PDF della fattura.',
    en: 'Your logo which will be placed on the PDF document of the invoice.',
  },
  administration: {
    de: 'Verwaltung',
    it: 'Amministrazione',
    en: 'Administration',
  },
  saveBaseDate: {
    de: 'Grunddaten speichern',
    it: 'Salva dati di base',
    en: 'Save base data',
  },
  holidayDeleted: {
    de: 'Feiertag gelöscht',
    it: 'Giorno festivo eliminato',
    en: 'Holiday deleted',
  },
  holidayDeleteFailed: {
    de: 'Feiertag konnte nicht gelöscht werden',
    it: 'Impossibile eliminare il giorno festivo',
    en: 'Holiday could not be deleted',
  },
  invalidDate: {
    de: 'Ungültiges Datum ausgewählt',
    it: 'Data non valida selezionata',
    en: 'Invalid date selected',
  },
  ignoreDeadline: {
    de: 'Erhöhte Toleranz',
    it: 'Maggiore tolleranza',
    en: 'Increased tolerance',
  },
  ignoreDeadlineHelp: {
    de: 'Kunden können auch nach der Deadline bestellen, Sie werden dabei gewarnt dass die Lieferung jedoch nicht garantiert ist für den darauffolgenden Liefertag.',
    it: 'I clienti possono ordinare anche dopo la scadenza, ma sono avvisati che la consegna non è garantita per il giorno di consegna successivo.',
    en: 'Customers can also order after the deadline, but they are warned that delivery is not guaranteed for the following delivery day.',
  },
  deliverSunday: {
    de: 'Sonntag',
    it: 'Domenica',
    en: 'Sunday',
  },
  deliverSundayHelp: {
    de: 'Liefern Sie auch Sonntags aus?',
    it: 'Consegnate anche la domenica?',
    en: 'Do you also deliver on Sundays?',
  },
  deliverSaturday: {
    de: 'Samstag',
    it: 'Sabato',
    en: 'Saturday',
  },
  deliverSaturdayHelp: {
    de: 'Liefern Sie auch Samstag aus?',
    it: 'Consegnate anche il sabato?',
    en: 'Do you also deliver on Saturdays?',
  },
  takeaway: {
    de: 'Abholung',
    it: 'Raccolta',
    en: 'Take away',
  },
  takeawayHelp: {
    de: 'Können Ihre Kunden auf Abholung bestellen?',
    it: 'È possibile ordinare al ritiro?',
    en: 'Can your customers order on pickup?',
  },
  security: {
    de: 'Sicherheit',
    it: 'Sicurezza',
    en: 'Security',
  },
  createBills: {
    de: 'Rechnungserstellung',
    it: 'Fatturazione',
    en: 'Invoicing',
  },
  createBillsHelp: {
    de: 'Wenn Sie die Rechnungserstellung aktivieren, müssen Sie beim wiegen der Bestellung das Gewicht der entsprechenden Produkte eintragen. Dies kann auch automatisiert werden indem die Waage angeschlossen wird, kontaktieren Sie dafür bitte den Support. Ist die Rechnungsstellung deaktiviert so ist das Eintragen des Gewichts nicht notwendig, jedoch kann SlowVita damit nicht die Rechnungen für Sie erstellen. Achtung! Verändern Sie diese Einstellung nicht im laufenden Betrieb, ansonsten können Probleme entstehen.',
    it: "Se attivi la fatturazione, devi inserire il peso dei prodotti corrispondenti quando pesi l'ordine. Questo può anche essere automatizzato collegando la bilancia, si prega di contattare l'assistenza. Se la fatturazione è disattivata, non è necessario inserire il peso, ma SlowVita non può creare le fatture per te. Attenzione! Non modificare questa impostazione durante il mese, altrimenti potrebbero sorgere problemi.",
    en: 'If you activate invoicing, you must enter the weight of the corresponding products when you weigh the order. This can also be automated by connecting the scale, please contact support. If invoicing is deactivated, it is not necessary to enter the weight, but SlowVita cannot create the invoices for you. Caution! Do not change this setting during operation, otherwise problems can arise.',
  },
};
const bakeryListKeywords = {
  bakelist: { de: 'Backliste', it: 'Listino pani', en: 'Bakery list' },
  bakelistFor: {
    de: 'Backliste für [deadline]',
    it: 'Listino pani per [deadline]',
    en: 'Bakery list for [deadline]',
  },
  generate: {
    de: 'Generieren',
    it: 'Generare',
    en: 'Generate',
  },
  additionalBread: {
    de: 'Zusätzliche Brote',
    it: 'Pane aggiuntive',
    en: 'Additional breads',
  },
  semmel: {
    de: 'Semmel',
    it: 'Panino',
    en: 'Bread roll',
  },
  append: {
    de: 'Hinzufügen',
    it: 'Inserisci',
    en: 'Append',
  },
  total: {
    de: 'Gesamt',
    it: 'Totale',
    en: 'Total',
  },
};
const chatKeywords = {
  Day0: {
    de: 'Heute',
    it: 'Oggi',
    en: 'Today',
  },
  Day1: {
    de: 'Gestern',
    it: 'Ieri',
    en: 'Yesterday',
  },
  Day2: {
    de: 'Vorgestern',
    it: "L'altro ieri",
    en: '2 days ago',
  },
  Day3: {
    de: 'Vor 3 Tage',
    it: '3 giorni fa',
    en: '3 days ago',
  },
  Day4: {
    de: 'Vor 4 Tage',
    it: '4 giorni fa',
    en: '4 days ago',
  },
  Day5: {
    de: 'Vor 5 Tage',
    it: '5 giorni fa',
    en: '5 days ago',
  },
  Day6: {
    de: 'Vor 6 Tage',
    it: '6 giorni fa',
    en: '6 days ago',
  },
  lastWeek: {
    de: 'Letzte woche',
    it: 'prossima settimana',
    en: 'Last week',
  },
  nothingHappenedRecently: {
    de: 'Keine kürzliche Aktivität',
    it: "Non è un'attività recente",
    en: 'Not a recent activity',
  },
  contactSupport: {
    de: 'Support kontaktieren',
    it: 'Contatta il supporto',
    en: 'Contact support',
  },
  reply: {
    de: 'Antworten',
    it: 'Rispondi',
    en: 'Reply',
  },
  me: {
    de: 'Ich',
    it: 'Io',
    en: 'Me',
  },
  fromMe: {
    de: 'Ihnen',
    it: 'te',
    en: 'you',
  },
  title: {
    de: 'Betreff',
    it: 'Soggetto',
    en: 'Subject',
  },
  msg: {
    de: 'Nachricht',
    it: 'Messaggio',
    en: 'Message',
  },
  recipients: {
    de: 'Empfänger',
    it: 'Destinatario',
    en: 'Recipient',
  },
  createMessage: {
    de: 'Neue Nachricht schreiben',
    it: 'Scrivi un nuovo messaggio',
    en: 'Write a new message',
  },
  editMessage: {
    de: 'Nachricht bearbeiten',
    it: 'Modifica messaggio',
    en: 'Edit message',
  },
  replyMessage: {
    de: 'Auf Nachricht antworten',
    it: 'Risponde al messaggio',
    en: 'Reply to message',
  },
  messageDeleteFailed: {
    de: 'Nachricht konnte nicht gelöscht werden',
    it: 'Impossibile eliminare il messagio',
    en: 'Message could not be deleted',
  },
  conversation: { de: 'Konversation', it: 'Conversazione', en: 'Conversation' },
  noConversation: {
    de: 'Noch keine Konversation',
    it: 'Nessun conversazione',
    en: 'No conversations',
  },
  conversationStarted: {
    de: 'Konversation gestartet',
    it: 'Conversazione iniziata',
    en: 'Conversation started',
  },
  conversationStartFailed: {
    de: 'Konversation konnte nicht erstellt werden',
    it: 'Impossibile creare la conversazione',
    en: 'Conversation could not be created',
  },
  replied: {
    de: 'Antwort versendet',
    it: 'Risposta inviata',
    en: 'Reply sent',
  },
  replyFailed: {
    de: 'Antwort konnte nicht versendet werden',
    it: 'Impossibile inviare la risposta',
    en: 'Reply could not be sent',
  },
};
const productKeywords = {
  amount: {
    de: 'Menge',
    it: 'Quantità',
    en: 'Amount',
  },
  prevOrder: {
    de: 'Vorherige Bestellung',
    it: 'Ordine precedente',
    en: 'Previous order',
  },
  nextOrder: {
    de: 'Nächste Bestellung',
    it: 'Prossimo ordine',
    en: 'Next order',
  },
  createProduct: {
    de: 'Produkt erstellen',
    it: 'Crea un Prodotto',
    en: 'Create Product',
  },
  editProduct: {
    de: 'Produkt bearbeiten',
    it: 'Modifica Prodotto',
    en: 'Edit Product',
  },
  deleteProduct: {
    de: 'Produkt löschen',
    it: 'Elimina Prodotto',
    en: 'Delete Product',
  },
  priceList: {
    de: 'Preisliste',
    it: 'Listino prezzi',
    en: 'Price list',
  },
  priceLists: {
    de: 'Preislisten',
    it: 'Listino prezzi',
    en: 'Price lists',
  },
  priceListsHelp: {
    de: 'Weisen Sie den Produkt eine oder mehrere Preislisten zu, STRG gedrückt halten um mehrere auszuwählen. Produkte ohne Preisliste sind nicht sichtbar für Kunden.',
    it: 'Assegna uno o più listini al prodotto, tieni premuto CTRL per selezionarne diversi. I prodotti senza listino non sono visibili ai clienti.',
    en: 'Assign one or more price lists to the product, hold CTRL to select several. Products without a price list are not visible to clients.',
  },
  priceListsHelpClient: {
    de: 'Weisen Sie den Kunde eine oder mehrere Preislisten zu. Der Kunde sieht nur die Produkte welche in einer der ausgewählten Preislisten ist. STRG gedrückt halten um mehrere auszuwählen.',
    it: 'Assegna uno o più listini al cliente. Il cliente vede solo i prodotti che si trovano in uno dei listini selezionati. Tenere premuto CTRL per selezionare più.',
    en: 'Assign one or more price lists to the customer. The customer only sees the products that are in one of the selected price lists. Hold CTRL to select multiple.',
  },
  defaultPriceListHelp: {
    de: 'Standard Preisliste für Neukunden.',
    en: 'Standard price list for new customers.',
    it: 'Listino standard per nuovi clienti.',
  },
  priceListNameHelp: {
    de: 'Name der Preisliste. Nur für Sie sichtbar.',
    en: 'Price list name. Only visible to you.',
    it: 'Nome del listino prezzi. Visibile solo a te.',
  },
  addPriceList: {
    de: 'Preisliste hinzufügen!',
    it: 'Aggiungi listino Prezzi!',
    en: 'Add price list!',
  },
  removePriceList: {
    de: 'Preisliste löschen!',
    it: 'Elimina listino Prezzi!',
    en: 'Delete price list!',
  },
  removeFromPriceList: {
    de: 'Aus [priceList] mit [price] entfernen',
    it: 'Elimina da [priceList] con [price]!',
    en: 'Remove from [priceList] with [price]!',
  },
  addToPriceList: {
    de: 'Zur Preisliste hinzufügen',
    it: 'Aggiungi a listino',
    en: 'Add to price list',
  },
  noPriceListYet: {
    de: 'Noch keine Preisliste erstellt',
    it: 'Nessun listino prezzi ancora creato',
    en: 'No price list created yet',
  },
  productPriceInListHelp: {
    de: 'Preis für die [price_list] Preisliste',
    it: 'Prezzo per il listino [price_list]',
    en: 'Price for [price_list] price list',
  },
  setAsDefault: {
    de: 'Die neue Preisliste wurde als Standard gesetzt.',
    it: 'Il nuovo listino prezzi è stato fissato come standard.',
    en: 'The new price list has been set as default.',
  },
  productDeleteFailed: {
    de: 'Produkt konnte nicht gelöscht werden',
    it: 'Impossibile eliminare il prodotto',
    en: 'Product could not be deleted',
  },
  productName: {
    de: 'Name',
    it: 'Nome',
    en: 'Name',
  },
  productDesc: {
    de: 'Beschreibung',
    it: 'Descrizione',
    en: 'Description',
  },
  productPrice: {
    de: 'Preis',
    it: 'Prezzo',
    en: 'Price',
  },
  productPriceHelp: {
    de: "Der Preis wird berechnet aus 'Grammpreis' x 'Gewicht in Gramm'. Wenn 'Muss gewogen werden' aktiviert ist wird das gewogene Gewicht für die Rechnung verwendet.",
    it: "Il prezzo è calcolato dal 'Prezzo al grammo' x 'peso in grammi'. Se si attiva 'Deve essere pesato', il peso pesato viene utilizzato per la fattura.",
    en: "The price is calculated from the 'Gram price' x 'Weight in grams'. If 'Must be weighed' is activated, the weighed weight is used for the invoice.",
  },
  productWeight: {
    de: 'Gewicht in Gramm',
    it: 'Peso in grammi',
    en: 'Weight in grams',
  },
  productWeightHelp: {
    de: "Geben Sie hier das ungefähre Gewicht in Gramm an sodass ihr Wunschpreis (angezeigter Preis = 'Grammpreis' x 'Gewicht in Gramm') angezeigt wird.",
    it: "Immettere qui il peso approssimativo in grammi in modo che venga visualizzato il prezzo desiderato (prezzo visualizzato = 'prezzo in grammi' x 'peso in grammi').",
    en: "Enter the approximate weight in grams here so that your desired price (displayed price = 'gram price' x 'weight in grams') is displayed.",
  },
  haveToWeight: {
    de: 'Muss gewogen werden',
    it: 'Deve essere pesato',
    en: 'Must be weighed',
  },
  haveToWeightHelp: {
    de: 'Aktivieren wenn das Produkt nicht gewogen wird, also pro Gewicht verkauft wird, bei Stückverkauf deaktivieren.',
    it: 'Si attiva se il prodotto non è pesato, cioè viene venduto a peso, si disattiva se venduto a pezzo.',
    en: 'Activate if the product is not weighed, i.e. is sold per weight, deactivate if sold by piece.',
  },
  productActive: {
    de: 'Aktiv',
    it: 'Attivo',
    en: 'Active',
  },
  productActiveHelp: {
    de: 'Sie können ein Produkt ständig aktivieren oder deaktivieren, z.B. wenn mal nicht verfügbar.',
    it: 'Puoi sempre attivare o disattivare un prodotto, ad esempio quando non è disponibile.',
    en: 'You can always activate or deactivate a product, e.g. when it is not available.',
  },
  productVat: {
    de: 'Steuersatz',
    it: 'Aliquota fiscale',
    en: 'Tax rate',
  },
  productVatHelp: {
    de: 'Achtung, auf die Rechnung wird dieser Steuersatz angegeben.',
    it: 'Attenzione, questa aliquota fiscale è indicata in fattura.',
    en: 'Attention, this tax rate is indicated on the invoice.',
  },
  productThumb: {
    de: 'Bild',
    it: 'Immagine',
    en: 'Picture',
  },
  productThumbHelp: {
    de: 'Wählen Sie ein schönes Vorschau Bild für Ihr Produkt, am besten ein freigestelltes.',
    it: 'Scegli una bella immagine di anteprima per il tuo prodotto, preferibilmente isolata.',
    en: 'Choose a nice preview image for your product, preferably an isolated one.',
  },
  displayPrice: {
    de: 'Angezeigter Preis',
    it: 'Prezzo visualizzato',
    en: 'Display Price',
  },
  displayPriceHelp: {
    de: 'Hier können Sie den Preis eintragen welcher Ihren Gästen angezeigt wird, bevorzugt der Preis den Sie Ihren Gästen für dieses Produkt verrechnen.',
    it: 'Qui puoi inserire il prezzo che viene mostrato ai tuoi ospiti, preferibilmente il prezzo che addebiti ai tuoi ospiti per questo prodotto.',
    en: 'Here you can enter the price that is displayed to your guests, preferably the price that you charge your guests for this product.',
  },
  productBaseProduct: {
    de: 'Basisprodukt',
    it: 'Prodotto base',
    en: 'Base Product',
  },
  productBaseProductHelp: {
    de: 'Wählen Sie hier ein Basisprodukt der Bäckerei welches Ihren Gästen angezeigt wird.',
    it: 'Seleziona un prodotto base della pasticceria che i tuoi ospiti vedranno.',
    en: 'Select a basic product from the bakery which your guests will see.',
  },
  selectImage: {
    de: 'Bitte wähle ein Bild aus',
    it: "Seleziona un'immagine",
    en: 'Please select an image',
  },
  importProducts: {
    de: 'Produkte importieren',
    it: 'Importa prodotti',
    en: 'Import products',
  },
  createNewProduct: {
    de: 'Erstelle ein neues Produkt',
    it: 'Crea un nuovo prodotto',
    en: 'Create a new Product',
  },
  noProductsYet: {
    de: 'Noch keine Produkte vorhanden.',
    it: 'Nessun prodotto ancora.',
    en: 'No products yet.',
  },
  createFirstProduct: {
    de: 'Legen Sie Ihr erstes Produkt an.',
    it: 'Crea il tuo primo prodotto.',
    en: 'Create your first product.',
  },
  newProduct: {
    de: 'Neues Produkt',
    it: 'Nuovo prodotto',
    en: 'New Product',
  },
  importCsv: {
    de: 'Produkt Import via CSV',
    it: 'Importazione del prodotto tramite CSV',
    en: 'Product import via CSV',
  },
  noValidZip: {
    de: 'Keine gültige ZIP-Datei!',
    it: 'Non è un file ZIP valido!',
    en: 'Not a valid ZIP file!',
  },
  importHelp: {
    de: "Sie können Produkte aus Easyback importieren. Benützen Sie für den export unser Tool. Sie können das Tool hier kostenlos herunterladen. Einfach entpacken und die 'export.bat' ausführen. Sollte eine Sicherheitswarnung von Windows erscheinen, einfach auf trotzdem ausführen klicken. Eine 'exported.csv' sollte generiert werden.",
    it: 'Puoi importare prodotti da Easyback. Usa il nostro strumento per l\'esportazione. Puoi scaricare lo strumento qui gratuitamente. Basta decomprimere ed eseguire "export.bat". Se viene visualizzato un avviso di sicurezza di Windows, fai semplicemente clic su Esegui comunque. Dovrebbe essere generato un file "exported.csv".',
    en: "You can import products from Easyback. Use our tool for export. You can download the tool here for free. Simply unzip and run the 'export.bat'. If a Windows security warning appears, simply click on Run anyway. An 'exported.csv' should be generated.",
  },
  exporttool: {
    de: 'Export-Tool herunterladen',
    it: 'Scarica lo strumento di esportazione',
    en: 'Download the export tool',
  },
  dragDrop: {
    de: "Ziehen Sie die 'exported.csv' hier herein oder",
    it: 'Trascina il file "exported.csv" qui o',
    en: "Drag & drop the 'exported.csv' in here or",
  },
  chooseFile: {
    de: 'wähle eine Datei aus',
    it: 'scegli un file',
    en: 'choose a file',
  },
  verifyImports: {
    de: 'Überprüfe die Daten',
    it: 'Controlla le date',
    en: 'Verify imports',
  },
  import: {
    de: 'Importieren',
    it: 'Importare',
    en: 'Import',
  },
  productPriceWeight: {
    de: 'Preis | Gewicht',
    it: 'Prezzo | Peso',
    en: 'Price | Weight',
  },
  productUpdates: {
    de: 'Folgende Produkte haben übereinstimmungen',
    it: 'I seguenti prodotti hanno corrispondenze',
    en: 'The following products have matches',
  },
  noProductUpdates: {
    de: 'Keine Übereinstimmungen mit bestehenden Produkten gefunden.',
    it: 'Nessuna corrispondenza trovata con prodotti esistenti.',
    en: 'No matches found with existing products.',
  },
  newProductsImport: {
    de: 'Neue Produkte importieren',
    it: 'Importa nuovi prodotti',
    en: 'Import new products',
  },
  noNewProducts: {
    de: 'Kein neuer Datensatz',
    it: 'Nessun nuovo record',
    en: 'No new record',
  },
  selectPlist: {
    de: 'Wähle eine Preisliste aus!',
    it: 'Scegli un listino prezzi!',
    en: 'Choose a price list!',
  },
  unitPrice: {
    de: 'Stückpreis',
    it: 'Prezzo al pezzo',
    en: 'Unit price',
  },
  gramPrice: {
    de: 'Grammpreis',
    it: 'Prezzo al grammo',
    en: 'Gram price',
  },
  kiloPrice: {
    de: 'Kilopreis',
    it: 'Prezzo al kilo',
    en: 'Kilo price',
  },
  setToGrampriceProduct: {
    de: 'Muss gewogen werden',
    it: 'Deve essere pesato',
    en: 'Must be weighed',
  },
  setToUnitProduct: {
    de: 'Brauch nicht gewogen werden',
    it: "Non c'è bisogno di essere pesati",
    en: 'Do not need to be weighed',
  },
  productDifference: {
    de: 'Unterschiede',
    it: 'Differenze',
    en: 'Differences',
  },
  noDifferences: {
    de: 'Keine Unterschiede',
    it: 'Senza distinzione',
    en: 'No differences',
  },
  showNoDiffs: {
    de: 'Zeige Produkte ohne Unterschiede',
    it: 'Mostra i prodotti senza differenze',
    en: 'Show products without differences',
  },
  hideNoDiffs: {
    de: 'Verstecke Produkte ohne Unterschiede',
    it: 'Nascondi i prodotti senza distinzione',
    en: 'Hide products without differences',
  },
  productCreated: {
    de: 'Produkt erfolgreich erstellt',
    it: 'Prodotto creato con successo',
    en: 'Product created successfully',
  },
  productAdopted: {
    de: 'Produkt erfolgreich übernommen',
    it: 'Prodotto adottato con successo',
    en: 'Product adopted successfully',
  },
  productSaveFailed: {
    de: 'Produkt konnte nicht erstellt werden',
    it: 'Impossibile creare il prodotto',
    en: 'Product could not be created',
  },
  productAlreadyTaken: {
    de: 'Sie haben dieses Produkt bereits übernommen',
    it: 'Hai già adottato questo prodotto',
    en: 'You have already adopted this product',
  },
  ownProduct: {
    de: 'Eigenprodukt',
    it: 'Prodotto fatto in casa',
    en: 'Self made Product',
  },
  displayOrder: {
    de: 'Anzeigereihenfolge',
    it: 'Ordine di visualizzazione',
    en: 'Display order',
  },
  productActions: {
    de: 'Aktionen',
    it: 'Azioni',
    en: 'Actions',
  },
  resetProductOrder: {
    de: 'Anzeigereihenfolge zurücksetzen',
    it: 'Ripristina ordine di visualizzazione',
    en: 'Reset display order',
  },
  overtakeEvery: {
    de: 'Alle Produkte übernehmen',
    it: 'Adotta tutti i prodotti',
    en: 'Adopt all products',
  },
  noDisplayPrice: {
    de: 'Preis von Bäckerei verwenden?',
    it: 'Usare prezzo del panificio?',
    en: 'Use price of Bakery?',
  },
};
const legalKeywords = {
  extra: {
    de: 'Extra',
    it: 'Varie',
    en: 'Extra',
  },
  impressum: {
    de: 'Impressum',
    it: 'Impronta',
    en: 'Imprint',
  },
  agb: {
    de: 'Allgemeine Geschäftsbedingungen',
    it: 'Termini di servizio',
    en: 'Terms of Service',
  },
  privacyPolicy: {
    de: 'Datenschutz-Bestimmungen',
    it: 'Privacy Policy',
    en: 'Privacy Policy',
  },
  sitemap: {
    de: 'Sitemap',
    it: 'Sitemap',
    en: 'Sitemap',
  },
  companyName: {
    de: BAKERY,
    it: BAKERY,
    en: BAKERY,
  },
  companySlogan: SLOGAN,
  responsibles: {
    de: 'Betreiber',
    it: 'Responsabile',
    en: 'Responsible',
  },
  responsiblesTitle: {
    de: 'Betreiber und Verantwortlich für die Inhalt im Sinne von § 18 ABS. 2 MSTV',
    it: 'Responsabile ai sensi di § 18 ABS. 2 MSTV per questo sito',
    en: 'Operator and responsible for the content within the meaning of § 18 ABS. 2 MSTV',
  },
  realizedBy: {
    de: 'Realisierung',
    it: 'Realizzazione',
    en: 'Realization',
  },
  realizedByTitle: {
    de: 'Software realisiert von',
    it: 'Software realizzato da',
    en: 'Software realized by',
  },
  lawLine: {
    de: 'Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Alle verwendeten Abbildungen sind urheberrechtlich geschützt.',
    it: "Avviso di responsabilità: Nonostante l'attento controllo dei contenuti non ci assumiamo responsabilità per i contenuti di link esterni. Per i contenuti di siti esterni sono responsabili esclusivamente i loro operatori. Tutte le immagini usate sono protette da copyright.",
    en: 'Liability notice: Despite careful control of the content, we assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content. All images used are protected by copyright.',
  },
  responsiblesName: {
    de: 'Kivi G.M.B.H.',
    it: 'Kivi S.R.L.',
    en: 'Kivi Ltd.',
  },
  companyAddress1: {
    de: 'Schlachthofstraße 69',
    it: 'Via Macellaio 69',
    en: 'Via Macellaio 69',
  },
  companyAddress2: {
    de: '39100 Bozen, BZ, IT',
    it: '39100 Bolzano, BZ, IT',
    en: '39100 Bolzano, BZ, IT',
  },
  companyTel: {
    de: '+39 666 420 6969',
    it: '+39 666 420 6969',
    en: '+39 666 420 6969',
  },
  companyTelClean: {
    de: '+396664206969',
    it: '+396664206969',
    en: '+396664206969',
  },
  companyMail: {
    de: 'info@kivi.bz.it',
    it: 'info@kivi.bz.it',
    en: 'info@kivi.bz.it',
  },
  companyPIva: {
    de: '03080800216',
    it: '03080800216',
    en: '03080800216',
  },
  mainDeveloper: {
    de: 'CEO & Founder, Entwickler',
    it: 'CEO & Founder, Sviluppatore',
    en: 'CEO & Founder, Developer',
  },
  ivanAddress1: {
    de: 'Lingweg 29/A',
    it: 'Via Ling 29/A',
    en: 'Via Ling 29/A',
  },
  ivanAddress2: {
    de: '39019 Tirol, BZ, IT',
    it: '39019 Tirolo, BZ, IT',
    en: '39019 Tirolo, BZ, IT',
  },
  ivanTel: {
    de: '+39 340 915 3895',
    it: '+39 340 915 3895',
    en: '+39 340 915 3895',
  },
  ivanTelClean: {
    de: '+393409153895',
    it: '+393409153895',
    en: '+393409153895',
  },
  ivanMail: {
    de: 'ivan@waldboth.com',
    it: 'ivan@waldboth.com',
    en: 'ivan@waldboth.com',
  },
  ivanVat: {
    de: '03080800216',
    it: '03080800216',
    en: '03080800216',
  },
  secondDeveloper: {
    de: 'CEO & Founder',
    it: 'CEO & Founder',
    en: 'CEO & Founder',
  },
  kalleAddress1: {
    de: 'Guln 14',
    it: 'Gola 14',
    en: 'Gola 14',
  },
  kalleAddress2: {
    de: '39040 Feldthurns, BZ, IT',
    it: '39040 Velturno, BZ, IT',
    en: '39040 Velturno, BZ, IT',
  },
  kalleTel: {
    de: '+39 340 956 0534',
    it: '+39 340 956 0534',
    en: '+39 340 956 0534',
  },
  kalleTelClean: {
    de: '+393409560534',
    it: '+393409560534',
    en: '+393409560534',
  },
  kalleMail: {
    de: 'alexander.klement@gmail.com',
    it: 'alexander.klement@gmail.com',
    en: 'alexander.klement@gmail.com',
  },
  kalleVat: {
    de: '03080790219',
    it: '03080790219',
    en: '03080790219',
  },
  toc: {
    de: 'Inhaltsverzeichnis',
    it: 'Sommario',
    en: 'Table of contents',
  },
  additionalInfo: {
    de: 'Zusatzinformationen',
    it: 'Ulteriori informazioni',
    en: 'Further information',
  },
  about: {
    de: 'Über uns',
    it: 'Su di noi',
    en: 'About us',
  },
  privacyTitle1: {
    de: `Datenschutzerklärung für ${BAKERY}`,
    it: `Informativa sulla privacy per ${BAKERY}`,
    en: `Privacy Policy for ${BAKERY}`,
  },
  privacyTitle2: {
    de: 'Zustimmung',
    it: 'Consenso',
    en: 'Consent',
  },
  privacyTitle3: {
    de: 'Informationen, die wir sammeln',
    it: 'Informazioni che raccogliamo',
    en: 'Information we collect',
  },
  privacyTitle4: {
    de: 'Wie wir Ihre Daten verwenden',
    it: 'Come utilizziamo le tue informazioni',
    en: 'How we use your information',
  },
  privacyTitle5: {
    de: 'Protokolldateien',
    it: 'Log files',
    en: 'Log Files',
  },
  privacyTitle6: {
    de: 'Cookies und Web Beacons',
    it: 'Cookie e web beacon',
    en: 'Cookies and Web Beacons',
  },
  privacyTitle7: {
    de: 'Datenschutzrichtlinien für Werbepartner',
    it: 'Norme sulla privacy dei partner pubblicitari',
    en: 'Advertising Partners Privacy Policies',
  },
  privacyTitle8: {
    de: 'Datenschutzrichtlinien für Drittanbietern',
    it: 'Politiche sulla privacy di terze parti',
    en: 'Third Party Privacy Policies',
  },
  privacyTitle9: {
    de: 'GDPR-Datenschutzrechte (Verkaufen Sie meine persönlichen Daten nicht)',
    it: 'Diritti sulla privacy GDPR (non vendere le mie informazioni personali)',
    en: 'GDPR Privacy Rights (Do Not Sell My Personal Information)',
  },
  privacyTitle10: {
    de: 'Kontaktaufnahme',
    it: 'Contatto',
    en: 'Contact',
  },

  privacyText1: {
    de:
      `Bei ${BAKERY}, das über ${URL} zugänglich ist, ist die Privatsphäre unserer Besucher eine unserer Hauptprioritäten. Dieses Datenschutzdokument enthält Arten von Informationen, die von ${BAKERY} gesammelt und aufgezeichnet werden, und wie wir sie verwenden.\n` +
      `\n` +
      `Wenn Sie weitere Fragen haben oder weitere Informationen zu unserer Datenschutzrichtlinie benötigen, zögern Sie nicht, uns zu kontaktieren.\n` +
      `\n` +
      `Diese Datenschutzrichtlinie gilt nur für unsere Online-Aktivitäten und gilt für Besucher unserer Website oder App, in Bezug auf die Informationen, die sie mit ${BAKERY} geteilt und haben. Diese Richtlinie gilt nicht für Informationen, die offline oder über andere Kanäle als diese Website gesammelt werden.`,
    it:
      `In ${BAKERY}, accessibile da ${URL}, una delle nostre principali priorità è la privacy dei nostri visitatori. Questo documento sulla Privacy Policy contiene i tipi di informazioni che vengono raccolte e registrate da ${BAKERY} e come le usiamo.\n` +
      `\n` +
      `Se hai ulteriori domande o desideri maggiori informazioni sulla nostra Informativa sulla privacy, non esitare a contattarci.\n` +
      `\n` +
      `La presente Informativa sulla privacy si applica solo alle nostre attività online ed è valida per i visitatori del nostro sito Web o la app per quanto riguarda le informazioni che hanno condiviso e / o raccolto in ${BAKERY}. Questa politica non è applicabile a nessuna informazione raccolta offline o tramite canali diversi da questo sito web.`,
    en:
      `At ${BAKERY}, accessible from ${URL}, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by ${BAKERY} and how we use it.\n` +
      `\n` +
      `If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.\n` +
      `\n` +
      `This Privacy Policy applies only to our online activities and is valid for visitors to our website or our app, with regards to the information that they shared and/or collect in ${BAKERY}. This policy is not applicable to any information collected offline or via channels other than this website.`,
  },
  privacyText2: {
    de: 'Durch die Nutzung unserer Website oder unserer App stimmen Sie hiermit unserer Datenschutzrichtlinie zu und stimmen deren Bedingungen zu.',
    it: 'Utilizzando il nostro sito Web o la nostra app, acconsenti alla nostra Informativa sulla privacy e accetti i suoi termini.',
    en: 'By using our website or our app, you hereby consent to our Privacy Policy and agree to its terms.',
  },
  privacyText21: {
    de: 'Durch die Nutzung unserer Website oder unserer App stimmen Sie hiermit unserer Datenschutzrichtlinie zu und stimmen deren Bedingungen zu.',
    it: 'Utilizzando il nostro sito Web o la nostra app, acconsenti alla nostra Informativa sulla privacy e accetti i suoi termini.',
    en: 'By using our website or our app, you hereby consent to our Privacy Policy and agree to its terms.',
  },
  privacyText3: {
    de:
      'Die von Ihnen angeforderten persönlichen Daten und die Gründe, warum Sie zur Angabe aufgefordert werden, werden Ihnen an dem Punkt klargestellt, an dem wir Sie auffordern, Ihre persönlichen Daten anzugeben.\n' +
      '\n' +
      'Wenn Sie uns direkt kontaktieren, erhalten wir möglicherweise zusätzliche Informationen über Sie, wie z. B. Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefonnummer, den Inhalt der Nachricht und / oder Anhänge, die Sie uns senden, sowie weitere Informationen, die Sie möglicherweise angeben.\n' +
      '\n' +
      'Wenn Sie sich für ein Konto registrieren, werden Sie möglicherweise nach Ihren Kontaktinformationen gefragt, einschließlich Namen, Firmenname, Adresse, E-Mail-Adresse und Telefonnummer.',
    it:
      'Le informazioni personali che ti viene chiesto di fornire e i motivi per cui ti viene chiesto di fornirle, ti saranno chiarite nel momento in cui ti chiediamo di fornire le tue informazioni personali.\n' +
      '\n' +
      'Se ci contatti direttamente, potremmo ricevere ulteriori informazioni su di te come il tuo nome, indirizzo e-mail, numero di telefono, il contenuto del messaggio e / o gli allegati che potresti inviarci e qualsiasi altra informazione che potresti scegliere di fornire.\n' +
      '\n' +
      "Quando ti registri per un account, potremmo chiedere le tue informazioni di contatto, inclusi elementi come nome, nome dell'azienda, indirizzo, indirizzo e-mail e numero di telefono.",
    en:
      'The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.\n' +
      '\n' +
      'If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.\n' +
      '\n' +
      'When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.',
  },
  privacyText4: {
    de: 'Wir verwenden die von uns gesammelten Informationen auf verschiedene Weise, unter anderem um:',
    it: 'Utilizziamo le informazioni che raccogliamo in vari modi, incluso per:',
    en: 'We use the information we collect in various ways, including to:',
  },
  privacyText4L1: {
    de: 'Bereitstellung, Betrieb und Pflege unserer Website, Verbesserung, Personalisierung und Erweiterung unserer Website',
    it: 'Fornire, gestire e mantenere il nostro sito Web, migliorare, personalizzare ed espandere il nostro sito Web',
    en: 'Provide, operate, and maintain our website, Improve, personalize, and expand our website',
  },
  privacyText4L2: {
    de: 'Zu verstehen und zu analysieren, wie Sie unsere Website nutzen',
    it: 'Comprendi e analizza come utilizzi il nostro sito web',
    en: 'Understand and analyze how you use our website',
  },
  privacyText4L3: {
    de: 'Neue Produkte, Dienstleistungen, Features und Funktionen zu entwickeln',
    it: 'Sviluppa nuovi prodotti, servizi, caratteristiche e funzionalità',
    en: 'Develop new products, services, features, and functionality',
  },
  privacyText4L4: {
    de: 'Mit Ihnen zu Kommunizieren, direkt oder über einen unserer Partner, auch für den Kundenservice, um Sie mit Updates und anderen Informationen in Bezug auf die Website sowie für Marketing- und Werbezwecke zu versorgen',
    it: 'Comunicare con te, direttamente o tramite uno dei nostri partner, anche per il servizio clienti, per fornirti aggiornamenti e altre informazioni relative al sito web e per scopi di marketing e promozionali',
    en: 'Communicate with you, either directly or through one of our partners, including for client service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes',
  },
  privacyText4L5: {
    de: 'Ihnen E-Mails zu senden',
    it: 'Inviarti e-mail',
    en: 'Send you emails',
  },
  privacyText4L6: {
    de: 'Betrug finden und verhindern',
    it: 'Trova e previeni le frodi',
    en: 'Find and prevent fraud',
  },
  privacyText4L7: {
    de: 'Rechnungen und/oder Lieferscheine zu erstellen',
    it: 'Crea fatture e / o moduli di consegna',
    en: 'Create Invoices and/or delivery dockets',
  },
  privacyText5: {
    de: `${BAKERY} folgt einem Standardverfahren zur Verwendung von Protokolldateien. Diese Dateien protokollieren Besucher, wenn sie Websites besuchen. Alle Hosting-Unternehmen tun dies und sind Teil der Analyse der Hosting-Services. Zu den von Protokolldateien gesammelten Informationen gehören IP-Adressen (Internet Protocol), Browsertyp, Internet Service Provider (ISP), Datums- und Zeitstempel, Verweis- / Verlassungsseiten und möglicherweise die Anzahl der Klicks. Diese sind nicht mit Informationen verknüpft, die persönlich identifizierbar sind. Der Zweck der Informationen besteht darin, Trends zu analysieren, die Website zu verwalten, die Bewegung der Benutzer auf der Website zu verfolgen und demografische Informationen zu sammeln.`,
    it: `${BAKERY} segue una procedura standard di utilizzo dei file di registro. Questi file registrano i visitatori quando visitano i siti web. Tutte le società di hosting fanno questo e una parte dell'analisi dei servizi di hosting. Le informazioni raccolte dai file di registro includono gli indirizzi del protocollo Internet (IP), il tipo di browser, il provider di servizi Internet (ISP), la data e l'ora, le pagine di riferimento / uscita ed eventualmente il numero di clic. Questi non sono collegati ad alcuna informazione che sia personalmente identificabile. Lo scopo delle informazioni è analizzare le tendenze, amministrare il sito, tracciare il movimento degli utenti sul sito web e raccogliere informazioni demografiche.`,
    en: `${BAKERY} follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.`,
  },
  privacyText6: {
    de:
      `Wie jede andere Website verwendet ${BAKERY} "Cookies". Diese Cookies werden verwendet, um Informationen zu speichern, einschließlich der Präferenzen der Besucher und der Seiten auf der Website, auf die der Besucher zugegriffen oder die er besucht hat. Die Informationen werden verwendet, um die Benutzererfahrung zu optimieren, indem der Inhalt unserer Webseite basierend auf dem Browsertyp der Besucher und / oder anderen Informationen angepasst wird.\n` +
      `\n` +
      `Weitere allgemeine Informationen zu Cookies finden Sie unter "Was sind Cookies?" Von Cookie Consent.`,
    it:
      `Come qualsiasi altro sito Web, ${BAKERY} utilizza i "cookie". Questi cookie vengono utilizzati per memorizzare informazioni comprese le preferenze dei visitatori e le pagine del sito Web a cui il visitatore ha avuto accesso o ha visitato. Le informazioni vengono utilizzate per ottimizzare l'esperienza degli utenti personalizzando il contenuto della nostra pagina web in base al tipo di browser dei visitatori e / o ad altre informazioni.\n` +
      `\n` +
      `Per informazioni più generali sui cookie, leggi "Cosa sono i cookie" nella sezione Consenso dei cookie.`,
    en:
      `Like any other website, ${BAKERY} uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.\n` +
      `\n` +
      `For more general information on cookies, please read "What Are Cookies" from Cookie Consent.`,
  },
  privacyText7: {
    de:
      `In dieser Liste finden Sie die Datenschutzbestimmungen für jeden Werbepartner von ${BAKERY}.\n` +
      `\n` +
      `Werbeanbieter oder Werbenetzwerk von Drittanbietern verwenden Technologien wie Cookies, JavaScript oder Web Beacons, die in ihren jeweiligen Anzeigen verwendet werden, und Links, die auf ${BAKERY} erscheinen und direkt an den Browser der Benutzer gesendet werden. In diesem Fall erhalten sie automatisch Ihre IP-Adresse. Diese Technologien werden verwendet, um die Effektivität ihrer Werbekampagnen zu messen und / oder um den Werbeinhalt zu personalisieren, den Sie auf den von Ihnen besuchten Websites sehen.\n` +
      `\n` +
      `Beachten Sie, dass ${BAKERY} keinen Zugriff auf oder keine Kontrolle über diese Cookies hat, die von Werbekunden Dritter verwendet werden.`,
    it: `${
      `Puoi consultare questo elenco per trovare l'informativa sulla privacy per ciascuno dei partner pubblicitari di ${BAKERY}.\n` +
      `\n` +
      `Gli ad server o le reti pubblicitarie di terze parti utilizzano tecnologie come cookie, JavaScript o Web beacon che vengono utilizzati nelle rispettive pubblicità e nei collegamenti visualizzati su ${BAKERY}, che vengono inviati direttamente al browser degli utenti. Ricevono automaticamente il tuo indirizzo IP quando ciò si verifica. Queste tecnologie vengono utilizzate per misurare l'efficacia delle loro campagne pubblicitarie e / o per personalizzare il contenuto pubblicitario che vedi sui siti web che visiti.\n` +
      `\n` +
      `Tieni presente che `
    }${BAKERY} non ha accesso o controllo su questi cookie utilizzati da inserzionisti di terze parti.`,
    en:
      `You may consult this list to find the Privacy Policy for each of the advertising partners of ${BAKERY}.\n` +
      `\n` +
      `Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on ${BAKERY}, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.\n` +
      `\n` +
      `Note that ${BAKERY} has no access to or control over these cookies that are used by third-party advertisers.`,
  },
  privacyText8: {
    de:
      `Die Datenschutzrichtlinie von ${BAKERY} gilt nicht für andere Werbetreibende oder Websites. Wir empfehlen Ihnen daher, die jeweiligen Datenschutzrichtlinien dieser Werbeanbieter von Drittanbietern zu konsultieren, um detailliertere Informationen zu erhalten. Es kann ihre Praktiken und Anweisungen zum Deaktivieren bestimmter Optionen enthalten.\n` +
      `\n` +
      `Sie können Cookies über Ihre individuellen Browser Optionen deaktivieren. Ausführlichere Informationen zur Cookie-Verwaltung mit bestimmten Webbrowsern finden Sie auf den jeweiligen Websites der Browser.`,
    it:
      `L'Informativa sulla privacy di ${BAKERY} non si applica ad altri inserzionisti o siti web. Pertanto, ti consigliamo di consultare le rispettive informative sulla privacy di questi ad server di terze parti per informazioni più dettagliate. Può includere le loro pratiche e istruzioni su come rinunciare a determinate opzioni.\n` +
      `\n` +
      `Puoi scegliere di disabilitare i cookie tramite le singole opzioni del browser. Per conoscere informazioni più dettagliate sulla gestione dei cookie con specifici browser web, è possibile trovarle sui rispettivi siti web dei browser.`,
    en:
      `${BAKERY}'s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.\n` +
      `\n` +
      `You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.`,
  },
  privacyText9: {
    de:
      'Im Rahmen der GDPR haben Verbraucher unter anderem das Recht:\n' +
      'Nach aufforderung die personenbezogenen Daten eines Verbrauchers, die Kategorien und spezifischen personenbezogenen Daten offenzulegen, welche Unternehmen über Verbraucher erfasst hat.\n' +
      'Nach aufforderung alle persönlichen Daten über den Verbraucher zu löschen, die ein Unternehmen gesammelt hat.\n' +
      'Die personenbezogenen Daten eines Verbrauchers nicht zu verkaufen.\n' +
      'Wenn Sie eine Anfrage stellen, haben wir einen Monat Zeit, um Ihnen zu antworten. Wenn Sie eines dieser Rechte ausüben möchten, kontaktieren Sie uns bitte.',
    it:
      'Ai sensi del GDPR, tra gli altri diritti, i consumatori della California hanno il diritto di:\n' +
      "Richiedere a un'azienda che raccoglie i dati personali di un consumatore di rivelare le categorie e le parti specifiche di dati personali che un'azienda ha raccolto sui consumatori.\n" +
      "Richiedere a un'azienda di eliminare tutti i dati personali sul consumatore raccolti da un'azienda.\n" +
      "Richiedi che un'azienda che vende i dati personali di un consumatore, non venda i dati personali del consumatore.\n" +
      'Se fai una richiesta, abbiamo un mese per risponderti. Se desideri esercitare uno di questi diritti, ti preghiamo di contattarci.',
    en:
      'Under the GDPR, among other rights, consumers have the right to:\n' +
      "Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.\n" +
      'Request that a business delete any personal data about the consumer that a business has collected.\n' +
      "Request that a business that sells a consumer's personal data, not sell the consumer's personal data.\n" +
      'If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.',
  },

  privacyText10: {
    de:
      `${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Lingweg 29/A,\n` +
      `IT-39019 Dorf Tirol,\n` +
      `E-Mail ivan@waldboth.com,\n` +
      `Registernummer 2020/26644/800,\n` +
      `Registergericht Bozen zustande`,
    it:
      `${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Via Ling 29/A,\n` +
      `IT-39019 Tirolo,\n` +
      `E-Mail ivan@waldboth.com,\n` +
      `Numero di registro 2020/26644/800,\n` +
      `Registro tribunale di Bolzano`,
    en:
      `${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Lingweg 29 / A,\n` +
      `D-39019 Dorf Tirol,\n` +
      `E-Mail ivan@waldboth.com,\n` +
      `Register number 2020/26644/800,\n` +
      `Register court Bozen`,
  },
  tosTitle1: {
    de: 'Geltung gegenüber Unternehmern und Begriffsdefinitionen',
    it: 'Validità nei confronti degli imprenditori e definizione dei termini',
    en: 'Applicability to entrepreneurs and definitions of terms',
  },
  tosTitle2: {
    de: 'Zustandekommen eines Vertrages, Speicherung des Vertragstextes',
    it: 'Conclusione di un contratto, archiviazione del testo del contratto',
    en: 'Conclusion of a contract, storage of the contract text',
  },
  tosTitle3: {
    de: 'Im Falle des Vertragsschlusses kommt der Vertrag mit',
    it: 'Nel caso della conclusione del contratto, il contratto viene con',
    en: 'If the contract is concluded, the contract comes with',
  },
  tosTitle4: {
    de: 'Vertragsschluss',
    it: 'Conclusione del contratto',
    en: 'Contract conclusion',
  },
  tosTitle5: {
    de: 'Preise, Versandkosten, Zahlung, Fälligkeit',
    it: 'Prezzi, spese di spedizione, pagamento, data di scadenza',
    en: 'Prices, shipping costs, payment, due date',
  },
  tosTitle6: {
    de: 'Dienstleistungsbeschreibung',
    it: 'Descrizione del servizio',
    en: 'Service description',
  },
  tosTitle8: {
    de: 'Widerrufsrecht des Kunden als Verbraucher',
    it: 'Diritto di recesso del cliente in quanto consumatore',
    en: 'Right of withdrawal of the client as a consumer',
  },
  tosTitle81: {
    de: 'Widerrufsrecht für Verbraucher',
    it: 'Diritto di recesso',
    en: 'Right of Withdrawal',
  },
  tosTitle82: {
    de: 'Widerrufsbelehrung',
    it: 'Diritto di recesso',
    en: 'Cancellation policy',
  },
  tosTitle83: {
    de: 'Widerrufsfolgen',
    it: 'Conseguenze della cancellazione',
    en: 'Consequences of cancellation',
  },
  tosTitle84: {
    de: 'Widerrufsformular',
    it: 'Modulo di recesso',
    en: 'Withdrawal form',
  },
  tosText1: {
    de: 'Die nachfolgenden Allgemeinen Geschäftsbedingungen gelten zwischen uns und einem Verbraucher in ihrer zum Zeitpunkt der Registrierung gültigen Fassung.\n Verbraucher ist jede Organisation oder natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.',
    it: "I seguenti termini e condizioni generali si applicano tra noi e un consumatore nella versione valida al momento della registrazione.\n Un consumatore è un'organizzazione o una persona fisica che conclude una transazione legale per scopi che non sono prevalentemente né commerciali né per la loro attività professionale indipendente.",
    en: 'The following general terms and conditions apply between us and a consumer in the version valid at the time of registration.\n A consumer is any organization or natural person that concludes a legal transaction for purposes that are predominantly neither commercial nor their independent professional activity.',
  },
  tosText2: {
    de: `Die folgenden Regelungen über den Vertragsabschluss gelten für die nutzung unserer Dienstleistung ${URL} sowie der entsprechenden APP ${BAKERY}.`,
    it: `Le seguenti disposizioni sulla conclusione di un contratto si applicano all'uso del nostro servizio ${URL} come la corrispondente APP ${BAKERY}.`,
    en: `The following provisions on the conclusion of a contract apply to the use of our service ${URL} as well as the corresponding APP ${BAKERY}.`,
  },
  tosText3: {
    de:
      `${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Lingweg 29/A,\n` +
      `IT-39019 Dorf Tirol,\n` +
      `Registernummer 2020/26644/800,\n` +
      `Registergericht Bozen zustande`,
    it:
      `${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Via Ling 29/A,\n` +
      `IT-39019 Tirolo,\n` +
      `Numero di registro 2020/26644/800,\n` +
      `Registro tribunale di Bolzano`,
    en:
      `${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Lingweg 29 / A,\n` +
      `D-39019 Dorf Tirol,\n` +
      `Register number 2020/26644/800,\n` +
      `Register court Bozen`,
  },
  tosText4: {
    de:
      'Mit der Registrierung und der aktiven Nutzung gibt der Verbraucher ein für ihn verbindliches Angebot auf Abschluss eines Dienstleistungsvertrags ab.\n' +
      'Der Verbraucher gibt ein bindendes Vertragsangebot ab, indem er die in unserer Webseite angebotene Dienstleistung wie folgt nutzt:',
    it:
      "Con la registrazione e l'uso attivo, il consumatore presenta un'offerta vincolante per concludere un contratto di servizio.\n" +
      "Il consumatore presenta un'offerta contrattuale vincolante utilizzando il servizio offerto sul nostro sito web come segue.",
    en:
      'With the registration and active use, the consumer submits a binding offer to conclude a service contract.\n' +
      'The consumer submits a binding contract offer by using the service offered on our website as follows.',
  },
  tosText4L1: {
    de: 'Anlegen des eigenen Sortiment (Produkte)',
    it: 'Crea il tuo assortimento (prodotti)',
    en: 'Create your own assortment (products)',
  },
  tosText4L2: {
    de: 'Erstellung des/der Käuferaccounts',
    it: "Creazione dei/dell'account/s acquirente",
    en: 'Creation of the buyer account (s)',
  },
  tosText4L3: {
    de: 'Bearbeitung der Eingehenden Bestellung der Käuferaccounts oder dessen Unteraccounts',
    it: "Elaborazione dell'ordine in entrata degli account acquirente o dei loro account aggiuntivi",
    en: 'Processing of the incoming order of the buyer accounts or their additional accounts',
  },
  tosText5: {
    de: 'Die angegebenen Preise enthalten die gesetzliche Umsatzsteuer und sonstige Preisbestandteile. Der Verbraucher hat die Möglichkeit der Zahlung per Nachnahme. Die Fälligkeit der Dienstleistungspreis Forderung tritt binnen 30 Tage nach Erhalt der Rechnung ein. Kosten für die Dienstleistung berechnen sich pro erstellten Käuferaccount, wobei nur aktive* Käufer berechnet werden: 3,00 € / Käuferaccount / Monat\n\n*Unter aktive Käuferaccount versteht sich jeder Account welche innerhalb einem Monat mindestens eine Bestellung aufgibt.',
    it: "I prezzi indicati includono l'imposta legale sulle vendite e altre componenti di prezzo. Il consumatore ha la possibilità di pagare in contrassegno. La data di scadenza del reclamo sul prezzo del servizio avviene entro 30 giorni dal ricevimento della fattura. I costi per la disinstallazione sono calcolati per account acquirente creato, per cui vengono addebitati solo gli acquirenti attivi*: 3,00 € / account acquirente / mese\n\n* Un account acquirente attivo è qualsiasi account che effettua almeno un ordine entro un mese.",
    en: 'The prices quoted include the statutory sales tax and other price components. The consumer has the option of paying cash on delivery. The due date of the service price claim occurs within 30 days after receipt of the invoice. Uninstalling costs are calculated per created buyer account, whereby only active* buyers are charged: 3.00 € / buyer account / month.\n\n* An active buyer account is any account that places at least one order within a month.',
  },
  tosText6: {
    de: 'Die Dienstleistung umfasst die Nutzung unseres online-Systems mit folgender Funktionalität:',
    it: "Il servizio include l'utilizzo del nostro sistema online con le seguenti funzionalità:",
    en: 'The service includes the use of our online system with the following functionality:',
  },
  tosText6L1: {
    de: 'Anlegen der Produkte, Import der Produkte aus Easyback und das Anlegen der Käuferaccounts',
    it: 'Creazione dei prodotti, importazione dei prodotti da Easyback e creazione degli account acquirente',
    en: 'Creating the products, importing the products from Easyback and creating the buyer accounts',
  },
  tosText6L2: {
    de: `Den Verkauf der Produkte des Verbrauchers durch die Webseite ${URL} an die Käufer, oder an dessen Unteraccounts über die App ${BAKERY}.`,
    it: `La vendita dei prodotti del consumatore tramite il sito web ${URL} all'acquirente, o ai suoi account aggiuntivi tramite l'app ${BAKERY}.`,
    en: `The sale of the consumer's products through the website ${URL} to the buyer, or to their additional accounts via the ${BAKERY} app.`,
  },
  tosText6L3: {
    de: 'Einsicht der eingehenden Bestellungen, sowie die Erstellung einer Übersicht (Backliste) der offenen Bestellungen, sowie eine Übersicht welche die Aufbereitung der Bestellung erleichtert ("Bestellungen wiegen").',
    it: 'Ispezione degli ordini in entrata, nonché la creazione di una panoramica (back list) degli ordini aperti, nonché una panoramica che facilita la preparazione dell\'ordine ("ordini di pesatura").',
    en: 'Inspection of the incoming orders, as well as the creation of an overview (back list) of the open orders, as well as an overview which facilitates the preparation of the order ("weigh orders").',
  },
  tosText6L4: {
    de: 'Erstellung der Rechnungen an Käufer',
    it: 'Creazione di fatture agli acquirenti',
    en: 'Creation of invoices to buyers',
  },
  tosText61: {
    de: 'Außerdem wird den Käuferaccounts folgende Funktionalität angeboten:',
    it: 'Inoltre, la seguente funzionalità è offerta agli account acquirente:',
    en: 'In addition, the following functionality is offered to buyer accounts:',
  },
  tosText6L21: {
    de: 'Übernahme der Produkte des Verbrauchers, Erstellung eigener Produkte, Erstellung Unteraccounts',
    it: 'Acquisizione dei prodotti del consumatore, creazione dei propri prodotti, creazione di Account aggiuntivi',
    en: "Takeover of the consumer's products, creation of own products, creation of sub-accounts",
  },
  tosText6L22: {
    de: 'Erstellung einer Bestellung bzw. Nachbearbeitung einer des Unteraccounts erstellten Bestellung',
    it: "Creazione di un ordine o post-elaborazione di un ordine creato nell'account aggiuntivo",
    en: 'Creation of an order or post-processing of an order created in the additional account',
  },
  tosText6L23: {
    de: 'Einsicht auf eine Zusammenfassung der des Unteraccounts bestellten Ware',
    it: "Informazioni su un riepilogo delle merci ordinate nell'account aggiuntivo",
    en: 'Insight into a summary of the goods ordered in the additional account',
  },
  tosText81: {
    de: 'Verbrauchern steht ein Widerrufsrecht nach folgender Maßgabe zu:',
    it: 'I consumatori hanno diritto al diritto di recesso alle seguenti condizioni:',
    en: 'Consumers are entitled to a right of withdrawal under the following conditions:',
  },
  tosText82: {
    de:
      `${
        'Sie haben das Recht, binnen vierzehn Tagen, oder mit jedem Monatsende ohne Angabe von Gründen diesen Vertrag zu widerrufen.\n' +
        '\n' +
        'Um Ihr Widerrufsrecht auszuüben, müssen Sie uns\n' +
        ''
      }${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Lingweg 29/A,\n` +
      `IT-39019 Dorf Tirol,\n` +
      `E-Mail ivan@waldboth.com,\n` +
      `mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.\n` +
      `\n` +
      `Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.`,
    it:
      `${
        'Hai il diritto di recedere dal presente contratto entro quattordici giorni senza fornire alcuna motivazione.\n' +
        'Il periodo di recesso è di quattordici giorni dalla data di conclusione del contratto.\n' +
        '\n' +
        'Per esercitare il tuo diritto di recesso, devi contattarci\n' +
        ''
      }${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Via Ling 29 / A,\n` +
      `IT-39019 Tirolo,\n` +
      `E-mail ivan@waldboth.com,\n` +
      `mediante una chiara dichiarazione (ad esempio una lettera inviata per posta o e-mail) della tua decisione di recedere dal presente contratto. A tal fine è possibile utilizzare il modulo di recesso modello allegato, ma non è obbligatorio.\n` +
      `\n` +
      `Per rispettare il termine di recesso, è sufficiente inviare la notifica di esercizio del diritto di recesso prima della scadenza del periodo di recesso.`,
    en:
      `${
        'You have the right to cancel this contract within fourteen days without giving any reason.\n' +
        'The cancellation period is fourteen days from the day the contract is concluded.\n' +
        '\n' +
        'In order to exercise your right of withdrawal, you must contact us\n' +
        ''
      }${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Lingweg 29 / A,\n` +
      `IT-39019 Dorf Tirol,\n` +
      `Email ivan@waldboth.com,\n` +
      `by means of a clear statement (e.g. a letter sent by post or e-mail) of your decision to withdraw from this contract. You can use the attached model withdrawal form for this purpose, but this is not mandatory.\n` +
      `\n` +
      `To meet the withdrawal deadline, it is sufficient for you to send your notification of exercising your right of withdrawal before the withdrawal period has expired.`,
  },
  tosText83: {
    de:
      'Wenn Sie diesen Vertrag widerrufen, werden Ihnen keine bisherigen Zahlungen rückerstattet. Mit einen Widerruf folgt die Blockierung des Accounts zu Monatsende, wodurch mit folgemonat keine Dienstleistung mehr angeboten wird.\n' +
      '\n' +
      'Haben Sie verlangt, dass die Dienstleistungen bis Monatsende fortgesetzt werden soll, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht',
    it:
      "In caso di recesso dal presente contratto, non verranno rimborsati eventuali pagamenti precedenti. Con un recesso, l'account viene bloccato alla fine del mese, il che significa che non vengono più offerti servizi per il mese successivo.\n" +
      '\n' +
      "Se hai richiesto che i servizi siano continuati fino alla fine del mese, devi pagarci un importo ragionevole, che corrisponde alla parte dei servizi già forniti fino al momento in cui ci hai informato dell'esercizio del il diritto di recesso in relazione al presente contratto Rispetto alla portata complessiva dei servizi previsti dal contratto",
    en:
      'If you cancel this contract, you will not be reimbursed any previous payments. With the cancellation, the account gets blocked at the end of the month, which means that no more services are offered for the following month.\n' +
      '\n' +
      'If you have requested that the services be continued until the end of the month, you have to pay us a reasonable amount, which corresponds to the portion of the services already provided up to the point in time at which you informed us of the exercise of the right of withdrawal with regard to this contract Compared to the total scope of the services provided for in the contract',
  },
  tosText84: {
    de:
      'Muster-Widerrufsformular\n' +
      '\n' +
      '(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)',
    it:
      'Modulo di recesso modello\n' +
      '\n' +
      '(Se desideri annullare il contratto, compila questo modulo e rispediscilo.)',
    en:
      'Model withdrawal form\n' +
      '\n' +
      '(If you want to cancel the contract, please fill out this form and send it back.)',
  },
  tosFormText1: {
    de:
      `An:\n${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Lingweg 29/A,\n` +
      `IT-39019 Dorf Tirol,\n` +
      `E-Mail ivan@waldboth.com. \nHiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über die Erbringung der Dienstleistung`,
    it:
      `A:\n${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Via Ling 29/A,\n` +
      `IT-39019 Tirolo,\n` +
      `E-Mail ivan@waldboth.com. \nCon la presente io / noi revoco il contratto da me / noi concluso per la fornitura del servizio`,
    en:
      `To:\n${BAKERY},\n` +
      `Ivan Waldboth,\n` +
      `Lingweg 29/A,\n` +
      `IT-39019 Dorf Tirol,\n` +
      `E-Mail ivan@waldboth.com. \nI / we hereby revoke the contract concluded by me / us the provision of the service`,
  },
  tosFormText2: {
    de: '',
    it: '',
    en: '',
  },
  addressTos: {
    de: 'Anschrift des/der Verbraucher(s)',
    it: 'Indirizzo del / i consumatore / i',
    en: 'Address of the consumer (s)',
  },
  sign: {
    de: 'Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)\n',
    it: 'Firma del / i consumatore / i (solo se notificato su supporto cartaceo)',
    en: 'Signature of the consumer (s) (only when notified on paper)',
  },
  date: {
    de: 'Datum',
    it: 'Data',
    en: 'Date',
  },
  cookieConsentBtn: {
    de: 'Verstanden und akzeptieren',
    it: 'Capito e accetta',
    en: 'Understood and accept',
  },
  cookieConsent: {
    de: 'Auf dieser Website werden Cookies verwendet, um Ihre Benutzererfahrung zu verbessern. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu. Lesen Sie unsere ',
    it: "I cookie vengono utilizzati su questo sito Web per migliorare la tua esperienza utente. Utilizzando il nostro sito Web, acconsenti all'uso dei cookie. Leggi la nostra ",
    en: 'Cookies are used on this website to improve your user experience. By using our website, you agree to the use of cookies. Read our ',
  },
};
const authKeywords = {
  captchaWrong: {
    de: 'Die eingegebene Lösung ist leider Falsch, bitte versuchen Sie es erneut.',
    it: 'La soluzione inserita è purtroppo errata, riprova.',
    en: 'The entered solution is unfortunately wrong, please try again.',
  },
  /*  passwordHelp: {
    de: 'Passwörter müssen aus min. 8 Zeichen bestehen und mindestens 1 von folgenden enthalten: (Groß-Kleinbuchstabe, Zahl, Sonderzeichen).',
    it: 'Le password devono essere composte da almeno 8 caratteri e contenere almeno 1 dei seguenti: (lettera min-maiuscola, numero, caratteri speciali).',
    en: 'Passwords must consist of at least 8 characters and contain at least 1 of the following: (upper and lower case letter, number, special characters).',
  },*/
  passwordHelp: {
    de: 'Passwörter müssen aus min. 8 Zeichen bestehen.',
    it: 'Le password devono essere composte da almeno 8 caratteri.',
    en: 'Passwords must consist of at least 8 characters.',
  },
  passwordRepeat: {
    de: 'Passwort wiederholen',
    it: 'Ripeti la password',
    en: 'Repeat password',
  },
  confirmed: {
    de: 'Bestätigt',
    it: 'Confermato',
    en: 'Confirmed',
  },
  confirmedHelp: {
    de: 'Soll der Account bereits bestätigt sein? Wenn nicht wird dem Benutzer noch eine E-Mail gesendet mit welcher er seinen Account freischalten kann.',
    it: "L'account dovrebbe già essere confermato? In caso contrario, all'utente verrà inviata un'E-mail con la quale potrà attivare il proprio account.",
    en: 'Should the account already be confirmed? If not, the user will be sent an email with which he can activate his account.',
  },
  numberMissing: {
    de: 'Mindestens eine Zahl',
    it: 'Almeno un numero',
    en: 'At least one number',
  },
  lowercaseMissing: {
    de: 'Mindestens ein Kleinbuchstabe!',
    it: 'Almeno una lettera minuscola!',
    en: 'At least one lowercase letter!',
  },
  uppercaseMissing: {
    de: 'Mindestens ein Großbuchstabe!',
    it: 'Almeno una lettera maiuscola!',
    en: 'At least one capital letter!',
  },
  specialMissing: {
    de: 'Mindestens ein Sonderzeichen!',
    it: 'Almeno un carattere speciale!',
    en: 'At least one special character!',
  },
  passwordNotEqual: {
    de: 'Passwörter stimmen nicht überein!',
    it: 'Le passwords non corrispondono!',
    en: 'Passwords do not match!',
  },
  tooShort: {
    de: 'Passwort zu kurz!',
    it: 'Password troppo corta!',
    en: 'Password too short!',
  },
  loggedOut: {
    de: 'Erfolgreich abgemeldet!',
    it: 'Disconnesso con successo!',
    en: 'Successfully logged off!',
  },
  userNotConfirmed: {
    de: 'Der Account wurde noch nicht aktiviert, bitte klicke auf den Link in der E-Mail die wir Ihnen gesendet haben.',
    it: "L'account non è stato ancora attivato, fare clic sul collegamento nell'E-mail che ti abbiamo inviato.",
    en: 'The account has not yet been activated, please click on the link in the email we sent you.',
  },
  userBlocked: {
    de: 'Der Account wurde blockiert aufgrund von zu vielen Fehlanmeldungen.',
    it: "L'account è stato bloccato a causa di troppi accessi falsi.",
    en: 'The account was blocked due to too many false logins.',
  },
  userNotActive: {
    de: 'Der Account ist nur während der Zeit Ihres Aufenthalts ([stay]) aktiv.',
    it: "L'account è attivo solo durante il soggiorno ([stay]).",
    en: 'The account is only active during your stay ([stay]).',
  },
  onlyForAdmins: {
    de: 'Dieser Bereich ist für Gäste nicht zugänglich.',
    it: 'Questa zona non è accessibile al ospite.',
    en: 'This area is not accessible to guests.',
  },
  invalidUser: {
    de: 'Der Account ist fehlerhaft, bitte kontaktieren Sie Ihre Bäckerei oder den Support.',
    it: "L'account non è configurato corretto, contatta il tuo panificio o l'assistenza.",
    en: 'The account is faulty, please contact your bakery or support.',
  },
  userNotFound: {
    de: 'Der Account wurde nicht gefunden.',
    it: "L'account non è stato trovato.",
    en: 'The account was not found.',
  },
  password: { de: 'Passwort', it: 'Password', en: 'Password' },
  username: { de: 'Benutzername', it: 'Nome utente', en: 'Username' },
  forgotPw: {
    de: 'Password vergessen?',
    it: 'Password dimenticato?',
    en: 'Forgot password?',
  },
  forgotPwDesc: {
    de: 'Geben Sie Ihre E-Mail Adresse ein und wir senden Ihnen einen Link zur Passwort-Wiederherstellung.',
    it: 'Inserisci il tuo indirizzo email e ti invieremo un link per recuperare la tua password.',
    en: 'Enter your email address and we will send you a link to recover your password.',
  },
  captcha: {
    de: 'Was ist die Lösung auf "first op second"?',
    it: 'Qual è la risposta a "first op second"?',
    en: 'What is the answer to "first op second"?',
  },
  resetLinkSend: {
    de: 'Wir haben Ihnen einen Link zur Wiederherstellung des Passworts zugesendet.',
    it: 'Ti abbiamo inviato un link per recuperare la tua password.',
    en: 'We have sent you a link to recover your password.',
  },
  mailWrong: {
    de: 'Kein Account mit dieser E-Mail Adresse gefunden.',
    it: 'Nessun account trovato con questo indirizzo email.',
    en: 'No account found with this email address.',
  },
  resetCodeWrong: {
    de: 'Etwas ist schief gelaufen. Dieser Link ist leider nicht mehr gültig. Bitte versuchen Sie es erneut.',
    it: 'Qualcosa è andato storto. Purtroppo questo link non è più valido. Per favore riprova.',
    en: 'Something went wrong. Unfortunately, this link is no longer valid. Please try again.',
  },
  backToLogin: {
    de: 'Zurück zur Anmeldung',
    it: 'Torna al login',
    en: 'Back to Login',
  },
  credentialsWrong: {
    de: 'Benutzername oder Passwort falsch!',
    it: 'Nome utente o password errati!',
    en: 'Invalid username or password!',
  },
  passwordWrong: {
    de: 'Passwort falsch!',
    it: 'Password errati!',
    en: 'Password wrong!',
  },
  passwordChanged: {
    de: 'Password erfolgreich geändert!',
    it: 'Password cambiata con successo!',
    en: 'Password successfully changed!',
  },
  setNewPw: {
    de: 'Sie können nun ein neues Passwort eingeben!',
    it: 'Ora puoi inserire una nuova password!',
    en: 'You can now enter a new password!',
  },
  loginSuccess: {
    de: 'Erfolgreich angemeldet',
    it: 'Accesso eseguito con successo',
    en: 'Logged in successfully',
  },
  logout: { de: 'Abmelden', it: 'Uscire', en: 'Sign out' },
  register: { de: 'Registrieren', it: 'Registrati', en: 'Sign up' },
  registerFailed: {
    de: 'Registrierung fehlgeschlagen',
    it: 'Registrazione fallita',
    en: 'Registration failed',
  },
  welcome: {
    de: 'Willkommen, bitte Anmelden!',
    it: 'Benvenuto, accedete per favore!',
    en: 'Welcome, please login!',
  },
  verifySuccess: {
    de: 'Erfolgreich registriert, bitte Anmelden!',
    it: 'Registrato con successo, accedete per favore!',
    en: 'Successfully sign up, please login!',
  },
  welcomeRegister: {
    de: `Vielen Dank dass Sie sich dazu entschieden haben mit ins Team ${BAKERY} zu kommen! Achtung, die Registrierung ist nur für eine Bäckerei möglich.`,
    it: `Grazie per aver scelto di entrare a far parte del team ${BAKERY}! Attenzione, la registrazione è possibile solo per un panificio.`,
    en: `Thank you for choosing to join the ${BAKERY} team! Attention, registration is only possible for one bakery.`,
  },
  firstLogin: {
    de: `Willkommen, bevor Sie ${BAKERY} benützen können brauchen wir noch einige Informationen!`,
    it: `Benvenuto, prima di poter utilizzare ${BAKERY}, abbiamo bisogno di qualche informazione in più!`,
    en: `Welcome, before you can use ${BAKERY}, we need some more information!`,
  },
  login: { de: 'Anmelden', it: 'Accedi', en: 'Login' },
  confirmMail: {
    de: 'E-Mail Verifizierung',
    it: 'Verifica email',
    en: 'Email verification',
  },
  confirmMailCont: {
    de: 'Sie haben Post bekommen!',
    it: 'Hai ricevuto posta!',
    en: 'You have received mail!',
  },
  sendConfirmationMail: {
    de: 'Wir haben Ihnen eine E-Mail mit den Link zur Verifizierung gesendet. Klicken Sie auf den Link oder tippen Sie hier den code ein.',
    it: "Ti abbiamo inviato un'E-mail con il link per la verifica. Fare clic sul collegamento o digitare il codice qui.",
    en: 'We have sent you an email with the link for verification. Click on the link or type in the code here.',
  },
  confirmationCode: {
    de: 'Verifizierungscode',
    it: 'Codice di verifica',
    en: 'Verification code',
  },
  confirmationCodeWrong: {
    de: 'Verifizierungscode falsch',
    it: 'Codice di verifica errato',
    en: 'Verification code wrong',
  },
};
const headerKeywords = {
  keywords: {
    de: `Kivi,${BAKERY},Brötchenservice,Südtirol,Urlaub,Ferienwohnung,Frühstück`,
    it: `Kivi,${BAKERY},servizio pane,Alto Adige,vacanze,appartamento per le vacanze,colazione`,
    en: `Kivi,${BAKERY},bread service,South Tyrol,vacation,holiday apartment,breakfast`,
  },
  pageTitle: {
    en: ` - ${BAKERY}`,
    it: ` - ${BAKERY}`,
    de: ` - ${BAKERY}`,
  },
  pageLoginTitle: {
    de: 'Anmelden',
    it: 'Accedi',
    en: 'Log In',
  },
  pageLoginDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageRegisterTitle: {
    de: 'Registrieren',
    it: 'Registrati',
    en: 'Sign Up',
  },
  pageRegisterDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageImpressumTitle: {
    de: 'Impressum',
    it: 'Impronta',
    en: 'Imprint',
  },
  pageImpressumDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageTosTitle: {
    de: 'Allgemeine Geschäftsbedingungen',
    it: 'Termini di servizio',
    en: 'Terms of Service',
  },
  pageTosDesc: {
    de: '',
    it: '',
    en: '',
  },
  pagePpTitle: {
    de: 'Datenschutz-Bestimmungen',
    it: 'Privacy Policy',
    en: 'Privacy Policy',
  },
  pagePpDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageSitemapTitle: {
    de: 'Sitemap',
    it: 'Sitemap',
    en: 'Sitemap',
  },
  pageSitemapDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageProfileTitle: {
    de: 'Mein Profil',
    it: 'Mio Profilo',
    en: 'My Profile',
  },
  pageProfileDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageHomeTitle: {
    de: 'Home',
    it: 'Home',
    en: 'Home',
  },
  pageHomeDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageSettingsTitle: {
    de: 'Einstellungen',
    it: 'Impostazioni',
    en: 'Settings',
  },
  pageSettingsDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageClientTitle: {
    de: 'Kunden',
    it: 'Clienti',
    en: 'Clients',
  },
  pageClientDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageProductTitle: {
    de: 'Produkte',
    it: 'Prodotti',
    en: 'Products',
  },
  pageProductDesc: {
    de: '',
    it: '',
    en: '',
  },
  pagePlansTitle: {
    de: 'Vorteile und Preise',
    it: 'Vantaggi e prezzi',
    en: 'Benefits and prices',
  },
  pagePlansDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageBillTitle: {
    de: 'Rechnungen',
    it: 'Fatture',
    en: 'Invoices',
  },
  pageBillDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageOrderTitle: {
    de: 'Bestellungen',
    it: 'Ordini',
    en: 'Orders',
  },
  pageOrderDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageAbosTitle: {
    de: 'Abos',
    it: 'Abbonamenti',
    en: 'Subscriptions',
  },
  pageAbosDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageChatTitle: {
    de: 'Chat',
    it: 'Chat',
    en: 'Chat',
  },
  pageChatDesc: {
    de: '',
    it: '',
    en: '',
  },
  pageBakeryTitle: {
    de: 'Backliste',
    it: 'Listino',
    en: 'Bake List',
  },
  pageBakeryDesc: {
    de: '',
    it: '',
    en: '',
  },
}; // TODO vervollständigen
const dashboardKeywords = {
  numberClients: {
    de: 'Anzahl Kunden',
    it: 'Numero di clienti',
    en: 'Number of Clients',
  },
  numberProducts: {
    de: 'Anzahl Produkte',
    it: 'Numero di prodotti',
    en: 'Number of Products',
  },
  numberOrders: {
    de: 'Anzahl Bestellungen',
    it: 'Numero di ordini',
    en: 'Number of orders',
  },
  sales: {
    de: 'Umsatz',
    it: 'Vendite',
    en: 'Sales',
  },
  growth: {
    de: 'Umsatzwachstum',
    it: 'Crescita delle vendite',
    en: 'Sales growth',
  },
  delivered: {
    de: 'Zurzeit Ausgeliefert',
    it: 'Attualmente consegnato',
    en: 'Delivered atm.',
  },
  guests: {
    de: 'Gäste',
    it: 'Clienti',
    en: 'Clients',
  },
  created: {
    de: 'Registriert',
    it: 'Registrato',
    en: 'Registered ',
  },
  recentClients: {
    de: 'Letzten Kunden',
    it: 'Ultimi clienti',
    en: 'Recent clients',
  },
  recentOrders: {
    de: 'Letzten Bestellungen',
    it: 'Ultimi ordini',
    en: 'Recent orders',
  },
  fullPrice: {
    de: 'Gesamtpreis',
    it: 'Prezzo totale',
    en: 'Full price',
  },
  isDelivered: {
    de: 'Geliefert',
    it: 'Spedito',
    en: 'Delivered',
  },
  client: {
    de: 'Kunde',
    it: 'Cliente',
    en: 'Client',
  },
  qA: {
    de: 'Fragen und Antworten',
    it: 'Domande e risposte',
    en: 'Questions and answers',
  },
  Q1: {
    de: `Wofür benötige ich ${BAKERY}?`,
    it: `A cosa mi serve ${BAKERY}?`,
    en: `What do I need ${BAKERY} for?`,
  },
  A1: {
    de: 'Wir erleichtern Ihnen den täglichen Brötchenservice. Sie können ganz einfach alle Produkte von [bakery] über die App bestellen. Zusätzlich können Sie Gästeaccounts erstellen. Ihre Gäste können dann mit der App ihren Frühstücksbedarf decken.',
    it: "Semplifichiamo per te il servizio di consegna quotidiana del pane. Puoi facilmente ordinare tutti i prodotti da [bakery] tramite l'app. Puoi anche creare account ospite. I tuoi ospiti possono quindi utilizzare l'app per soddisfare le loro esigenze di colazione.",
    en: 'We make the daily bread delivery service easier for you. You can easily order all products from [bakery] via the app. You can also create guest accounts. Your guests can then use the app to cover their breakfast needs.',
  },
  Q2: {
    de: 'Ich bin zum ersten mal hier, wie benutze ich diese Plattform?',
    it: 'È la prima volta che vengo qui, come utilizzo questa piattaforma?',
    en: 'This is my first time here, how do I use this platform?',
  },
  A2: {
    de: 'Sollten Sie Privat diese Plattform nutzen werden Sie die folgenden 4 Fragen nicht interessieren, mehr Information finden Sie in Frage 7.\n\n Anderenfalls finden Sie oben in der Navigationsleiste und rechts in der Seitenleiste alle Links die Sie benötigen. In der oberen Leiste finden Sie ein eingekreistes Fragezeichen. Ein klick auf diesen Fragezeichen öffnet ein Tutorial für die Seite auf der Sie sich gerade befinden. Als ersten Schritt sollten Sie ihre Stammdaten vervollständigen. Das können Sie auf Ihren Profil, welches sie mit ein klick auf das Avatar oben rechts, öffnen. Danach können Sie Gäste-Accounts anlegen und Produkte übernehmen/erstellen.',
    it: "Se usi questa piattaforma privatamente, non sarai interessato alle seguenti 4 domande, puoi trovare maggiori informazioni nella domanda 7.\n\n In caso contrario troverai tutti i link di cui hai bisogno nella barra di navigazione in alto e nella barra laterale a destra. Nella barra in alto troverai un punto interrogativo cerchiato. Un clic su questo punto interrogativo apre un tutorial per la pagina in cui ti trovi attualmente. Come primo passo, dovresti completare i tuoi dati anagrafici. Puoi farlo sul tuo profilo, che puoi aprire cliccando sull'avatar in alto a destra. Puoi quindi creare account ospite e adottare/creare prodotti.",
    en: 'If you use this platform privately, you will not be interested in the following 4 questions, you can find more information in question 7.\n\n Otherwise you will find all the links you need in the navigation bar at the top and in the sidebar on the right. In the top bar you will find a circled question mark. A click on this question mark opens a tutorial for the page you are currently on. As a first step, you should complete your personal data. You can do this on your profile, which you can open by clicking on the avatar at the top right. You can then create guest accounts and adopt / create products.',
  },
  Q3: {
    de: 'Was ist ein Gäste-Account?',
    it: "Che cos'è un account ospite?",
    en: 'What is a guest account?',
  },
  A3: {
    de: 'Sollten Sie Apartments mit Brötchenservice anbieten, ist der Gäste-Account genau das was Sie brauchen. Mit dem Gäste-Account können ihre Gäste die Brote selbst von der App über Sie bestellen.\n Damit ist kein Eingreifen mehr erforderlich und Ihre Gäste bekommen täglich Ihre Brote geliefert. \nSie müssen vorher noch die [link Produkte /products] definieren welche Ihre Gäste sehen und die Preise dafür festlegen. Sie können [link hier /client/create] einen Gäste-Account anlegen.',
    it: "Se offri appartamenti con servizio colazione, l'account ospite è esattamente ciò di cui hai bisogno. Con l'account ospite, i tuoi ospiti possono ordinare il pane dall'app tramite te.\nQuesto significa che non c'è più bisogno di intervenire e i tuoi ospiti avranno il loro pane consegnato tutti i giorni.\nDevi prima definire i [link prodotti /products] che i tuoi ospiti vedono e impostare i prezzi per loro. Puoi creare un account ospite [link qui /client/create].",
    en: 'If you offer apartments with a bread delivery service, the guest account is exactly what you need. With the guest account, your guests can order the bread themselves from the app through you.\nThis means that there is no longer any need to intervene and your guests will have their breads delivered every day.\nYou must first define the [link products /products] which your guests see and set the prices for them. You can create a guest account [link here /client/create].',
  },
  Q4: {
    de: 'Muss ich für jeden Gast einen Account anlegen?',
    it: 'Devo creare un account per ogni ospite?',
    en: 'Do I have to create an account for each guest?',
  },
  A4: {
    de: 'Nein, es gibt zwei Möglichkeiten wie Sie vorgehen können: \n\n1. Sie erstellen einen Account für jede neue Anreise, sie können dann festlegen wie lange der Account aktiv ist. Dies verhindert dass der Gast auch nach Aufenthalt noch Brote bestellt. Bei Erstellung eines Gäste-Accounts wird Ihnen eine Anleitungsseite erstellt welche Sie den Gast übergeben können. Sobald der Gast abreist, sehen Sie [link hier /client/billing] die Abrechnung.\n\n2. Sie erstellen einen Account pro vermietbare Einheit. Dabei sei jedoch empfohlen das Password ständig zu ändern da sonst der Gast auch noch nach Abreise bestellen kann. Bei der Abrechnung können Sie gegebenenfalls den Zeitraum angeben. Die Anleitungsseite wird bei der Passwort Änderung neu erstellt.',
    it:
      'No, puoi procedere in due modi:\n' +
      '\n' +
      "1. Crei un account per ogni nuovo arrivo, puoi quindi determinare per quanto tempo l'account è attivo. Ciò impedisce all'ospite di ordinare il pane dopo il soggiorno. Quando crei un account ospite, viene creata una pagina di istruzioni che puoi consegnare all'ospite. Non appena l'ospite se ne va, vedrai la fatturazione [link qui /client/billing].\n" +
      '\n' +
      "2. Crei un account per ogni unità affittabile. Tuttavia, si consiglia di cambiare costantemente la password, altrimenti l'ospite può ancora ordinare dopo la partenza. Se necessario, è possibile specificare il periodo nella fatturazione. La pagina delle istruzioni verrà ricreata quando cambi la password.",
    en:
      'No, there are two ways you can proceed:\n' +
      '\n' +
      '1. You create an account for each new arrival, you can then determine how long the account is active. This prevents the guest from ordering bread even after their stay. When creating a guest account, an instruction page will be created which you can hand over to the guest. As soon as the guest leaves, you will see the billing [link here /client/billing].\n' +
      '\n' +
      '2. You create an account for each rentable unit. However, it is recommended to change the password constantly, otherwise the guest can still order after departure. If necessary, you can specify the period in the billing page. The instructions page will be recreated when you change the password of the guest account.',
  },
  Q5: {
    de: 'Wie funktioniert das mit den Produkten?',
    it: 'Come funziona con i prodotti?',
    en: 'How does it work with the products?',
  },
  A5: {
    de: "Die Produkte hat bereits [bakery] für Sie erstellt, diese müssen Sie nur mehr 'übernehmen'. D.h. festlegen welche Produkte ihre Gäste in der App sehen. Sie können auch den Anzeigepreis verändern. Damit können Sie einen kleinen Gewinn auf die Brote schaffen. Die Abrechnung verwendet den Anzeigepreis, nicht den Preis welcher von der Bäckerei festgelegt wurde.\n\nSie können auch Eigenprodukte anlegen, klicken Sie dazu auf der '[link Produkte-erstellen /product/create]' Seite auf 'Eigenprodukt'. Ein Eigenprodukt (wie z.B. selbstgemachte Marmelade) wird dann nicht an die Bäckerei weitergeleitet und Sie müssen dies selbst den Gästen übergeben.",
    it:
      '[bakery] ha già creato i prodotti per te, devi solo "prenderli in mano". Cioè specifica quali prodotti i tuoi ospiti vedono nell\'app. Puoi anche modificare il prezzo di visualizzazione. Con questo puoi creare un piccolo profitto sul pane. La fatturazione utilizza il prezzo visualizzato, non il prezzo fissato dal panificio.\n' +
      '\n' +
      'Puoi anche utilizzare i tuoi prodotti facendo clic su "Prodotto fatto in casa" nella pagina "[link Crea-prodotti /product/create]". Il tuo prodotto (per esempio marmellata fatta in casa) non verrà quindi inoltrato al panificio e dovrai consegnarlo tu stesso agli ospiti.',
    en:
      "[bakery] has already created the products for you, you just have to 'take over' them. This means specify which products your guests see in the app. You can also change the display price. With this you can create a small profit on the bread. The billing uses the display price, not the price set by the bakery.\n" +
      '\n' +
      "You can also use your own products by clicking on 'Self made product' on the '[link create-products /product/create]' page. Your own product (such as homemade jam for example) will then not be forwarded to the bakery and you have to hand it over to the guests yourself.",
  },
  Q6: {
    de: 'Wo sehe ich wie viel meine Gäste bestellt haben?',
    it: 'Dove posso vedere quanto hanno ordinato i miei ospiti?',
    en: 'Where can I see how much my guests have ordered?',
  },
  A6: {
    de: "Im '[link Abrechnungsbereich /client/billing]' wählen sie einen Gäste-Account aus und Sie sehen alles was er bestellt hat. Ihnen wird dabei auch der Gesamtbetrag zusammengerechnet. Dabei wird der Anzeigepreis der Produkte verwendet. \n\nAuf der [link Bestellungen /orders]-Seite sehen Sie jede Bestellung detaillierter.",
    it:
      "In '[link area-fatturazione /client/billing]' seleziona un account ospite e vedrai tutto ciò che ha ordinato. L'importo totale verrà aggiunto anche a te. Viene utilizzato il prezzo di visualizzazione dei prodotti.\n" +
      '\n' +
      'Puoi vedere ogni ordine in modo più dettagliato nella pagina [link ordini /orders].',
    en:
      "In the '[link billing-area /client/billing]' select a guest account and you will see everything that he has ordered. The total amount will also be added to you. The display price of the products is used.\n" +
      '\n' +
      'You can see each order in more detail on the [link Orders /orders] page.',
  },
  Q7: {
    de: 'Ich habe einen Privaten Account, was mache ich hier?',
    it: 'Ho un account privato, cosa faccio qui?',
    en: 'I have a private account, what do I do here?',
  },
  A7: {
    de: 'Diese Platform bietet eine Bestellapp an, diese haben Sie wahrscheinlich schon gesehen. Dort können Sie täglich Brote und andere tolle Produkte von [bakery] bestellen.\n\nDie Webseite beinhaltet für Sie folgendes:\n\n1. Einsicht in Privacy Policy, AGB und Impressum\n\n2. Einfache Kontaktaufnahme zu [bakery] und den Support.\n\n3. Ihre persönlichen Daten/Adresse, welche Sie hier auch bearbeiten können.\n\nSollten Sie noch Fragen haben, zögern Sie nicht den Support zu [link kontaktieren /chat/create].',
    it:
      "Questa piattaforma offre un'app per ordinare da [bakery], probabilmente l'hai già vista. Lì puoi ordinare pane e altri ottimi prodotti da [bakery] ogni giorno.\n" +
      '\n' +
      'Il sito Web include quanto segue per te:\n' +
      '\n' +
      '1. Controllo della politica sulla privacy, termini e condizioni e impronta\n' +
      '\n' +
      '2. Facile contatto con [bakery] e il supporto.\n' +
      '\n' +
      '3. I tuoi dati personali / indirizzo, che puoi anche modificare qui.\n' +
      '\n' +
      "In caso di domande, non esitare a [link contattare /chat/create] l'assistenza.",
    en:
      'This platform offers an ordering app, you have probably already seen it. There you can order bread and other great products from [bakery] every day.\n' +
      '\n' +
      'The website includes the following for you:\n' +
      '\n' +
      '1. Inspection of the privacy policy, terms and conditions and imprint\n' +
      '\n' +
      '2. Easy contact to [bakery] and the support.\n' +
      '\n' +
      '3. Your personal data / address, which you can also edit here.\n' +
      '\n' +
      'If you have any questions, please do not hesitate to [link contact /chat/create] the support .',
  },
};
const planKeywords = {
  plan: { de: 'Paket', it: 'Pacchetto', en: 'Subscription' },
  easyPlan: { de: 'Einfach', it: 'Comodo', en: 'Easy' },
  mediumPlan: { de: 'Standard', it: 'Standard', en: 'Standard' },
  premiumPlan: { de: 'Premium', it: 'Premium', en: 'Premium' },
  planHelp: {
    de: '*Alle Preisangaben inkl. MwSt., Jährliche Abrechnung',
    it: '*Tutti i prezzi sono comprensivi di IVA, pagamento annuale',
    en: '*All prices including VAT, annual billing',
  },
  prices: {
    de: 'Preise',
    it: 'Prezzi',
    en: 'Prices',
  },
  plans: {
    de: 'Pakete',
    it: 'Pacchetti',
    en: 'Subscriptions',
  },
  plansPrices: {
    de: `Vorteile und Preise im Team ${BAKERY}`,
    it: `Vantaggi e prezzi nel team ${BAKERY}`,
    en: `Benefits and prices in the ${BAKERY} team`,
  },
  benefit1: {
    de: 'Sie zahlen nur die Kapazität welche Sie benötigen.',
    it: 'Paghi solo per la capacità di cui hai bisogno.',
    en: 'You only pay for the capacity you need.',
  },
  benefit2: {
    de: `Einfach, sicher und effizient. ${BAKERY} erstellt für Sie Backliste, Lieferschein und Rechnungen.`,
    it: `Semplice, sicuro ed efficiente. ${BAKERY} crea una backlist, una bolla di consegna e fatture per te.`,
    en: `Simple, safe and efficient. ${BAKERY} creates a backlist, delivery note and invoices for you.`,
  },
  benefit3: {
    de: `Vorteile auch für Ihre Kunden: Die Gastbetreiber haben durch ${BAKERY} fast keine Arbeit mehr.`,
    it: `Vantaggi anche per i tuoi clienti: La gestione dei appartamenti non ha quasi nulla a che fare con ${BAKERY}.`,
    en: `Advantages for your clients too: the guest operators have almost no work to do with ${BAKERY}.`,
  },
  pricePerGuest: {
    de: 'Kontaktieren Sie uns für ein Angebot.',
    it: 'Contattaci per un preventivo.',
    en: 'Contact us for a offer.',
  },
  vendor: {
    de: 'Verkaufsleiter Marian Bacher',
    it: 'Responsabile vendite Marian Bacher',
    en: 'Sales manager Marian Bacher',
  },
  pricesHost: {
    de: 'Gastbetreiber / Endverbraucher können Slowvita kostenlos nutzen',
    it: 'La gestione dei appartamenti/consumatori finali possono utilizzare Slowvita gratuitamente',
    en: 'Guest operators / end users can use Slowvita free of charge',
  },
  registerText: {
    de: `Sind Sie noch nicht mit dabei im Team ${BAKERY}? Erstellen Sie hier einen Account und profitieren auch Sie von unseren Startangebot.`,
    it: `Non fai ancora parte del team ${BAKERY}? Crea un account qui e approfitta della nostra offerta iniziale.`,
    en: `Are you not part of the ${BAKERY} team yet? Create an account here and benefit from our starter offer.`,
  },
  generateAutomatically: {
    de: 'Automatisch generieren',
    it: 'Genera automaticamente',
    en: 'Generate automatically',
  },
};
const tutorialKeywords = {
  tutorial: {
    de: 'Tutorial',
    it: 'Tutorial',
    en: 'Tutorial',
  },
  skip: {
    de: 'Weiter',
    it: 'Continua',
    en: 'Continue',
  },
  annotateSkip: {
    de: 'Wenn Sie fertig sind, klicken Sie auf die Schaltfläche "Weiter" in der unteren rechten Ecke Ihres Bildschirms.',
    it: 'Quando hai finito, premi il pulsante "Continua" nell\'angolo in basso a destra dello schermo.',
    en: 'When you are done, press the "Continue" button in the bottom right corner of your screen.',
  },
  exitTutorial: {
    de: 'Tutorial beenden',
    it: "Esci dall'tutorial",
    en: 'Exit Tutorial',
  },
  hideHelp: {
    de: 'Verstecken',
    it: 'Nascondere',
    en: 'Hide',
  },
  showHelp: {
    de: 'Tutorial Anzeigen',
    it: 'Mostra Tutorial',
    en: 'Show Tutorial',
  },
  complete: {
    de: 'Beenden',
    it: 'Finisce',
    en: 'Complete',
  },
  tooLow: {
    de: 'Scrollen Sie nach unten, um den nächsten Abschnitt des Tutorials zu sehen',
    it: 'Scorri verso il basso per vedere la sezione successiva del tutorial',
    en: 'Scroll down to see the next section of the tutorial',
  },
  tooHigh: {
    de: 'Scrollen Sie nach oben, um den nächsten Abschnitt des Tutorials zu sehen',
    it: "Scorri verso l'alto per vedere la prossima sezione del tutorial",
    en: 'Scroll up to see the next section of the tutorial',
  },
  okay: {
    de: 'Ok, Danke!',
    it: 'Ok, grazie!',
    en: 'Okay thank you!',
  },
  nextStep: {
    de: 'Weiter zum nächsten Schritt',
    it: 'Vai al passaggio successivo',
    en: 'Go to next step',
  },
  generalTutorial: {
    de: `Wie ist ${BAKERY} aufgebaut?`,
    it: `Come è strutturato ${BAKERY}?`,
    en: `How is ${BAKERY} structured?`,
  },
  generalTutorialDesc: {
    de: 'Dieses Tutorial erklärt Ihnen in wenigen Schritten die wichtigsten Funktionen. Sie können das Tutorial jederzeit abbrechen.',
    it: 'Questo tutorial spiega le funzioni più importanti in pochi passaggi. Puoi annullare il tutorial in qualsiasi momento.',
    en: 'This tutorial explains the most important functions in just a few steps. You can cancel the tutorial at any time.',
  },
  tutorialGo: {
    de: 'Okay, fangen wir an',
    it: 'Ok, cominciamo',
    en: "Okay, let's begin",
  },
  generalTutorialHeader: {
    de:
      'Hier sehen Sie die Navigation zu den wichtigsten Komponenten: Ihre Kunden, Produkte, Bestellungen und Rechnungen.\n ' +
      'Mit einem Klick auf die 3 waagerechten Striche können Sie die linke/rechte Seitenleiste ein- und ausblenden.',
    it:
      'Qui puoi vedere la navigazione ai componenti più importanti: i tuoi clienti, prodotti, ordini e fatture.\n' +
      'Con un click sulle 3 linee orizzontali puoi mostrare e nascondere la barra laterale destra/sinistra.',
    en:
      'Here you can see the navigation to the most important components: your customers, products, orders and invoices.\n' +
      'With a click on the 3 horizontal lines you can show and hide the left/right sidebar.',
  },
  generalTutorialSidebar: {
    de: 'Hier sehen sie noch ein paar nützliche Schnellzugriffe wie z.B. "Produkt erstellen"',
    it: 'Qui puoi vedere alcune scorciatoie più utili come "Crea prodotto"',
    en: 'Here you can see a few more useful shortcuts such as "Create product"',
  },
  generalTutorialAside: {
    de: 'In der rechten Seitenleiste sehen Sie die letzten Aktivitäten. Mit einem Klick auf das Messenger Symbol sehen Sie Ihre Konversationen.',
    it: 'Nella barra laterale destra puoi vedere le ultime attività. Con un clic sul simbolo del messenger puoi vedere le tue conversazioni.',
    en: 'In the right sidebar you can see the last activities. With a click on the messenger symbol you can see your conversations.',
  },
  generalTutorialProfile: {
    de: 'Klicken Sie auf das Profilbild um Ihren Account zu sehen. Hier können Sie Webseiten-Einstellungen vornehmen, Ihre Stammdaten ändern, oder sich abmelden.',
    it: "Clicca sull'immagine del profilo per vedere il tuo account. Qui puoi effettuare le impostazioni del sito web, modificare i tuoi dati anagrafici o disconnetterti.",
    en: 'Click on the profile picture to see your account. Here you can make website settings, change your master data, or log out.',
  },
  generalTutorialToggle: {
    de: 'Mit einem Klick auf das eingekreiste Fragezeichen können Sie jederzeit Hilfe zur aktuell geöffneten Seite anfordern.',
    it: 'Con un clic sul punto interrogativo cerchiato è possibile richiedere assistenza sulla pagina attualmente aperta in qualsiasi momento.',
    en: 'With a click on the circled question mark you can request help on the currently opened page at any time.',
  },
  generalTutorialCompleteTitle: {
    de: 'Geschafft!',
    it: 'Fatto!',
    en: 'Done!',
  },
  generalTutorialComplete2Bakery: {
    de: 'Bei weiteren fragen können Sie gerne den Support(support@kivi.bz.it|+39 340 915 3895) kontaktieren.',
    it: "Se hai ulteriori domande, non esitare a contattare l'assistenza (support@kivi.bz.it|+39 340 915 3895) o 'il tuo panificio.",
    en: 'If you have any further questions, please feel free to contact support (support@kivi.bz.it|+39 340 915 3895) or your bakery.',
  },
  generalTutorialComplete2Host: {
    de: 'Bei weiteren fragen können Sie gerne den Support (support@kivi.bz.it | +39 340 915 3895) oder Ihre Bäckerei kontaktieren.',
    it: "Se hai ulteriori domande, non esitare a contattare l'assistenza (support@kivi.bz.it | +39 340 915 3895) o 'il tuo panificio.",
    en: 'If you have any further questions, please feel free to contact support (support@kivi.bz.it | +39 340 915 3895) or your bakery.',
  },
  noTutTutorial: {
    de: 'Kein Tutorial Vorhanden',
    it: 'Nessun tutorial disponibile',
    en: 'No tutorial available',
  },
  noTutTutorialDesc: {
    de: 'Für diese Seite haben wir leider kein Tutorial. Für das Übersichtstutorial besuchen Sie das "Dashboard"',
    it: 'Sfortunatamente non abbiamo un tutorial per questa pagina. Per il tutorial della panoramica, visita il "Dashboard"',
    en: 'Unfortunately we don\'t have a tutorial for this page. For the overview tutorial visit the "Dashboard"',
  },
  noTutTutorialComplete: {
    de: 'Sollten Sie Fragen zu dieser Seite haben, kontaktieren Sie bitte den Support.',
    it: "In caso di domande su questa pagina, contattare l'assistenza.",
    en: 'If you have any questions about this page, please contact support.',
  },
  noTutTutorialCompleteTitle: {
    de: 'Wir beantworten gerne Ihre Fragen!',
    it: 'Risponderemo volentieri alle tue domande!',
    en: 'We will gladly answer your questions!',
  },
  clientTutorial: {
    de: 'Kunden-Tutorial',
    it: 'Tutorial cliente',
    en: 'Client tutorial',
  },
  clientTutorialDesc: {
    de: 'In diesem Tutorial wird kurz beschrieben wie Sie Kunden anlegen oder bearbeiten können.',
    it: 'Questo tutorial descrive brevemente come creare o modificare i clienti.',
    en: 'This tutorial briefly describes how you can create or edit customers.',
  },
  clientTutorialGeneral: {
    de: 'Hier müssen Sie alle Felder ausfüllen einen Kunden anzulegen. Jedes Feld ist darunter im ausgegrauter Schrift beschrieben.',
    it: 'Qui devi compilare tutti i campi per creare un cliente. Ogni campo è descritto di seguito con caratteri in grigio.',
    en: 'Here you have to fill in all fields to create a customer. Each field is described below in gray font.',
  },
  clientTutorialEmail: {
    de: 'Mit einem Klick auf diesen Knopf öffnet sich Ihr E-Mail Programm mit einer vorgefertigten E-Mail an den neu angelegten Kunden. Aus Sicherheitsgründen haben wir keine Einsicht auf gespeicherte Passwörter, deshalb wird das Password in der E-Mail nur ausgefüllt, wenn sie es gerade erstellt haben.',
    it: "Con un clic su questo pulsante, il tuo programma di posta elettronica si apre con un'E-mail già pronta per il cliente appena creato. Per motivi di sicurezza, non abbiamo alcuna visualizzazione delle password salvate, quindi la password viene compilata nell'E-mail solo se l'hai appena creata.",
    en: 'With a click on this button, your e-mail program opens with a ready-made e-mail to the newly created customer. For security reasons, we have no view of saved passwords, so the password is only filled in in the e-mail if you have just created it.',
  },
  clientTutorialQRCodes: {
    de: 'Hier sehen Sie den QR-Code zum App Download. Sobald Sie einen Kunden angelegt haben sehen Sie hier auch den QR-Code für die Anmeldung in der App. Wir haben für Sie bereits eine Anleitung zusammengestellt, diese können Sie gerne ausdrucken und in der Unterkunft aufliegen lassen. Sie erscheint ebenfalls nach dem Erstellen des Kunden.',
    it: "Qui puoi vedere il codice QR per il download dell'app. Non appena avrai creato un cliente, vedrai anche il codice QR per accedere all'app qui. Abbiamo già messo insieme le istruzioni per te, che puoi stampare e lasciare al tuo alloggio. Viene visualizzato anche dopo aver creato il cliente.",
    en: 'Here you can see the QR code for the app download. As soon as you have created a customer, you will also see the QR code for logging into the app here. We have already put together instructions for you, which you can print out and have them available in the accommodation. It also appears after creating the customer.',
  },
  clientTutorialComplete: {
    de: 'Hiermit endet das Kundentutorial. Am besten Sie fangen sofort damit an Kunden anzulegen.',
    it: 'Questo termina il tutorial del cliente. La cosa migliore da fare è iniziare subito a creare clienti.',
    en: 'This ends the client tutorial. The best thing to do is to start creating customers right away.',
  },
  productTutorial: {
    de: 'Produkte-Tutorial',
    it: 'Tutorial sui prodotti',
    en: 'Products tutorial',
  },
  productTutorialDescHost: {
    de:
      'In diesem Tutorial wird kurz beschrieben wie Sie Produkte anlegen, übernehmen oder bearbeiten können. Ihre Bäckerei sollte schon die Produkte angelegt haben, diese müssen Sie lediglich übernehmen. Diese werden Ihren Gästen in der App angezeigt, Sie können natürlich trotzdem das gesamte Sortiment bestellen.\n' +
      '\n' +
      'Sie können auch eigene Produkte anlegen, welche Ihre Gäste über die App bei Ihnen kaufen. Die Bestellung für diese Produkte geht natürlich nicht an die Bäckerei.',
    it:
      "Questo tutorial descrive brevemente come creare, prendere in carico o modificare i prodotti. La tua pasticceria dovrebbe aver già creato i prodotti, devi solo prenderli in carico. Questo mostrerà i tuoi ospiti nell'app, ovviamente puoi ancora ordinare l'intera gamma.\n" +
      '\n' +
      "Puoi anche creare i tuoi prodotti, che i tuoi ospiti possono acquistare da te tramite l'app. Naturalmente, l'ordine per questi prodotti non va al panificio.",
    en:
      'This tutorial briefly describes how you can create, take over or edit products. Your bakery should already have created the products, you just have to take them over. These products will be shown to your guests in the app, of course you can still order the entire range.\n' +
      '\n' +
      'You can also create your own products, which your guests can buy from you via the app. Of course, the order for these products does not go to the bakery.',
  },
  productTutorialDescBakery: {
    de: 'In diesem Tutorial wird kurz beschrieben wie Sie Produkte anlegen oder bearbeiten können.',
    it: 'Questo tutorial descrive brevemente come creare o modificare i prodotti.',
    en: 'This tutorial briefly describes how you can create or edit products.',
  },
  productTutorialSelect: {
    de: 'Wählen Sie hier ein von der Bäckerei angelegtes Produkt aus, welches Ihre Gäste über die App Bestellen können.',
    it: "Seleziona un prodotto, creato dalla pasticceria, il quale i tuoi ospiti possono ordinare tramite l'app.",
    en: 'Select a product, created by the bakery, which your guests can order via the app.',
  },
  productTutorialPreview: {
    de: 'Hier sehen Sie das Produkt und einige Informationen.',
    it: 'Qui puoi vedere il prodotto e alcune informazioni.',
    en: 'Here you can see the product and some information.',
  },
  productTutorialDisplayPrice: {
    de: 'Sie können den Anzeigepreis selbst definieren. Dieser Preis wird Ihren Gästen angezeigt und wird bei der Gästeabrechnung verwendet. Sie zahlen bei der Bäckerei natürlich den originalen Preis.',
    it: 'Puoi definire tu stesso il prezzo di visualizzazione. Questo prezzo verrà mostrato ai tuoi ospiti e verrà utilizzato per la fatturazione degli ospiti. Certo, paghi il prezzo originale alla panetteria.',
    en: 'You can define the display price yourself. This price will be shown to your guests and will be used for guest billing. Of course, you pay the original price at the bakery.',
  },
  productTutorialSave: {
    de: 'Mit einem Klick auf den Speichern Knopf ist das Produkt gespeichert und für Ihre Kunden sichtbar.',
    it: 'Con un clic sul pulsante Salva, il prodotto viene salvato e visibile ai tuoi clienti.',
    en: 'With a click on the save button, the product is saved and visible to your customers.',
  },
  productTutorialOwnProducts: {
    de: 'Möchten Sie auch sehen wie Sie eigene Produkte anlegen? Klicken Sie dazu hier auf "Eigenprodukt", Sie können das Tutorial auch hier beenden.',
    it: 'Vuoi vedere anche come crei i tuoi prodotti? Per fare ciò, fai clic qui su "Prodotto personale", puoi anche terminare il tutorial qui.',
    en: 'Would you also like to see how you create your own products? To do this, click here on "Own product", you can also end the tutorial here.',
  },
  productTutorialOwnProductHost: {
    de: 'Füllen Sie alle Felder aus, Sie müssen auch ein geeignetes Foto dazu hochladen, am besten freigestellt oder mit weißem Hintergrund.',
    it: 'Compila tutti i campi, devi anche caricare una foto adatta, preferibilmente ritagliata o con sfondo bianco.',
    en: 'Fill in all fields, you also have to upload a suitable photo, preferably cropped or with a white background.',
  },
  ordersTutorialDescHost: {
    de: 'In diesem Tutorial wird die Bestellungen-Seite kurz beschrieben.',
    it: 'Questo tutorial descrive brevemente la pagina degli ordini.',
    en: 'This tutorial briefly describes the orders page.',
  },
  ordersTutorial: {
    de: 'Bestellungs Tutorial',
    it: 'Tutorial dei ordini',
    en: 'Orders tutorial',
  },
  ordersTutorialDescBakery: {
    de: 'Für diese Seite haben wir leider kein Tutorial. Für das Übersichtstutorial besuchen Sie das "Dashboard"',
    it: 'Sfortunatamente non abbiamo un tutorial per questa pagina. Per il tutorial della panoramica, visita il "Dashboard"',
    en: 'Unfortunately we don\'t have a tutorial for this page. For the overview tutorial visit the "Dashboard"',
  },
  ordersTutorialOverviewHost: {
    de: 'Falls bereits eine Bestellung für die Aktuelle Deadline existiert, sehen Sie hier groß einen Banner welcher mit "Aktuelle Bestellung" markiert ist. Diese Bestellung kann bearbeitet oder versendet werden. Außerdem sehen Sie darin die Produkte, welche der Gast bei Ihnen bestellt hat. Darunter sehen Sie die bereits abgeschlossenen Bestellungen.',
    it: 'Se esiste già un ordine per la scadenza corrente, qui vedrai un grande banner contrassegnato con "Ordine corrente". Questo ordine può essere modificato o spedito. Puoi anche vedere i prodotti che l\'ospite ha ordinato da te. Di seguito puoi vedere gli ordini che sono già stati completati.',
    en: 'If there is already an order for the current deadline, you will see a large banner here marked with "Current order". This order can be edited or shipped. You can also see the products that the guest has ordered from you. Below you can see the orders that have already been completed.',
  },
  ordersTutorialCreate: {
    de: 'Falls noch keine existiert, können Sie hier eine neue Bestellung erstellen.',
    it: 'If one does not exist yet, you can create a new order here.',
    en: 'If one does not exist yet, you can create a new order here.',
  },
  orderEditTutorialDescHost: {
    de: 'In diesem Tutorial wird kurz beschrieben wie Sie Bestellungen bearbeiten, löschen oder versenden können.',
    it: 'Questo tutorial descrive brevemente come elaborare, eliminare o inviare gli ordini.',
    en: 'This tutorial briefly describes how you can edit, delete or send orders.',
  },
  orderEditTutorialOverviewHost: {
    de: 'Hier sehen Sie einige Informationen zur Bestellung, sollte es sich um einer aktuellen Bestellung handeln, können Sie bis zur Deadline jederzeit den Akzeptanz-Status ändern. ACHTUNG: Nur akzeptierte Bestellungen sind für die Bäckerei Sichtbar. Wollen Sie sich darum nicht kümmern, können Sie in den Einstellungen alle Bestellungen automatisch akzeptieren.',
    it: "Qui puoi vedere alcune informazioni sull'ordine, se si tratta di un ordine in corso, puoi modificare lo stato di accettazione in qualsiasi momento fino alla scadenza. ATTENZIONE: Solo gli ordini accettati sono visibili al panificio. Se non vuoi preoccuparti, puoi accettare automaticamente tutti gli ordini nelle impostazioni.",
    en: "Here you can see some information about the order, if it is a current order, you can change the acceptance status at any time until the deadline. ATTENTION: Only accepted orders are visible to the bakery. If you don't want to worry about it, you can automatically accept all orders in the settings.",
  },
  orderEditTutorialProductsHost: {
    de: 'Hier sehen Sie die bestellten Produkte. Produkte aus dem eigenen Sortiment sind speziell für Sie gekennzeichnet.',
    it: 'Qui puoi vedere i prodotti che sono stati ordinati. I prodotti della nostra gamma sono contrassegnati appositamente per te.',
    en: 'Here you can see the products you have ordered. Products from our own range are specially marked for you.',
  },
  orderEditTutorialAddProducts: {
    de: 'Sie können beliebig Produkte hinzufügen oder entfernen, auch für Ihre Gäste.',
    it: 'Puoi aggiungere o rimuovere prodotti come preferisci, anche per i tuoi ospiti.',
    en: 'You can add or remove products as you like, including for your guests.',
  },
  orderCreateTutorialDesc: {
    de: 'In diesem Tutorial wird kurz beschrieben wie Sie Bestellungen von dieser Webseite aus erstellen können.  Dabei sei empfohlen dass die Bestellung von der App aus gemacht werden sollte, da diese übersichtlicher gestaltet ist. Wenn ein Gast bereits eine Bestellung gemacht hat können Sie diese hier bearbeiten.',
    it: "Questo tutorial descrive brevemente come creare ordini da questo sito web. Si consiglia di effettuare l'ordine dall'app, in quanto è strutturato in modo più chiaro. Se un ospite ha già effettuato un ordine, puoi modificarlo qui.",
    en: 'This tutorial briefly describes how you can create orders from this website. It is recommended that the order should be made from the app, as it is more clearly structured. If a guest has already made an order, you can edit it here.',
  },
  orderCreateTutorialMessage: {
    de: 'Geben Sie hier eine Nachricht ein wenn Sie der Bäckerei etwas mitteilen möchten, drücken Sie anschließend auf Jetzt Erstellen.',
    it: 'Inserisci qui un messaggio se vuoi dire qualcosa al panificio, quindi premi Crea ora.',
    en: 'Enter a message here if you want to tell the bakery something, then press Create Now.',
  },
  introTutorial: {
    de: 'Einführungs Tutorial',
    it: 'Tutorial introduttivo',
    en: 'Introductory tutorial',
  },
  introTutorialDescHost: {
    de:
      `Willkommen bei ${BAKERY}!\n` +
      `\n` +
      `Ich erkläre Ihnen kurz wie sie ${BAKERY} benutzen können.\n` +
      `\n` +
      `Falls Sie sich bereits zurechtfinden, können Sie das Tutorial jederzeit abbrechen und vielleicht später hierher zurückkehren.\n`,
    it:
      `Benvenuto in ${BAKERY}!\n` +
      `\n` +
      `Spiegherò brevemente come puoi usare ${BAKERY}.\n` +
      `\n` +
      `Se riesci già a orientarti, puoi annullare il tutorial in qualsiasi momento e magari tornare qui più tardi.`,
    en:
      `Welcome to ${BAKERY}!\n` +
      `\n` +
      `I will briefly explain how you can use ${BAKERY}.\n` +
      `\n` +
      `If you can already find your way around, you can cancel the tutorial at any time and maybe come back here later.`,
  },
  introTutorialDashboard: {
    de: 'Sie befinden sich nun auf den Dashboard. Hier sehen Sie einige nützliche Infos.',
    it: 'Ora sei sul Dashboard. Qui puoi vedere alcune informazioni utili.',
    en: 'You are now on the dashboard. Here you can see some useful information.',
  },
  introTutorialExplain: {
    de: `Mit ${BAKERY} können Sie einfach Produkte ihrer lokalen Bäckerei für sich und Ihre Gäste bestellen. Ganz einfach geht dies über die App, welche Sie in ihrem App-Store (Iphone oder Android), oder mit einem Klick auf den Link "Lade die App" links, installieren können. `,
    it: `Con ${BAKERY} puoi ordinare facilmente prodotti dal tuo panificio locale per te e i tuoi ospiti. Questo è molto semplice tramite l'app, che puoi installare nel tuo app store (Iphone o Android) o facendo clic sul link "Scarica l'app" a sinistra.`,
    en: `With ${BAKERY} you can easily order products from your local bakery for you and your guests. This is very easy via the app, which you can install in your app store (Iphone or Android) or by clicking on the link "Download the app" on the left.`,
  },
  introTutorialExplain2: {
    de:
      'Zusätzlich können Sie die App auch ihren Gästen anbieten, um Ihnen zusätzliche Arbeit abzunehmen. Dazu erstellen Sie separate Accounts, mit denen diese, von Ihnen ausgewählte Produkte, bestellen können. \n' +
      '\n' +
      'Natürlich können Sie hier jede Bestellung einsehen und bearbeiten. Die Preise für die Produkte legen Sie fest! Zusätzlich können Sie die Auswahl durch ihre Hofeigenen Produkte ergänzen.\n',
    it:
      "Puoi anche offrire l'app ai tuoi ospiti per sollevarli dal lavoro aggiuntivo. Per fare ciò, crei account separati con i quali possono ordinare i prodotti che hai selezionato.\n" +
      '\n' +
      'Naturalmente, puoi visualizzare e modificare ogni ordine qui. Sei tu a stabilire i prezzi per i prodotti! Puoi anche aggiungere i tuoi prodotti agricoli alla selezione.',
    en:
      'You can also offer the app to your guests in order to relieve them of additional work. To do this, you create separate accounts with which they can order the products you have selected.\n' +
      '\n' +
      'Of course, you can view and edit every order here. You set the prices for the products! You can also add your own farm products to the selection.',
  },
  introTutorialExplain3: {
    de:
      'Falls Sie sich entschieden haben ihre Gäste selbst bestellen zu lassen, müssen Sie zwei Dinge festlegen:\n' +
      '\n' +
      '[li]Welche Produkte werden Ihren Gästen zu welchen Preisen angezeigt: “Produkt erstellen”' +
      '[li]Mit welchen Accounts melden sich Ihre Gäste an: “Kunde erstellen”[lie]' +
      '\n' +
      'Hilfe dazu erhalten Sie auf den jeweiligen Seiten mit einem Klick auf das Fragezeichen oben rechts.\n',
    it:
      'Se hai deciso di far ordinare i tuoi ospiti da soli, devi determinare due cose:\n' +
      '\n' +
      '[li]Quali prodotti vengono mostrati ai tuoi ospiti a quali prezzi: "Crea prodotto"' +
      '[li]Quali account utilizzano i tuoi ospiti per accedere: "Crea cliente"[lie]' +
      '\n' +
      "Puoi ottenere aiuto nelle rispettive pagine facendo clic sul punto interrogativo nell'angolo in alto a destra.",
    en:
      'If you have decided to let your guests order themselves, you have to determine two things:\n' +
      '\n' +
      '[li]Which products are shown to your guests at which prices: "Create product"' +
      '[li]Which accounts do your guests use to log in: "Create customer"[lie]' +
      '\n' +
      'You can get help on the respective pages by clicking on the question mark in the top right corner.',
  },
  introTutorialInfo: {
    de: 'Damit alles ordnungsgemäß funktioniert, benötigen wir jedoch noch einige Informationen von Ihnen. Bitte füllen Sie dieses Formular aus, Sie können dies aber auch zu einem späteren Zeitpunkt nachholen.',
    it: 'Affinché tutto funzioni correttamente, tuttavia, abbiamo ancora bisogno di alcune informazioni da parte tua. Si prega di compilare questo modulo, ma è possibile farlo anche in un secondo momento.',
    en: 'However, we still need some information from you in order for everything to work properly. Please fill out this form, but you can also do so at a later point in time.',
  },
  introTutorialComplete: {
    de:
      'Das wars schon!\n' +
      '\n' +
      'Bei Fragen, Fehlern oder Anregungen scheuen Sie sich nicht den Support zu kontaktieren.\n' +
      '\n' +
      'Am besten Sie starten sofort mit dem Anlegen von Produkten.',
    it:
      'Questo è tutto!\n' +
      '\n' +
      "In caso di domande, errori o suggerimenti, non esitare a contattare l'assistenza.\n" +
      '\n' +
      'La cosa migliore da fare è iniziare a creare prodotti immediatamente.',
    en:
      "That's it!\n" +
      '\n' +
      'If you have any questions, errors or suggestions, please do not hesitate to contact support.\n' +
      '\n' +
      'It is best to start creating products immediately.',
  },
  showIntroTut: {
    de: 'Einführungs-Tutorial anzeigen',
    it: 'Mostra il tutorial introduttivo',
    en: 'Show introductory tutorial',
  },
  whatIs: {
    de: `Was ist die ${BAKERY} Bestellapp?`,
    it: `Cos'è l' app d'ordina di ${BAKERY}?`,
    en: `What is the ${BAKERY} order app?`,
  },
  howWorks: {
    de: `${BAKERY} App, so funktioniert's!`,
    it: `${BAKERY} App, cosi funziona!`,
    en: `${BAKERY} App, how it works!`,
  },
};
const chatbotKeywords = {
  hello: {
    de: 'Hallo [name], Ich bin Silvia und kann dir weiter helfen!',
    it: 'Ciao  [name], mi chiama Silvia e ti puoi aiutare!',
    en: 'Hello [name], my name is Silvia, may I can help you!',
  },
  askMeAnything: {
    de: 'Frag mich etwas?',
    it: 'Hai una domanda?',
    en: 'Do you have questions?',
  },
  wasIHelpful: {
    de: 'War ich hilfreich?',
    it: 'Sono stato utile?',
    en: 'Was I helpful?',
  },
  help: {
    de: `Ich brauche hilfe - ${BAKERY}`,
    it: `Mi serve aiuto - ${BAKERY}`,
    en: `I need help - ${BAKERY}`,
  },
  imSorry: {
    de: 'Es tut mir leid, möchtest du den Support kontaktieren?',
    it: 'Mi dispiace, vuoi contattare il supporto?',
    en: "I'm sorry, do you want to contact the support?",
  },
  awesome: {
    de: 'Super, vielen Dank! Noch etwas?',
    it: "Perfetto, grazie! Qualcos'altro?",
    en: 'Awesome, thank you! Something else on your mind?',
  },
  sryCantHelp: {
    de: 'Oh, darüber weiß ich nicht Bescheid!',
    it: 'Oh, purtroppo non posso aiutarti!',
    en: "Oh, unfortunately I can't help you with that!",
  },
};
export declare type TranslatorKeys =
  | keyof typeof generalKeywords
  | keyof typeof orderKeywords
  | keyof typeof billKeywords
  | keyof typeof errorKeywords
  | keyof typeof clientKeywords
  | keyof typeof profileKeywords
  | keyof typeof settingsKeywords
  | keyof typeof bakeryListKeywords
  | keyof typeof chatKeywords
  | keyof typeof productKeywords
  | keyof typeof legalKeywords
  | keyof typeof authKeywords
  | keyof typeof headerKeywords
  | keyof typeof planKeywords
  | keyof typeof dashboardKeywords
  | keyof typeof chatbotKeywords
  | keyof typeof tutorialKeywords;

type Keywords = {
  [T in string]: Keyword;
};
export const keywords: Keywords = {
  ...generalKeywords,
  ...orderKeywords,
  ...billKeywords,
  ...errorKeywords,
  ...clientKeywords,
  ...profileKeywords,
  ...settingsKeywords,
  ...bakeryListKeywords,
  ...chatKeywords,
  ...productKeywords,
  ...legalKeywords,
  ...authKeywords,
  ...headerKeywords,
  ...planKeywords,
  ...dashboardKeywords,
  ...tutorialKeywords,
  ...chatbotKeywords,
};

export function translate<
  P extends TranslatorKeys,
  T extends { [U in string]: string },
>(key: P, lang: LanguageISO, params?: T): string {
  const keyword: Keyword = keywords[key];

  if (typeof keyword === 'undefined') {
    return key;
  }

  if (params) {
    let text = keyword[lang];
    const keys = Object.keys(params);
    keys.forEach((param) => {
      text = text
        .replace(param, params[param])
        .replace(param, params[param])
        .replace(param, params[param]);
    });
    return text;
  }
  return keyword[lang];
}

export const LocaleConfig = {
  locales: {
    de: {
      monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      monthNamesShort: [
        'Jan.',
        'Feb.',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'Aug.',
        'Sept.',
        'Oct.',
        'Nov.',
        'Dez.',
      ],
      dayNames: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ],
      dayNamesShort: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
      today: 'Heute',
    },
    en: {
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthNamesShort: [
        'Jan.',
        'Feb.',
        'March',
        'April',
        'May',
        'June',
        'July',
        'Aug.',
        'Sept.',
        'Oct.',
        'Nov.',
        'Dec.',
      ],
      dayNames: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      dayNamesShort: ['Su.', 'Mo.', 'Tu.', 'We.', 'Th.', 'Fr.', 'Sa.'],
      today: 'Today',
    },
  },
  it: {
    monthNames: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    monthNamesShort: [
      'Gen.',
      'Feb.',
      'Mar.',
      'Apr.',
      'Mag.',
      'Giu.',
      'Lug.',
      'Ago.',
      'Set.',
      'Ott.',
      'Nov.',
      'Dic.',
    ],
    dayNames: [
      'Domenica',
      'Lunedì',
      'Martedì',
      'Mercoledì',
      'Giovedì',
      'Venerdì',
      'Sabato',
    ],
    dayNamesShort: ['Do.', 'Lu.', 'Ma.', 'Me.', 'Gi.', 'Ve.', 'Sa.'],
    today: 'Oggi',
  },
};

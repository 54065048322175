import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isAndroid, isIOS } from 'react-device-detect';
import reportWebVitals from './reportWebVitals';
import Loading from './views/Components/Loading';
import { APP_GOOGLE_URL, APP_IOS_URL } from './config';
import register from './registerServiceWorker';
import App from './App';

Sentry.init({
  dsn: 'https://b6774a05711b45bdb46a848bf93a9598@sentry.kivi.bz.it/23',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});
if (window.location.pathname.indexOf('/app/get') !== -1 && isAndroid) {
  window.location.replace(APP_GOOGLE_URL);
} else if (window.location.pathname.indexOf('/app/get') !== -1 && isIOS) {
  window.location.replace(APP_IOS_URL);
} else {
  ReactDOM.render(
    <React.StrictMode>
      <React.Suspense fallback={<Loading />}>
        <App />
      </React.Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

register();
reportWebVitals();
